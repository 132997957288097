import moment from "moment";
import { useEffect, useState } from "react";
import { KTSVG } from "../../helpers";
import { toastError } from "../../helpers/ToastifyHelper";
import {
  getModeOperation,
  getStatusAndComments,
  createStatusAndComments,
  getUpdateStatus,
} from "./../../../app/API/api";

interface props {
  supplierData: any;
}
export default function UpdateStatusModal({ supplierData }: props) {
  const [statusList, setStatusList] = useState<any[]>([]);
  const [statusComments, setStatusComments] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCommentLoading, setIsCommentLoading] = useState<boolean>(false);

  useEffect(() => {
    getStatusAndCommentsHandler();
    getUpdateStatus()
      .then((res) => {
        setStatusList(res.data);
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );

    return () => {
      setSelectedStatus("");
      setComment("");
    };
  }, [supplierData]);

  const getStatusAndCommentsHandler = () => {
    if (supplierData) {
      setIsCommentLoading(true);
      getStatusAndComments(supplierData.applicationCode)
        .then((res) => {
          setIsCommentLoading(false);
          setStatusComments(res.data);
        })
        .catch((err) => {
          setIsCommentLoading(false);
          toastError(err.response?.data?.error || err.response?.data);
        });
    }
  };

  const commentHandler = () => {
    setIsLoading(true);
    createStatusAndComments({
      applicationCode: supplierData.applicationCode,
      status: selectedStatus,
      comment: comment,
    })
      .then((res) => {
        setSelectedStatus("");
        setComment("");
        getStatusAndCommentsHandler();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toastError(err.response?.data?.error || err.response?.data);
      });
  };

  return (
    <div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_status">
        <div className="modal-dialog">
          <div className="modal-content" style={{ height: "600px" }}>
            <div className="modal-header">
              <h5 className="modal-title">Comment</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setStatusComments([])}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="comments-lists">
                {isCommentLoading ? (
                  <span className="spinner-border text-primary"></span>
                ) : (
                  <>
                    {statusComments.map((status) => {
                      return (
                        <div id={status?.createdAt} className="row w-100">
                          <p className="col-6">{status.comment}</p>
                          <p className="col-3 text-end">
                            {moment(status?.createdAt).format("DD-MMM-YYYY")}
                          </p>
                          <p className="col-3 text-end">{status.status}</p>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>

            <div className="p-3">
              <div className="row g-3 mb-4">
                <select
                  value={selectedStatus}
                  onChange={({ target }) => setSelectedStatus(target.value)}
                  className="form-select form-select-solid"
                  aria-label="Select example"
                >
                  <option>Select Status</option>
                  {statusList.map((status: any) => (
                    <option
                      key={status.applicationStatusName}
                      value={status.applicationStatusName}
                    >
                      {status.applicationStatusName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row g-3">
              <div className="col-md-10" style={{ overflowY: 'auto', maxHeight: '200px' }}>
                <textarea
                    onChange={(e) => setComment(e.target.value)}
                    // type="text"
                    value={comment}
                    className="form-control"
                    placeholder="Enter Comment"
                  >
                 </textarea>
                </div>
                <div className="col-md-2 p-0">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mt-2"
                    onClick={() => commentHandler()}
                  >
                    {isLoading ? (
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    ) : (
                      <span>Comment</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
