import {FormikProps} from 'formik'

interface StepperList {
    stepperTitle: string
    stepperDesc: string
    value: string
  }

 export const stepperList: StepperList[] = [
    {
      stepperTitle: 'Business Info',
      stepperDesc: 'Your Business Related Info',
      value: 'businessInfo',
    },
    {
      stepperTitle: 'Buyer Details',
      stepperDesc: 'Setup Your Buyer Details',
      value: 'buyerDetails',
    },
    {
      stepperTitle: 'Company Address Detail',
      stepperDesc: 'Setup Your Company Address Detail',
      value: 'companyAddressDetails',
    },
    {
      stepperTitle: 'Promoter/Director/Partner',
      stepperDesc: 'Setup Your Promoter/Director/Partner',
      value: 'directorPromoterInfo',
    },
    {
      stepperTitle: 'Shareholder Details',
      stepperDesc: 'Setup Your Shareholder Details',
      value: 'shareholder',
    },
    {
      stepperTitle: 'Cover Letter Details',
      stepperDesc: 'Setup Your Cover Letter Details',
      value: 'coverLetterDetails',
    },
    {
      stepperTitle: 'SIK Details',
      stepperDesc: 'Setup Your SIK Details',
      value: 'sikDetails',
    },
    {
      stepperTitle: 'Supplier Agreement Details',
      stepperDesc: 'Setup Your Supplier Agreement Details',
      value: 'supplierAgreementDetails',
    },
    {
      stepperTitle: 'EOI',
      stepperDesc: 'Setup Your EOI',
      value: 'EOI',
    },
    {
      stepperTitle: 'Authorized Signatory',
      stepperDesc: 'Setup Your Authorized Signatory',
      value: 'authorizeSignatory',
    },
    {stepperTitle: 'Document', stepperDesc: 'Setup Your Document', value: 'documentInfo'},
    {stepperTitle: 'Template', stepperDesc: 'Setup Your Template', value: 'template'},
    // { stepperTitle: 'Agreements/ Contracts', stepperDesc: 'Setup Your Agreements/ Contracts' },
    // {
    //   stepperTitle: 'Confirmation Letter',
    //   stepperDesc: 'Setup Your Confirmation Letter',
    // },
    // {
    //   stepperTitle: 'List of Other Documents',
    //   stepperDesc: 'Setup Your List of Other Documents',
    // },
  ]

export const cacheValidation = (asyncValidate: (val: string, props?: any) => Promise<any>) => {
  let _valid = false
  let _value: any = {}
  return async (value: string, props?: any) => {
    if (value !== _value[props.key]) {
      // console.log(value,_value[key],key,'val')
      _value[props.key] = value!
      const response = await asyncValidate(value, props)
      _valid = response
      return response
    }
    return _valid
  }
}
