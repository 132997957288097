import { FieldArray } from "formik";
import { FC, useState } from "react";
import { Dropdowns } from "../../../../_metronic/layout/components/Dropdowns";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import { deleteForm } from "../../../API/api";
import PopupCard from "./PopupCard";
interface Props {
  value: any;
  saveApplicationHandler: any;
  setValue: any;
  setFieldError: any;
  loading: boolean;
  appId: string;
  applicationData: any;
  setApplicationData: any;
  setFieldValue:any;
  setIsSaved: any;
}

export const countryDropdown = [
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "British",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivan",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New Zealander",
  "Ni-Vanuatu",
  "Nicaraguan",
  "Nigerien",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian or Tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Welsh",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
];

// const valueForm ="";
// const form = applicationData[valueForm];
const Shareholder: FC<Props> = ({
  value,
  saveApplicationHandler,
  setValue,
  setFieldError,
  loading,
  appId,
  applicationData,
  setApplicationData,
  setFieldValue,
  setIsSaved,
}) => {

  const [loadingDocument, setLoadingDocument] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  
 
  const [errorMessage, setErrorMessage] = useState<any>('Total  Shareholder % cant be more than 100 ');

  let totalPercentage = 0;
  value.shareholder.forEach((shareholder:any) => {
  totalPercentage += parseFloat(shareholder.shareHolding);
  
 
});

  const renderShareholder = (index: number) => {
    
    return (
      <div className="row g-3">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <Dropdowns
            required={true}
            label="Type Of Shareholder"
            name={`shareholder[${index}].typeOfShareholder`}
            options={["INDIVIDUAL", "ENTITY"]}
          />
        </div>
        {value?.shareholder[index]?.typeOfShareholder === "INDIVIDUAL" && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              label="First Name"
              type="text"
              placeHolder="Enter First Name"
              name={`shareholder[${index}].firstName`}
            />
          </div>
        )}
        {value?.shareholder[index]?.typeOfShareholder === "INDIVIDUAL" && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              label="Middle Name"
              type="text"
              placeHolder="Enter Middle Name"
              name={`shareholder[${index}].middleName`}
            />
          </div>
        )}
        {value?.shareholder[index]?.typeOfShareholder === "INDIVIDUAL" && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              label="Last Name"
              type="text"
              placeHolder="Enter Last Name"
              name={`shareholder[${index}].lastName`}
            />
          </div>
        )}
        {value?.shareholder[index]?.typeOfShareholder === "ENTITY" && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              label="Company Name"
              type="text"
              placeHolder="Enter Company Name"
              name={`shareholder[${index}].companyName`}
            />
          </div>
        )}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            required={true}
            label="Nationality"
            name={`shareholder[${index}].nationality`}
            options={countryDropdown.map((c) => c)}
          />
        </div>
        {value?.shareholder[index]?.typeOfShareholder === "INDIVIDUAL" ? (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              label={"Date of Birth"}
              type="date"
              placeHolder="Enter Date of Birth"
              name={`shareholder[${index}].dateOfBirth`}
            />
          </div>
        ) : (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              label={"Date of Incorporation"}
              type="date"
              placeHolder="Enter Date of Birth"
              name={`shareholder[${index}].dateOfIncorporation`}
            />
          </div>
        )}
        {value?.shareholder[index]?.typeOfShareholder === "INDIVIDUAL" && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Dropdowns
              required={true}
              label="Gender"
              name={`shareholder[${index}].gender`}
              options={["MALE", "FEMALE"]}
            />
          </div>
        )}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="Address"
            type="text"
            placeHolder="Enter Address"
            name={`shareholder[${index}].address`}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={!value?.shareholder[index]?.typeOfShareholder}
            label="PAN Details"
            type="text"
            placeHolder="Enter PAN Details"
            name={`shareholder[${index}].panDetails`}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="Shareholding %"
            type="number"
            placeHolder="Enter Shareholding"
            name={`shareholder[${index}].shareHolding`}
          />
        </div>
        {/* value.shareholder[index].shareHolding  */}
        {totalPercentage > 100 && (
        errorMessage && <div className="text-danger">{errorMessage}</div>
        )}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <button
          type="button"
          className="mt-5 btn btn-primary me-4 col-auto"
          // onClick={() => addShareHolder(d)}
          onClick={() => {
            setShowPopup(true);
          }}
          style={{ display: showPopup ? 'none' : 'block' }}
        >
          Add Document
        </button>
        {showPopup && <PopupCard value={value} applicationData={applicationData}  setApplicationData={setApplicationData} appId={appId} setIsSaved={setIsSaved} setFieldValue={setFieldValue} onClose={() => {setShowPopup(false)}} />} 
        
        


        </div>

        {value?.shareholder[index]?.typeOfShareholder === 'INDIVIDUAL' && (
          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
            <Inputs
              label="Passport/ Voter ID/DL details"
              type="text"
              placeHolder="Enter Passport/ Voter ID/DL details"
              name={`shareholder[${index}].passport`}
            />
          </div>
        )}

  

        
         {/* dataUnit={applicationData.shareholder[0]?.dataUnits?.dataUnit} />} */}
      </div>
    );
  };

  // const PopupComponent = ({ onClose }: { onClose: () => void }) => {
  //   // Your popup component implementation here

  //   return (
  //     <div className="popup">
  //       <div className="row g-3">
  //         <div className="col-sm-4 col-md-6 col-lg-4 col-xl-6 ">
  //           <Inputs
  //             required={true}
  //             label="Cancelled Cheque"
  //             type="file"
  //             placeHolder="Enter First Name"
  //             name="Cancelled Cheque"
  //           />
  //         </div>

  //         <div className="col-lg-4 col-md-6 col-xl-6 col-sm-6">
  //           <a
  //             // href={dataUnit.dataUnitValue}
  //             download
  //             type="button"
  //             className="btn btn-primary btn-sm m-1 me-3"
  //           >
  //             Download
  //           </a>
  //           <button
  //             type="button"
  //             className="btn btn-primary btn-sm m-1"
  //             // onClick={() =>
  //             //   uploadDocumentHandler(dataUnit.dataUnitName)
  //             // }
  //           >
  //             {loadingDocument && loadingDocument === "" ? (
  //               <span className="indicator-progress d-block">
  //                 Please wait...
  //                 <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
  //               </span>
  //             ) : (
  //               "Upload"
  //             )}
  //           </button>

  //           <button
  //             type="button"
  //             className="btn btn-warning btn-sm"
  //             data-bs-toggle="modal"
  //             data-bs-target="#kt_modal_1"
  //             // onClick={() => setToggleDocumentComment(dataUnit)}
  //           >
  //             View And Add Comments
  //           </button>
  //         </div>
  //       </div>
  //       {/* Popup content */}
  //       <button className="mt-5 btn btn-danger me-4" onClick={onClose}>
  //         Close
  //       </button>
  //     </div>
  //   );
  // };

  const addShareHolder = (d: any) => {
    const currentData = value.shareholder[0];
    const currentDataCopy = JSON.parse(JSON.stringify(currentData));
    for (const key in currentDataCopy) {
      currentDataCopy[key] = "";
    }
    d.push(currentDataCopy);
    const form = JSON.parse(JSON.stringify(applicationData.shareholder[0]));
    form.collectionId = "";
    const keys: any = Object.keys(form.dataUnits);
    for (const fieldKey of keys) {
      form.dataUnits[fieldKey].dataUnitCode = "";
    }
    applicationData.shareholder.push(form);
    setApplicationData(applicationData);
  };

  const deleteShareHolder = (d: any, index: any) => {
    d.remove(index);
    const shareholderCopy = Object.assign(applicationData.shareholder[index]);
    const deleteData: any = {
      applicationId: appId,
      forms: {
        shareholder: [
          {
            ...shareholderCopy,
          },
        ],
      },
    };
    if (shareholderCopy.collectionId) {
      deleteForm(deleteData, shareholderCopy.collectionId, appId);
    }
  };
  
  // const totalPercentage = value.shareholder.shareHolding.reduce((total:any, shareholder:any) => {
  //   return total + parseFloat(shareholder.shareHolding);
  // }, 0);

  return (
  
    <div className='card p-5 w-100'>
      <h3 className='border-bottom p-2'>Shareholder Details</h3>
      <FieldArray
        name="shareholder"
        render={(d) => (
          <div>
            {value?.shareholder?.length > 1 ? (
              <div
                className="accordion col-xl-12 col-lg-12 col-md-12 col-sm-12"
                id="kt_accordion_1"
              >
                {
                  value?.shareholder?.map((shareholders: any, i: any) => (
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id={`kt_accordion_header_${i}`}>
                        <button
                          className='accordion-button fs-4 fw-bold collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#kt_accordion_body_${i}`}
                          aria-expanded='false'
                          aria-controls={`kt_accordion_body_${i}`}
                          // onClick={() => setIndex(i)}
                        >
                          {i + 1}. {shareholders?.typeOfShareholder == "INDIVIDUAL" ? `${shareholders.firstName} ${shareholders.middleName} ${shareholders.lastName}`:shareholders.companyName} 
                        </button>
                      </h2>
                      <div
                        id={`kt_accordion_body_${i}`}
                        className='accordion-collapse collapse row p-5'
                        aria-labelledby={`kt_accordion_header_${i}`}
                        data-bs-parent='#kt_accordion_1'
                      >
                       
                        {renderShareholder(i)}
                        {i > 0 && <div className='col-auto'>
                          <button
                            type="button"
                            className="mt-5 btn btn-danger me-4"
                            onClick={() => deleteShareHolder(d, i)}
                          >
                            Delete
                          </button>
                        </div>}

               
              <div className='row'>
              <div className='col-auto'>
                <button
                  type='submit'
                  className='mt-5 btn btn-primary me-4'
                  // onClick={() =>
                  //   saveApplicationHandler(value?.shareholder,'shareholder', setFieldError)
                  // }
                >
                  {loading ? (
                    <span className='indicator-progress d-block'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
              <div className='col-auto'>
              
            

             {/* {value.shareholder[i].shareHolding < 100 || totalPercentage <=100 &&( */}
             {totalPercentage < 100 &&(
                <button
                  type='button'
                  className='mt-5 btn btn-secondary me-4'
                  onClick={() => addShareHolder(d)}
                >
                  Add Shareholder
                </button>
        )}
        

              </div>
            </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              renderShareholder(0)
            )}
            {value?.shareholder?.length ===1 &&  (
            <div className='row'>
              <div className='col-auto'>
                <button
                  type="submit"
                  className="mt-5 btn btn-primary me-4"
                  // onClick={() =>
                  //   saveApplicationHandler(value?.shareholder,'shareholder', setFieldError)
                  // }
                >
                  {loading ? (
                    <span className="indicator-progress d-block">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              <div className='col-auto'>
              

             {value.shareholder[0].shareHolding < 100 &&(
                <button
                  type="button"
                  className="mt-5 btn btn-secondary me-4"
                  onClick={() => addShareHolder(d)}
                >
                  Add Shareholder
                </button>
        )}
        {/* {errorMessage && <div className="text-danger">{errorMessage}</div>} */}

              </div>
            </div>
  )}
            
          </div>
        )}
      />
    </div>
  );
};
export default Shareholder;
