import {useEffect, useState} from 'react'
import {getStatusAndComments} from './../../API/api'
import {toastError} from './../../../_metronic/helpers/ToastifyHelper'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import {stepperList} from './../../../_metronic/helpers/ArrayHelper'
import {createAccountSchemas} from '../../modules/wizards/components/CreateAccountWizardHelper'
import {getStepperData} from '../../modules/auth/core/StepperHelper'

interface Props {
  applicationSummary: any
  applicationId: string
  statusOfTheApplication: any[]
}
export default function Summary({
  applicationSummary,
  applicationId,
  statusOfTheApplication,
}: Props) {
  const [summaryList, setSummaryList] = useState([])
  const [constitution, setConstitution] = useState<string>('')
  const [stepperData, setStepperData] = useState(stepperList)

  useEffect(() => {
    if (applicationSummary)
      setStepperData(getStepperData(constitution, applicationSummary) || stepperList)
  }, [constitution, applicationSummary])

  useEffect(() => {
    if (applicationId) {
      getStatusAndComments(applicationId)
        .then((res) => {
          setSummaryList(res.data)
        })
        .catch((err: any) => toastError(err.response?.data?.error || err.response?.data))
    }
  }, [applicationId])

  useEffect(() => {
    if (statusOfTheApplication.length === 0) {
      navigate('/application')
    }
  })
  const navigate = useNavigate()

  const navigateToStep = (step: string) => {
    navigate('/application', {state: {step}})
  }

  return (
    <div className='py-lg-15'>
      <div>
        <h2 className='border-bottom text-white'>Summary</h2>
        <p className='m-0 text-white'>A section wise summary view of the data to be collected</p>
      </div>
      <div
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid mt-5 py-lg-15'
        id='kt_create_account_stepper'
      >
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            <div className='stepper-nav'>
              {stepperData.map((data, index) => {
                return (
                  <div key={index} className='stepper-item' data-kt-stepper-element='nav'>
                    <div
                      className='stepper-wrapper pointer'
                      onClick={() => navigateToStep(data?.value)}
                    >
                      <div className='stepper-icon w-45px h-45px'>
                        {data?.value &&
                        applicationSummary &&
                        applicationSummary[data.value] === 100 ? (
                          <i className='text-primary fas fa-check'></i>
                        ) : (
                          applicationSummary &&
                          data?.value && (
                            <span className='stepper-number'>
                              {applicationSummary[data.value]} %
                            </span>
                          )
                        )}
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>{data.stepperTitle}</h3>
                        <div className='stepper-desc fw-semibold'>{data.stepperDesc}</div>
                      </div>
                    </div>
                    {index !== stepperList.length - 1 && (
                      <div className='stepper-line h-40px'></div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='p-2 flex-row-fluid bg-body rounded px-lg-15 py-lg-15'>
          <h2>Status of the application</h2>
          {summaryList.map((summary: any, index) => {
            return (
              <div className='row my-5' key={index}>
                <p className='col-lg-7'>{summary.comment}</p>
                <p className='col-lg-2'>{moment(summary?.createdAt).format('DD-MMM-YYYY')}</p>
                <p className='col-lg-3'>{summary.status}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
