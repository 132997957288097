import moment from "moment";
import { useState, useEffect } from "react";
import { documentComment, getApplication } from "../../../app/API/api";
import { KTSVG } from "../../helpers";
import { toastError, toastSuccess } from "../../helpers/ToastifyHelper";
import { toAbsoluteUrl } from "./../../helpers/AssetHelpers";

interface props {
  dataUnit: any;
  appId: string;
  valueForm: string;
  commentList: any[];
  setCommentList: any;
}

export default function CommentModel({
  dataUnit,
  appId,
  valueForm,
  commentList,
  setCommentList,
}: props) {
  const [comment, setComment] = useState("");
  const { dataUnitName, dataUnitCode }: any = dataUnit;
  const [isLoading, setLoading] = useState<boolean>(false);
  const getDocumentComments = () => {
    setLoading(true);
    getApplication(appId)
      .then((res) => {
        setLoading(false);
        const { forms } = res.data;
        if (forms) {
          console.log("fomrs")
          setCommentList(
            forms[valueForm][0].dataUnits[dataUnitName].documentComments
          );
          console.log(forms[valueForm][0].dataUnits[dataUnitName].documentComments)
        }
      })
      .catch((err) => {
        setLoading(false);
        toastError(err.response?.data?.error || err.response?.data);
      });
  };

  useEffect(() => {
    getDocumentComments();
  }, [dataUnit]);

  const submitComment = () => {
    const user: any = localStorage.getItem("user");
    const currantUser = JSON.parse(user);
    const payload = {
      dataUnitCode: dataUnitCode,
      comment,
      email: currantUser.email,
      userRole: currantUser.roles[0],
    };
    documentComment(payload)
      .then((res) => {
        toastSuccess(res.data.message);
        setComment("");
        getDocumentComments();
      })
      .catch((err) => {
        toastError(err.response?.data?.error || err.response?.data);
      });
  };
  return (
    <div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content" style={{ height: "600px" }}>
            <div className="modal-header">
              <h5 className="modal-title">Enter Comment</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              {isLoading ? (
                <div className="p-6">
                  <span className="indicator-progress d-block text-center">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    height: "400px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {commentList &&
                  commentList.map((comment: any) => (
                    <div key={comment?.commentTimeStamp} className="row">
                      <div className="col-3">{comment.name} </div>
                      <div className="col-9 bg-gray-200 rounded-1 min-h-60px">
                        {comment.comment}
                      </div>
                      <div
                        className="offset-3 text-muted"
                        style={{ fontSize: "11px" }}
                      >
                        {comment?.commentTimeStamp}
                      </div>
                      {/*<img*/}
                      {/*  className='h-30px w-30px rounded-circle'*/}
                      {/*  src={toAbsoluteUrl('/media/avatars/300-2.jpg')}*/}
                      {/*  alt='metronic'*/}
                      {/*/>*/}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="p-5">
              <div className="row g-3">
                <div className="col-lg-10">
                  <input
                    onChange={(e) => setComment(e.target.value)}
                    type="text"
                    value={comment}
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Enter Comment"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary col-lg-2 p-0"
                  onClick={submitComment}
                >
                  comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
