import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
// import {requestPassword} from '../core/_requests'
import {resetPassword} from '../../../API/api'
import {toastError, toastSuccess} from '../../../../_metronic/helpers/ToastifyHelper'

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const location: any = useLocation()
  const navigate = useNavigate()
  const initialValues = {
    email: location?.state,
    password: '',
  }
  // const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik: any = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      setLoading(true)
      resetPassword({email: values.email, password: values.password})
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            toastSuccess(res.data.message)
            navigate('/auth/login')
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          toastError(err.response?.data?.error || err.response?.data)
        })
      // setHasErrors(undefined)
      // setTimeout(() => {
      //   requestPassword(values.email)
      //     .then(({data: {result}}) => {
      //       // setHasErrors(false)
      //       setLoading(false)
      //     })
      //     .catch(() => {
      //       // setHasErrors(true)
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-semibold fs-4'>
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {/* {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )} */}

        {/* {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )} */}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bold text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder='Enter Email'
            autoComplete='off'
            value={formik?.values?.email}
            onChange={() => formik.handleChange()}
            disabled
            // {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
          <label className='form-label fw-bold text-gray-900 fs-6 mt-5'>Password</label>
          <input
            type='Password'
            placeholder='Enter Password'
            onChange={() => formik.handleChange()}
            value={formik?.values?.password}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bold me-4'
          >
            {!loading && <span className='indicator-label'>Reset Password</span>}
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bold'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
