import {useEffect} from 'react'
import {Inputs} from '../../../../_metronic/layout/components/Inputs'

interface Props {
  value: any
  saveApplicationHandler: any
  setFieldError: any
  loading: boolean
  setValue: any
}

export default function EOI({
  value,
  saveApplicationHandler,
  setFieldError,
  loading,
  setValue,
}: Props): JSX.Element {
  useEffect(() => {
    setValue('EOI.nameOfCompany', value.businessInfo.nameOfCompany)
  }, [])

  return (
    <div className='card p-5 w-100'>
      <h3 className='border-bottom p-2'>EOI</h3>
      <div className='row g-3 mb-5'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5'>
          <Inputs
            required={true}
            label='Bank Name'
            type='text'
            placeHolder='Enter Bank Name'
            name='EOI.bankName'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5'>
          <Inputs
            required={true}
            label='Bank Address'
            type='text'
            placeHolder='Enter Bank Address'
            name='EOI.bankAddress'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5'>
          <Inputs
            required={true}
            label='Type of Facility'
            type='text'
            placeHolder='Enter Type of Facility'
            name='EOI.typeOfFacility'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5'>
          <Inputs
            required={true}
            label='Amount (INR)'
            type='number'
            placeHolder='Enter Amount (INR)'
            name='EOI.amount'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5'>
          <Inputs
            required={true}
            label='Name Of Company'
            type='text'
            placeHolder='Enter Name Of Company'
            name='EOI.nameOfCompany'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-auto'>
          <button
            type='submit'
            className='mt-5 btn btn-primary me-4'
            // onClick={() => saveApplicationHandler(value.EOI, 'EOI', setFieldError)}
          >
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
