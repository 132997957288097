import {useState, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
// import TopBarProgress from 'react-topbar-progress-indicator'
// import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
// import {MenuTestPage} from '../pages/MenuTestPage'
// import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
// import {WithChildren} from '../../_metronic/helpers'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Summary from '../pages/Summary/Summary'
import Application from '../pages/Application/Application'
import {getApplicationSummary} from '../API/api'
import {toastError} from '../../_metronic/helpers/ToastifyHelper'
import ResetPassword from '../modules/auth/components/ResetPassword'
const PrivateRoutes = (props: any) => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const [applicationId, setApplicationId] = useState<string>('')
  const [applicationSummary, setApplicationSummary] = useState<any>(null)
  const [isDocumentTabShow, setIsDocumentTabShow] = useState<boolean>()
  const [isTemplateTabShow, setIsTemplateTabShow] = useState<boolean>()
  const [applicationSubmitted, setApplicationSubmitted] = useState<boolean>()
  const [statusOfTheApplication, setStatusOfTheApplication] = useState<any[]>([])

  useEffect(() => {
    getSummaryHandler()
  }, [])

  const getSummaryHandler = () => {
    getApplicationSummary()
      .then(({data}) => {
        setApplicationSummary(data.data.applicationSummary)
        setApplicationId(data.data.applicationCode)
        setApplicationSubmitted(data.data.applicationSubmitted)
        setIsDocumentTabShow(data.data.isDocumentTabShow)
        setIsTemplateTabShow(data.data.isTemplateTabShow)
        setStatusOfTheApplication(data.data.statusOfTheApplication)
      })
      .catch((err) => toastError(err.response?.data?.error || err.response?.data))
  }
  useEffect(() => {
    if (props.isSaved) {
      getSummaryHandler()
    }
  }, [props.isSaved])

  return (
    <Routes>
      <Route element={<MasterLayout setLoginUser={props.setLoginUser} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route
          path='summary'
          element={
            <Summary
              applicationSummary={applicationSummary}
              applicationId={applicationId}
              statusOfTheApplication={statusOfTheApplication}
            />
          }
        />
        <Route
          path='application'
          element={
            <Application
              isDocumentTabShow={isDocumentTabShow}
              isTemplateTabShow={isTemplateTabShow}
              isSaved={props.isSaved}
              setIsSaved={props.setIsSaved}
              applicationSubmitted={applicationSubmitted}
              applicationId={applicationId}
              applicationSummary={applicationSummary}
            />
          }
        />
        <Route
          path='documents'
          element={
            <Application
              isDocumentTabShow={isDocumentTabShow}
              isTemplateTabShow={isTemplateTabShow}
              isSaved={props.isSaved}
              setIsSaved={props.setIsSaved}
              applicationId={applicationId}
              applicationSummary={applicationSummary}
            />
          }
        />
        <Route
          path='template'
          element={
            <Application
              isDocumentTabShow={isDocumentTabShow}
              isTemplateTabShow={isTemplateTabShow}
              isSaved={props.isSaved}
              setIsSaved={props.setIsSaved}
              applicationSubmitted={applicationSubmitted}
              applicationId={applicationId}
              applicationSummary={applicationSummary}
            />
          }
        />
        <Route path='reset-password' element={<ResetPassword />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

// const SuspensedView: FC<WithChildren> = ({children}) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export {PrivateRoutes}
