import { ChangeEvent, useState } from "react";
import { uploadDocument } from "./../../API/api";
import {
  toastError,
  toastSuccess,
} from "./../../../_metronic/helpers/ToastifyHelper";
import "../Documents/Document.css";
import CommentModel from "../../../_metronic/layout/components/CommentModal";
interface props {
  value: any;
  setFieldValue: any;
  handleBlur: any;
  applicationCode: any;
  application: any;
  initialConstitution: string;
  applicationSubmitted: any;
  setFieldError: any;
  errors: any;
  setIsSaved: any;
}
export default function Template({
  setFieldError,
  errors,
  value,
  setFieldValue,
  handleBlur,
  applicationCode,
  initialConstitution,
  application,
  applicationSubmitted,
  setIsSaved,
}: props) {
  const [loadingDocument, setLoadingDocument] = useState("");
  const [toggleDocumentComment, setToggleDocumentComment] = useState(null);
  const [commentList, setCommentList] = useState<any[]>([]);

  const valueForm =
    initialConstitution === "Sole Proprietor"
      ? "templateSole"
      : initialConstitution === "Private Limited"
      ? "templatePrivateandpublic"
      : initialConstitution === "Public Limited"
      ? "templatePrivateandpublic"
      : initialConstitution === "Partnership"
      ? "templatePartnership"
      : "templateLlp";
  const form = application[valueForm];

  const onImageChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    if (event.target.files && event.target.files[0]) {
      const fileSize = 1024 * 5;
      const fileSizeKiloBytes = event.target.files[0].size / 1024;
      if (fileSizeKiloBytes <= fileSize) {
        setFieldValue(`${valueForm}.${fieldName}`, event.target?.files[0]);
      } else {
        setFieldError(
          `${valueForm}.${fieldName}`,
          "File size must be less then or equal 5MB"
        );
      }
    }
  };

  const uploadDocumentHandler = (documentName: string) => {
    if (!value[valueForm][documentName]) return;
    setLoadingDocument(documentName);
    const payload = {
      collectionName: valueForm,
      applicationCode,
      documentCode: form[0].dataUnits[documentName].dataUnitCode,
      documentName,
    };
    const formData = new FormData();
    formData.append("contentData", value[valueForm][documentName]);
    formData.append("contentMetaData", JSON.stringify(payload));
    uploadDocument(formData)
      .then((res: any) => {
        setLoadingDocument("");
        setIsSaved(true);
        toastSuccess("You have uploaded document successfully");
      })
      .catch((err) => {
        setLoadingDocument("");
        toastError(err.response?.data?.error || err.response?.data);
      });
  };

  return (
    <div className="card p-5 w-100">
      <h3 className="border-bottom p-2 mb-10">
        Template
        <div className="d-flex" style={{ fontWeight: "300", color: "grey" }}>
          Supported Formats: doc, docx, pdf, upto 5 MB
        </div>
      </h3>
      {application[valueForm] &&
        value[valueForm] &&
        Object.values(application[valueForm][0].dataUnits).map(
          (dataUnit: any, index) => {
            const docName =
              dataUnit.dataUnitValue &&
              dataUnit.dataUnitValue.split("_")[
                dataUnit.dataUnitValue.split("_").length - 1
              ];
            return (
              <div key={index}>
                <div
                  className={`${
                    docName ? "align-items-start" : "align-items-center"
                  } row g-3 mb-5`}
                >
                  <label className="col-lg-3 form-label mt-auto mb-auto">
                    {dataUnit.dataUnitLabel}
                  </label>
                  <div className="col-lg-4 document-height d-flex flex-column justify-content-center m-0">
                    <input
                      onChange={(e: any) =>
                        onImageChange(e, dataUnit.dataUnitName)
                      }
                      className="form-control"
                      type="file"
                      accept="application/pdf,image/*"
                      onBlur={handleBlur}
                      name="address"
                    />
                    {docName ? (
                      <p className="mt-2 mb-0">{`Uploaded - ${docName}`}</p>
                    ) : null}
                  </div>

                  {/* Previous Code */}

                  {/* <div className="col-lg-1"></div> 
                   <div className="col-lg-4 d-flex m-0">
                    {dataUnit.dataUnitValue &&
                      value[valueForm][dataUnit.dataUnitName] ===
                        dataUnit.dataUnitValue && (
                        <a
                          href={dataUnit.dataUnitValue}
                          download
                          type="button"
                          className="btn btn-primary btn-sm m-1 me-3"
                        >
                          Download
                        </a>
                      )}
                    <button
                      type="button"
                      disabled={!dataUnit.dataUnitValue}
                      className="btn btn-primary m-1"
                      onClick={() =>
                        uploadDocumentHandler(dataUnit.dataUnitName)
                      }
                    >
                      {loadingDocument &&
                      loadingDocument === dataUnit.dataUnitName ? (
                        <span className="indicator-progress d-block">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      ) : (
                        "Upload"
                      )}
                    </button>
                  </div> */}

                  {/* New Code */}

                  <div className="col-lg-3 d-flex m-0 ">
                    {dataUnit.dataUnitValue &&
                      value[valueForm][dataUnit.dataUnitName] ===
                        dataUnit.dataUnitValue && (
                        <a
                          href={dataUnit.dataUnitValue}
                          download
                          type="button"
                          className="btn btn-primary btn-sm m-1 me-3"
                        >
                          Download
                        </a>
                      )}
                    <button
                      type="button"
                      disabled={!dataUnit.dataUnitValue}
                      className="btn btn-primary btn-sm m-1"
                      onClick={() =>
                        uploadDocumentHandler(dataUnit.dataUnitName)
                      }
                    >
                      {loadingDocument &&
                      loadingDocument === dataUnit.dataUnitName ? (
                        <span className="indicator-progress d-block">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      ) : (
                        "Upload"
                      )}
                    </button>
                  </div>
                  <div className="col-lg-2 m-0">
                    <button
                      type="button"
                      className="btn btn-warning btn-sm m-1"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_1"
                      onClick={() => setToggleDocumentComment(dataUnit)}
                    >
                      View And Add Comments
                    </button>
                  </div>
                </div>
                {!!Object.keys(errors).length &&
                  errors[valueForm] &&
                  !!Object.keys(errors[valueForm]).length &&
                  errors[valueForm][dataUnit.dataUnitName] && (
                    <p className="text-danger">
                      {errors[valueForm][dataUnit.dataUnitName]}
                    </p>
                  )}
              </div>
            );
          }
        )}
      {toggleDocumentComment && (
        <CommentModel
          commentList={commentList}
          setCommentList={setCommentList}
          appId={applicationCode}
          valueForm={valueForm}
          dataUnit={toggleDocumentComment}
        />
      )}
    </div>
  );
}
