import {toast} from 'react-toastify'

export const toastError = (message: string, position: any = 'top-right') => {
  toast.error(message || 'Something went wrong!', {position, toastId: message})
}

export const toastSuccess = (message: string, position: any = 'top-right') => {
  toast.success(message || 'Changes Saved', {position, toastId: message, autoClose: 2000})
}

export const toastWarning = (message: string, position: any = 'top-right') => {
  toast.warning(message, {position, toastId: message})
}
