import { ErrorMessage, Field, useField } from "formik";
import { FC, useEffect } from "react";

interface InputsModel {
  label: string;
  type: string;
  placeHolder: string;
  name: string;
  validate?: any;
  required?: boolean;
  disabled?: boolean;
}

export const Inputs: FC<InputsModel> = ({
  label,
  type,
  placeHolder,
  validate,
  name,
  required,
  disabled = false,
}) => {
  return (
    <div>
      <label
        className={`col-sm-3 col-form-label text-nowrap ${required && "required"
          }`}
        htmlFor={name}
      >
        {label}
      </label>
      <Field
        disabled={disabled}
        type={type}
        name={name}
        id={name}
        validate={validate}
        className="form-control form-control-lg form-control-solid"
        placeholder={placeHolder}
      />
      <div className="text-danger m-2">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};
