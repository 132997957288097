/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {
  // Link,
  useNavigate,
} from 'react-router-dom'
import {useFormik} from 'formik'
import {forgetPassword, postSendOTP} from '../../../API/api'
// import {getUserByToken, login} from '../core/_requests'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import {useAuth} from '../core/Auth'
import {toastError, toastSuccess} from './../../../../_metronic/helpers/ToastifyHelper'
import AdminLogin from './AdminLogin'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required(),
})

const initialValues = {
  email: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
interface props {
  setLoginUser: any
}
export function Login({setLoginUser}: props) {
  const [loading, setLoading] = useState(false)
  const [loadingResetPassword, setLoadingResetPassword] = useState(false)
  // const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      postSendOTP({emailAddress: values.email})
        .then((res) => {
          setLoading(false)
          if (res.data.status === 'SUCCESS') {
            navigate('/auth/login-otp', {state: values.email})
          }
          if(res.data.status === 'ERROR') toastError(res.data.message)
        })
        .catch((err) => {
          setLoading(false)
          toastError(err.response?.data?.error || err.response?.data)
        })
      // try {
      //   const {data: auth} = await login(values.email, values.email)
      //   saveAuth(auth)
      //   const {data: user} = await getUserByToken(auth.api_token)
      //   setCurrentUser(user)
      // } catch (error) {
      //   console.error(error)
      //   saveAuth(undefined)
      //   setStatus('The login detail is incorrect')
      //   setSubmitting(false)
      //   setLoading(false)
      // }
    },
  })

  const resetPassword = () => {
    if(formik.values.email){
    setLoadingResetPassword(true)
    forgetPassword({email: formik.values.email})
      .then((res) => {
        setLoadingResetPassword(false)
        if (res.data.status === 'SUCCESS') {
          navigate('/auth/forgot-password', {state: formik.values.email})
          toastSuccess(res.data.message)
        }
        if (res.data.status === 'ERROR') {
          toastError(res.data.message)
        }
      })
      .catch((err) => {
        setLoadingResetPassword(false)
        toastError(err.response?.data?.error || err.response?.data)
      })
    } else {
      formik.setFieldError("email","email is a required field");
  }
    

  }
  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to Scymes</h1>
        {/* <div className='text-gray-400 fw-semibold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bold'>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {
        formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )
        //  : (
        //   <div className='mb-10 bg-light-info p-8 rounded'>
        //     <div className='text-info'>
        //       Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //       continue.
        //     </div>
        //   </div>
        // )
      }

      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_supplier'>
            Supplier
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_admin'>
            Admin
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade show active' id='kt_tab_pane_supplier' role='tabpanel'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bold text-dark'>Email</label>
              <input
                placeholder='Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Send OTP</span>}
                {loading && (
                  <span className='indicator-progress d-block'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            <div
             className='d-flex flex-center link-primary fs-6 fw-bold' onClick={resetPassword}>
              {!loadingResetPassword && <span className='indicator-label pointer'>Forgot Password ?</span>}
              {loadingResetPassword && (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </div>
          </form>
        </div>
        <div className='tab-pane fade' id='kt_tab_pane_admin' role='tabpanel'>
          <AdminLogin setLoginUser={setLoginUser} />
        </div>
      </div>

      {/* begin::Form group */}

      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bold text-dark fs-6 mb-0'>Password</label>
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bold'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div> */}
      {/* end::Form group */}

      {/* begin::Action */}

      {/* begin::Separator */}
      {/* <div className='text-center text-muted text-uppercase fw-bold mb-5'>or</div> */}
      {/* end::Separator */}

      {/* begin::Google link */}
      {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
      {/* end::Google link */}

      {/* begin::Google link */}
      {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
      {/* end::Google link */}

      {/* begin::Google link */}
      {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
      {/* end::Google link */}
      {/* end::Action */}
    </>
  )
}
