import { FC, useState } from "react";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import Checkbox from "../../../../_metronic/layout/components/Checkbox";
import CustomCheckbox from "../../../../_metronic/layout/components/CustomCheckbox";
import { Dropdowns } from "../../../../_metronic/layout/components/Dropdowns";

interface Props {
  value: any;
  saveApplicationHandler: any;
  setFieldError: any;
  loading: boolean;
  constitution: string;
  initialConstitution: string;
  setValue: any;
}

const CoverLetterDetails: FC<Props> = ({
  value,
  saveApplicationHandler,
  setFieldError,
  loading,
  constitution,
  initialConstitution,
  setValue,
}) => {
  const [preCheckName, setPreCheckName] = useState("");

  const valueForm: any =
    initialConstitution === "Sole Proprietor"
      ? "coverLetterForSoloProprietor"
      : initialConstitution === "Private Limited"
      ? "coverLetterPrivateAndPublic"
      : initialConstitution === "Public Limited"
      ? "coverLetterPrivateAndPublic"
      : initialConstitution === "Partnership"
      ? "coverLetterForPartnership"
      : "coverLetterForLlp";
  const onChangeCheckbox = (e: any, name: string) => {
    const partnershipField = [
      "certifiedEntityProof",
      "complateSalesAndIncomeTaxReturn",
      "gst/cst/vatCertificate",
      "certificateOfMuncipal",
      "licenseIssuedAuthority",
      "registrationCertificates",
      "licenseIssuedAuthority1",
      "registrationCertificates1",
      "utlityBills",
      "registeredAddress",
      "sale&IncomeTaxReturn1",
      "certificateIssuedByAuthorities1",
      "latestListOfPartners",
      "certificateOfMuncipal1",
      "gst/cst/vatCertificate1",
      "mailingAddressForAforesaid",
      "mailingAddressForAforesaid1",
      "iecImportExportCode",
      "certificateRegistrationDocumentBySales",
    ];
    if (value?.businessInfo?.constitution === "Sole Proprietor") {
      partnershipField.forEach((p) =>
        setValue(
          `${valueForm}.${p}`,
          (name === p || p === preCheckName) && e.target.checked
        )
      );
      if (e.target.checked && name) {
        setPreCheckName(name);
      }
    } else {
      partnershipField.forEach((p) =>
        setValue(`${valueForm}.${p}`, name === p && e.target.checked)
      );
    }
  };

  return (
    <div className="card p-5 w-100">
      <h3 className="border-bottom p-2">Cover Letter Details</h3>
      <div className="row g-3 mb-5">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
          <Inputs
            required={true}
            label="Bill Discounting Facility"
            type="number"
            placeHolder="Enter Bill Discounting Facility"
            name={`${valueForm}.billDiscountingFacility`}
          />
        </div>

        {(initialConstitution === "Private Limited" ||
          initialConstitution === "Public Limited") && (
          <>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Date of Authorization"
                type="date"
                placeHolder="Enter Date of Authorization"
                name={`${valueForm}.dateOfAuthorization`}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                required={true}
                name={`${valueForm}.latestListOfDirectorsr`}
                label="Latest List of Directors is updated on Ministry of Corporate Affairs Website"
                options={["YES", "No"]}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                required={true}
                name={`${valueForm}.latestRegisteredAddress`}
                label="Latest Registered Address is updated on Ministry of Corporate Affairs Website"
                options={["YES", "No"]}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                required={true}
                name={`${valueForm}.latestMailingAddress`}
                label="Latest Mailing Address is updated on Ministry of Corporate Affairs Website"
                options={["YES", "No"]}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                required={true}
                name={`${valueForm}.latestMemorandum`}
                label="Latest Memorandum of Association (MOA) is updated on Ministry of Corporate Affairs Website"
                options={["YES", "No"]}
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <Dropdowns
                  required={true}
                  name={`${valueForm}.latestArticleOfAssociation`}
                  label="Latest Article of Association (AOA) is updated on Ministry of Corporate Affairs Website"
                  options={["YES", "No"]}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <Dropdowns
                  required={true}
                  name={`${valueForm}.latestCertificateOfIncorp`}
                  label="Latest Certificate of Incorporation/ commencement of Business is updated on Ministry of Corporate Affairs Website"
                  options={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Major countries of operation/Business"
                type="text"
                placeHolder="Enter Major Countries Of Operation/Business"
                name={`${valueForm}.majorCountriesOfOperation`}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Number of employees"
                type="number"
                placeHolder="Enter Number of employees"
                name={`${valueForm}.numberOfEmployees`}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Expected monthly transaction pattern(volume/value)"
                type="number"
                placeHolder="Enter Expected Monthly Transaction Pattern"
                name={`${valueForm}.expectedMonthlyTransaction`}
              />
            </div>
          </>
        )}

        {initialConstitution === "Sole Proprietor" && (
          <>
            <div className="row g-3 mb-5">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 px-5">
                <div className="required">
                  Certified true copy of Entity proof is annexed (anyone 2 of
                  following)
                </div>
                {/* <p className='m-0 text-danger'>{showError}</p> */}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <CustomCheckbox
                  onChangeCheckbox={onChangeCheckbox}
                  name="registrationCertificates"
                  valueForm={valueForm}
                  value={
                    value[valueForm] &&
                    value[valueForm]["registrationCertificates"]
                  }
                  setValue={setValue}
                  label="Registration Certificate (in the case of a registered concern)"
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <CustomCheckbox
                  onChangeCheckbox={onChangeCheckbox}
                  name="complateSalesAndIncomeTaxReturn"
                  valueForm={valueForm}
                  value={
                    value[valueForm] &&
                    value[valueForm]["complateSalesAndIncomeTaxReturn"]
                  }
                  setValue={setValue}
                  label="Complete Sales & income tax returns where the Firm’s name is reflected, duly authenticated/acknowledged by the Income Tax Authorities"
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <CustomCheckbox
                  onChangeCheckbox={onChangeCheckbox}
                  name="gst/cst/vatCertificate"
                  valueForm={valueForm}
                  value={
                    value[valueForm] &&
                    value[valueForm]["gst/cst/vatCertificate"]
                  }
                  setValue={setValue}
                  label="CST/ VAT/ GST certificate (provisional/final)"
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <CustomCheckbox
                  onChangeCheckbox={onChangeCheckbox}
                  name="certificateRegistrationDocumentBySales"
                  valueForm={valueForm}
                  value={
                    value[valueForm] &&
                    value[valueForm]["certificateRegistrationDocumentBySales"]
                  }
                  setValue={setValue}
                  label="Certificate / registration document issued by Sales Tax / Service Tax / Professional Tax authorities"
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <CustomCheckbox
                  onChangeCheckbox={onChangeCheckbox}
                  name="certificateOfMuncipal"
                  valueForm={valueForm}
                  value={
                    value[valueForm] &&
                    value[valueForm]["certificateOfMuncipal"]
                  }
                  setValue={setValue}
                  label="Certificate / license issued by the Municipal authorities under Shop & Establishment Act"
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <CustomCheckbox
                  onChangeCheckbox={onChangeCheckbox}
                  name="iecImportExportCode"
                  valueForm={valueForm}
                  value={
                    value[valueForm] && value[valueForm]["iecImportExportCode"]
                  }
                  setValue={setValue}
                  label="IEC (Importer Exporter Code) issued by DGFT"
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <CustomCheckbox
                  onChangeCheckbox={onChangeCheckbox}
                  name="utlityBills"
                  valueForm={valueForm}
                  value={value[valueForm] && value[valueForm]["utlityBills"]}
                  setValue={setValue}
                  label="Utility bills such as electricity, water, landline telephone bills, etc."
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <CustomCheckbox
                  onChangeCheckbox={onChangeCheckbox}
                  name="licenseIssuedAuthority"
                  valueForm={valueForm}
                  value={
                    value[valueForm] &&
                    value[valueForm]["licenseIssuedAuthority"]
                  }
                  setValue={setValue}
                  label="License issued by the Registering authority like Certificate of Practice issued by Institute of Chartered Accountants of India, Institute of Cost Accountants of India, Institute of Company Secretaries of India, Indian Medical Council, Food and Drug Control Authorities, etc."
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <Dropdowns
                  name={`${valueForm}.registeredAddress`}
                  label="Registered Address is same as mentioned in aforesaid Entity proof"
                  options={["YES", "No"]}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <Dropdowns
                  name={`${valueForm}.mailingAddressForAforesaid`}
                  label="Mailing Address is same as mentioned in aforesaid Entity proof"
                  options={["YES", "No"]}
                />
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <Inputs
                  required={true}
                  label="Major Countries Of Operation/Business"
                  type="text"
                  placeHolder="Enter Major Countries Of Operation/Business"
                  name={`${valueForm}.majorCountriesOfOperation`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <Inputs
                  required={true}
                  label="Number of employees"
                  type="number"
                  placeHolder="Enter Number of employees"
                  name={`${valueForm}.numberOfEmployees1`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <Inputs
                  required={true}
                  label="Expected monthly transaction pattern(volume/value)"
                  type="number"
                  placeHolder="Enter Expected Monthly Transaction Pattern"
                  name={`${valueForm}.expectedMonthlyTransaction`}
                />
              </div>
            </div>
          </>
        )}

        {initialConstitution === "Partnership" && (
          <>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label={"Date of Partnership Deed"}
                type="date"
                placeHolder="Date of Partnership Deed"
                name={`${valueForm}.dateOfPartnerShip`}
              />
            </div>
            <div className="required">
              Certified true copy of Entity proof is annexed (anyone of
              following)
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <CustomCheckbox
                onChangeCheckbox={onChangeCheckbox}
                name="registrationCertificates1"
                valueForm={valueForm}
                value={
                  value[valueForm] &&
                  value[valueForm]["registrationCertificates1"]
                }
                setValue={setValue}
                label="Registration Certificate (in the case of a registered concern)"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <CustomCheckbox
                onChangeCheckbox={onChangeCheckbox}
                name="sale&IncomeTaxReturn1"
                valueForm={valueForm}
                value={
                  value[valueForm] && value[valueForm]["sale&IncomeTaxReturn1"]
                }
                setValue={setValue}
                label="Complete Sales & income tax returns where the Firm’s name is reflected, duly authenticated/acknowledged by the Income Tax Authorities"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <CustomCheckbox
                onChangeCheckbox={onChangeCheckbox}
                name="gst/cst/vatCertificate1"
                valueForm={valueForm}
                value={
                  value[valueForm] &&
                  value[valueForm]["gst/cst/vatCertificate1"]
                }
                setValue={setValue}
                label="CST/ VAT/ GST certificate (provisional/final)"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <CustomCheckbox
                onChangeCheckbox={onChangeCheckbox}
                name="certificateIssuedByAuthorities1"
                valueForm={valueForm}
                value={
                  value[valueForm] &&
                  value[valueForm]["certificateIssuedByAuthorities1"]
                }
                setValue={setValue}
                label="Certificate / registration document issued by Sales Tax / Service Tax /
                ProfessionalTax authorities"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <CustomCheckbox
                onChangeCheckbox={onChangeCheckbox}
                name="certificateOfMuncipal1"
                valueForm={valueForm}
                value={
                  value[valueForm] && value[valueForm]["certificateOfMuncipal1"]
                }
                setValue={setValue}
                label="Certificate / license issued by the Municipal authorities under Shop & Establishment Act"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <CustomCheckbox
                onChangeCheckbox={onChangeCheckbox}
                name="licenseIssuedAuthority1"
                valueForm={valueForm}
                value={
                  value[valueForm] &&
                  value[valueForm]["licenseIssuedAuthority1"]
                }
                setValue={setValue}
                label="License issued by the Registering authority like Certificate of Practice issued by Institute of Chartered Accountants of India, Institute of Cost Accountants of India, Institute of Company Secretaries of India, Indian Medical Council, Food and Drug Control Authorities, etc."
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                name={`${valueForm}.latestListOfPartners`}
                label="Latest List of Partners is updated in aforesaid partnership deed"
                options={["YES", "No"]}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                name={`${valueForm}.registeredAddress`}
                label="Registered Address is same as mentioned in Partnership Deed"
                options={["YES", "No"]}
              />
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                name={`${valueForm}.mailingAddressForAforesaid1`}
                label="Mailing Address is same as mentioned in Partnership Deed"
                options={["YES", "No"]}
              />
              {/* <Checkbox
                title={'Mailing Address is same as mentioned in Partnership Deed'}
                name={`${valueForm}.mailingAddressForAforesaid1`}
              /> */}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Major Countries Of Operation/Business"
                type="text"
                placeHolder="Enter Major Countries Of Operation/Business"
                name={`${valueForm}.majorCountriesOfOperation2`}
              />
            </div>
            <Checkbox
              title="License issued by the Registering authority like Certificate of Practice issued by Institute of Chartered Accountants of India, Institute of Cost Accountants of India, Institute of Company Secretaries of India, Indian Medical Council, Food and Drug Control Authorities, etc."
              name={`${valueForm}.licenseIssuedAuthority1`}
            />
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Number Of Employees"
                type="number"
                placeHolder="Enter Number Of Employees"
                name={`${valueForm}.numberOfEmployees2`}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Expected Monthly Transaction Pattern (Volume/Value)"
                type="number"
                placeHolder="Enter Expected Monthly Transaction Pattern"
                name={`${valueForm}.expectedMonthlyTransaction2`}
              />
            </div>
          </>
        )}

        {initialConstitution === "LLP" && (
          <>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label={"Board Meeting Date"}
                type="date"
                placeHolder="Enter Board Meeting Date"
                name={`${valueForm}.boardMeetingDate`}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                label="Latest Registered Address is updated on Ministry of Corporate Affairs Website"
                name={`${valueForm}.latestRegisteredAddress1`}
                options={["YES", "No"]}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                label="Latest Mailing Address is updated on Ministry of Corporate Affairs Website"
                name={`${valueForm}.latestMailingAddress1`}
                options={["YES", "No"]}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Dropdowns
                label="Latest Certificate of Incorporation/ commencement of Business is updated on Ministry of Corporate Affairs Website"
                name={`${valueForm}.latestCertificateOfIncorp`}
                options={["YES", "No"]}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Major Countries Of Operation/Business"
                type="text"
                placeHolder="Enter Major Countries Of Operation/Business"
                name={`${valueForm}.majorCountriesOfOperation2`}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Number of employees"
                type="number"
                placeHolder="Enter Number of employees"
                name={`${valueForm}.numberOfEmployees2`}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
              <Inputs
                required={true}
                label="Expected monthly transaction pattern(volume/value)"
                type="number"
                placeHolder="Enter Expected Monthly Transaction Pattern"
                name={`${valueForm}.expectedMonthlyTransaction2`}
              />
            </div>
          </>
        )}
      </div>

      <div className="row">
        <div className="col-auto">
          <button
            type="submit"
            className="mt-5 btn btn-primary me-4"
            // onClick={() => saveApplicationHandler(value[valueForm], valueForm, setFieldError)}
          >
            {loading ? (
              <span className="indicator-progress d-block">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoverLetterDetails;
