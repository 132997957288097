import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import {toastError, toastSuccess} from '../../../../_metronic/helpers/ToastifyHelper'
import {resetPassword} from '../../../API/api'

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password is required'),
})

export default function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('user') || '')
  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const formik: any = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      setLoading(true)
      resetPassword({email: user.email, password: values.password})
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            toastSuccess(res.data.message)
            if (!user) {
              navigate('/auth/login')
            } else {
              navigate('/summary')
            }
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          toastError(err.response?.data?.error || err.response?.data)
        })
    },
  })
  return (
    <div className='p-lg-15'>
      <form
        className='form w-lg-500px bg-body rounded shadow-sm p-10 mx-auto'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Reset Password</h1>
          <div className='text-gray-400 fw-semibold fs-4'>
            Enter your new password.
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fw-bold text-gray-900 fs-6'>Password</label>
          <input
            type='password'
            placeholder='Enter Your Password'
            autoComplete='off'
            value={formik?.values?.password}
            onChange={() => formik.handleChange()}
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
          <label className='form-label fw-bold text-gray-900 fs-6 mt-5'>Confirm Password</label>
          <input
            type='Password'
            placeholder='Enter Confirm Password'
            onChange={() => formik.handleChange()}
            value={formik?.values?.confirmPassword}
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bold me-4'
          >
            <span className='indicator-label'>Reset Password</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
