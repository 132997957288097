import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {
  // Link,
  useNavigate,
} from 'react-router-dom'
import {useFormik} from 'formik'

import {toastError, toastSuccess} from './../../../../_metronic/helpers/ToastifyHelper'
import {adminLogin, axiosDefaultHeader, forgetPassword} from '../../../API/api'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required(),
  password: Yup.string().required('Password is required'),
})

const initialValues = {
  username: 'loddy@gmail.com',
  password: 'Seikor@1234',
}

interface Props {
  setLoginUser: any
}

export default function AdminLogin({setLoginUser}: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [loadingResetPassword, setLoadingResetPassword] = useState(false)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      adminLogin({username: values.username, password: values.password})
        .then((res) => {
          setLoading(false)
          axiosDefaultHeader(res.data.accessToken)
          localStorage.setItem('user', JSON.stringify(res.data))
          setLoginUser(res.data)
          navigate('/summary')
        })
        .catch((err) => {
          setLoading(false)
          const errorMessage = err.response?.data.split(':')                    
          toastError(errorMessage[2] || err.response?.data?.error);
        })
    },
  })

  const resetPassword = () => {
    if(formik.values.username){
      setLoadingResetPassword(true)
      forgetPassword({email: formik.values.username})
      .then((res) => {
        setLoadingResetPassword(false)
        if (res.data.status === 'SUCCESS') {
          navigate('/auth/forgot-password', {state: formik.values.username})
          toastSuccess(res.data.message)
        }
        if (res.data.status === 'ERROR') {
          toastError(res.data.message)
        }
      })
      .catch((err) => {
        setLoadingResetPassword(false)
        toastError(err.response?.data?.error || err.response?.data)
      })
    } 
    else formik.setFieldError("username","username is a required field");
  }
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bold text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='email'
          name='username'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bold text-dark'>Password</label>
        <input
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          type='password'
          name='password'
          autoComplete='off'
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.password}</span>
          </div>
        )}
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress d-block'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='d-flex flex-center link-primary fs-6 fw-bold' onClick={resetPassword}>
        {!loadingResetPassword && <span className='indicator-label pointer'>Forgot Password ?</span>}
        {loadingResetPassword && (
          <span className='indicator-progress d-block'>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </div>
    </form>
  )
}
