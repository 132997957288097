import {ErrorMessage, Field} from 'formik'

interface Props {
  title: string
  value: any[]
  name: string
  required?: boolean
  disabled?:boolean
}
function RadioButton({title, value, name, required,disabled=false}: Props): JSX.Element {
  return (
    <div>
      <label className={`col-sm-3 col-form-label text-nowrap ${required && 'required'}`}>{title}</label>
      <div>
        {value.map((p, index) => (
          <div
            key={index}
            className='d-flex form-check form-check-custom form-check-solid me-4 form-check-sm mt-3'
          >
            <Field
              disabled={disabled}
              className='form-check-input'
              name={name}
              type='radio'
              value={p.value}
              id={p.name}
            />
            <label className={'form-check-label'} htmlFor={p.name}>
              {p.label}
            </label>
          </div>
        ))}
      </div>
      <div className='text-danger m-2'>
        <ErrorMessage name={name} />
      </div>
    </div>
  )
}

export default RadioButton
