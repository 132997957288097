import {FieldArray} from 'formik'
import {useEffect, useState} from 'react'
import {Dropdowns} from '../../../../_metronic/layout/components/Dropdowns'
import {deleteForm, getBuyer} from '../../../API/api'
import {ICreateAccount} from '../../../modules/wizards/components/CreateAccountWizardHelper'
import {toastError} from './../../../../_metronic/helpers/ToastifyHelper'

interface Props {
  saveApplicationHandler: any
  value: ICreateAccount
  setFieldError: any
  loading: boolean
  appId: string
  applicationData: any
  setApplicationData: any
}

export default function BuyerDetails({
  saveApplicationHandler,
  value,
  setFieldError,
  loading,
  appId,
  applicationData,
  setApplicationData,
}: Props): JSX.Element {
  const [buyerNameData, setBuyerNameData] = useState<any[]>([])
  const gotUser: any = JSON.parse(localStorage.getItem('user') || '')
  const isEditable: boolean = gotUser.roles.includes('RM')
  const applicationCopy = JSON.parse(JSON.stringify(applicationData));
  useEffect(() => {
    getBuyer()
      .then((res) => setBuyerNameData(res.data))
      .catch((err) => toastError(err.response?.data?.error || err.response?.data))
  }, [])

  const addBuyer = (d: any) => {
    d.push({buyerName: "",buyerAddress: ""});
    const newBuyer = JSON.parse(JSON.stringify(applicationCopy.buyerDetails[0]))
    newBuyer.dataUnits.buyerAddress.dataUnitCode = ''
    newBuyer.dataUnits.buyerName.dataUnitCode = ''
    newBuyer.collectionId = ''
    applicationCopy.buyerDetails[0].collectionId = ''
    applicationCopy.buyerDetails.push(newBuyer)
    setApplicationData(applicationCopy)
  }

  const deleteBuyer = (d: any, index: any) => {
    d.remove(index)
    // const newBuyer = JSON.parse(JSON.stringify(applicationCopy.buyerDetails[0]))
    // applicationCopy.splice(index);

    const buyerDataCopy = Object.assign(applicationData.buyerDetails[index])
    const deleteData: any = {
      applicationId: appId,
      forms: {
        buyerDetails: [
          {
            ...buyerDataCopy,
          },
        ],
      },
    }

    if (buyerDataCopy.collectionId) {
      deleteForm(deleteData, buyerDataCopy.collectionId, appId)
    }
  }


  return (
    <div className='card p-5 w-100'>
      <h3 className='border-bottom p-2'>Buyer Details</h3>
      <FieldArray
        name='buyerDetails'
        render={(d) => (
          <div>
            {value?.buyerDetails?.map((p: any, index: any) => (
              <div className='row g-3'>
                <div
                  className={`${
                    isEditable
                      ? 'col-xl-5 col-lg-5 col-md-5 col-sm-11'
                      : 'col-xl-6 col-lg-6 col-md-6 col-sm-12'
                  }`}
                >
                  <Dropdowns
                    required={true}
                    label='Buyer Name'
                    name={`buyerDetails[${index}].buyerName`}
                    options={buyerNameData?.map((b) => b.buyerName)}
                    isEditable={isEditable}
                  />
                </div>
                <div
                  className={`${
                    isEditable
                      ? 'col-xl-5 col-lg-5 col-md-5 col-sm-11'
                      : 'col-xl-6 col-lg-6 col-md-6 col-sm-12'
                  }`}
                >
                  <Dropdowns
                    required={true}
                    label='Buyer Address'
                    name={`buyerDetails[${index}].buyerAddress`}
                    options={buyerNameData?.map((b) => b.buyerAddress)}
                    isEditable={isEditable}
                  />
                </div>
                {isEditable && index > 0 && (
                  <div className='col-auto'>
                    <button
                      type='button'
                      className='mb-2 btn btn-danger mt-13'
                      onClick={() => deleteBuyer(d, index)}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))}

            {/* <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <label className='col-form-label col-form-label-sm'>Turnover</label>
          <Field
            type='number'
            name='turnover'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Turnover'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <label className=' col-form-label col-form-label-sm text-nowrap'>
            No of Year of Relationship
          </label>
          <Field
            type='number'
            name='yearOfRelationship'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter No of Year of Relationship'
          />
        </div>
      </div>
      <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <label className='col-sm-2 col-form-label col-form-label-sm text-nowrap'>
            Bill Discounting Facility
          </label>
          <Field
            type='number'
            name='billDiscounting'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Bill Discounting Facility'
          />
        </div>
      </div> */}
            {isEditable && (
              <div className='row'>
                <div className='col-auto'>
                  <button
                    type='submit'
                    className='mt-5 btn btn-primary me-4'
                    // onClick={() =>
                    //   saveApplicationHandler(value.buyerDetails, 'buyerDetails', setFieldError)
                    // }
                  >
                    {loading ? (
                      <span className='indicator-progress d-block'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
                <div className='col-auto'>
                  <button
                    type='button'
                    className='mt-5 btn btn-secondary me-4'
                    onClick={() => addBuyer(d)}
                  >
                    Add Buyer
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      />
    </div>
  )
}
