import { ErrorMessage, Field } from "formik";
import { FC } from "react";

interface DropdownModel {
  label: string;
  name: string;
  options?: string[];
  required?: boolean;
  isEditable?: boolean;
  disabled?: boolean;
  defaultValue?:any;
}

export const Dropdowns: FC<DropdownModel> = ({
  label,
  name,
  options,
  required,
  isEditable = true,
  disabled,
  defaultValue,
}) => {
  return (
    <div>
      <label className={`col-form-label ${required && "required"}`}>
        {label}
      </label>
      <Field
        disabled={!isEditable || disabled}
        as="select"
        name={name}
        className="form-control-lg form-select form-select-solid"
      >
        {defaultValue ? <option value={defaultValue?.countryCode}>{defaultValue?.countryName}</option> :<option value="">Select Option</option>}
        {options?.map((option, index) => {
          return (
            <option key={index} value={option}>
              {option}
            </option>
          );
        })}
      </Field>
      <div className="text-danger m-2">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};
