/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {AuthPage, Logout} from '../modules/auth'
import {App} from '../App'
import {axiosDefaultHeader} from '../API/api'
import {AdminRoutes} from './AdminRoutes'
import {getRefreshToken} from './../API/api'
import {toastError} from './../../_metronic/helpers/ToastifyHelper'
import axios from 'axios'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  // const {currentUser} = useAuth()

  const [loginUser, setLoginUser] = useState<any>(null)
  const [isSaved, setIsSaved] = useState<boolean>(false)

  useEffect(() => {
    const user: any = localStorage.getItem('user')
    const currentUser = JSON.parse(user)
    if (currentUser) {
      axiosDefaultHeader(currentUser.accessToken)
      setLoginUser(currentUser)
    }
    ;(() => {
      // axios.interceptors.request.use(
      //   (config) => {
      //     if (currentUser) {
      //       // getRefreshToken("eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmYWl6YWxAbWFpbGluYXRvci5jb20iLCJpYXQiOjE2NzgyODYwMTksImV4cCI6MTY3ODQxNTYxOX0.6RDx2oj8kmer32k0-5lP6wr27Anc4h-9MWGe__W-GGTCgde1fy9nTAgxq-ITTwjmitUoVkuMdkodT3PFG2qpSw").then(res => {
      //       //   axiosDefaultHeader(res.data);
      //       //   const updateUser = { ...currentUser, accessToken: res.data };
      //       //   localStorage.setItem("user", JSON.stringify(updateUser));
      //       //   setLoginUser(updateUser);
      //       //   // originalRequest.headers.Authorization = `Bearer ${res.data}`;
      //       //  axios(originalRequest).then(res => {
      //       //   return res
      //       //   }).catch(err => {
      //       //     return err
      //       //   })
      //       // }).catch(err => {
      //       //   console.log("error token")
      //       //   toastError(err.response?.data?.error || err.response?.data)
      //       // })
      //       // axiosDefaultHeader("eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmYWl6YWxAbWFpbGluYXRvci5jb20iLCJpYXQiOjE2NzgyODYwMTksImV4cCI6MTY3ODQxNTYxOX0.6RDx2oj8kmer32k0-5lP6wr27Anc4h-9MWGe__W-GGTCgde1fy9nTAgxq-ITTwjmitUoVkuMdkodT3PFG2qpSw");
      //     }
      //     return config
      //   },
      //   (error) => {
      //     Promise.reject(error)
      //   }
      // )
      axios.interceptors.response.use(
        (res: any) => res,
        (error) => {
          const originalRequest = error.config
          if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            if (currentUser) {
              return getRefreshToken(currentUser.accessToken)
                .then((res) => {
                  axiosDefaultHeader(res.data)
                  const updateUser = {...currentUser, accessToken: res.data}
                  localStorage.setItem('user', JSON.stringify(updateUser))
                  setLoginUser(updateUser)
                  originalRequest.headers.Authorization = `Bearer ${res.data}`
                  return axios(originalRequest)
                })
                .catch((err) => {
                  toastError(err.response?.data?.error || err.response?.data)
                })
            }
          }
          return Promise.reject(error)
        },
      )
    })()
  }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {loginUser?.roles?.includes('RM') ? (
            <>
              <Route
                path='/*'
                element={
                  <AdminRoutes
                    isSaved={isSaved}
                    setIsSaved={setIsSaved}
                    setLoginUser={setLoginUser}
                  />
                }
              />
              <Route index element={<Navigate to='/summary' />} />
            </>
          ) : loginUser?.roles?.includes('SUPPLIER') ? (
            <>
              <Route
                path='/*'
                element={
                  <PrivateRoutes
                    isSaved={isSaved}
                    setIsSaved={setIsSaved}
                    setLoginUser={setLoginUser}
                  />
                }
              />
              <Route index element={<Navigate to='/summary' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage setLoginUser={setLoginUser} />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
