import { useEffect, useState } from "react";
import { Dropdowns } from "../../../../_metronic/layout/components/Dropdowns";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import { getCountry } from "../../../API/api";
import { toastError } from "./../../../../_metronic/helpers/ToastifyHelper";
import { defaultSelectedCountry } from "../../../constants/DropdownConstants";

interface Props {
  value: any;
  setValue: any;
  saveApplicationHandler: any;
  setFieldError: any;
  loading: boolean;
}

export default function SupplierAgreementDetails({
  value,
  setValue,
  saveApplicationHandler,
  setFieldError,
  loading,
}: Props): JSX.Element {
  const [countryData, setCountryData] = useState<any[]>([]);
  // useEffect(() => {
  //   setValue('supplierAgreementDetails.nameOfCompany', value.businessInfo.nameOfCompany)
  //   setValue('supplierAgreementDetails.address', value.companyAddressDetails.address)
  //   setValue('supplierAgreementDetails.pinCode', value.companyAddressDetails.pinCode)
  //   setValue('supplierAgreementDetails.citySupplier', value.companyAddressDetails.city)
  //   setValue('supplierAgreementDetails.countryCode', value.companyAddressDetails.country)
  // }, [
  //   value.businessInfo.nameOfCompany,
  //   value.companyAddressDetails.address,
  //   value.companyAddressDetails.city,
  //   value.companyAddressDetails.pinCode,
  //   value.companyAddressDetails.country,
  // ])

  useEffect(() => {
    getCountry()
      .then((res) => {
        let dataArray = res?.data;
        // console.log("dataArray",dataArray)
        let indexOfIndia: any = null;
        dataArray?.forEach((country: any, index: number) => {
          if (country?.countryName == "India") {
            indexOfIndia = index;
          }
        });
        dataArray?.splice(indexOfIndia, 1);
        setCountryData(res.data);
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  }, []);

  useEffect(() => {
    if (value.supplierAgreementDetails.countryCode == "") {
      setValue("supplierAgreementDetails.countryCode", "India");
    }
  }, [countryData]);

  return (
    <div className="card p-5 w-100">
      <h3 className="border-bottom p-2">Supplier Agreement Details</h3>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Name of company"
            type="text"
            placeHolder="Enter Your Name of company"
            name="supplierAgreementDetails.nameOfCompany"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Address"
            type="text"
            placeHolder="Enter Address"
            name="supplierAgreementDetails.address"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="PIN Code"
            type="number"
            placeHolder="Enter PIN Code"
            name="supplierAgreementDetails.pinCode"
          />
          {/* <div className="col-auto">
            <button
              type="button"
              className="mt-1 btn btn-primary me-3"
              // onClick={() => deletePromoter(d, i)}
            >
              Validate
            </button>
          </div> */}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            defaultValue={defaultSelectedCountry}
            label="Country"
            name="supplierAgreementDetails.countryCode"
            options={countryData.map((c) => c.countryName)}
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="City"
            type="text"
            placeHolder="Enter Your City"
            name="supplierAgreementDetails.city"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Supplier Finance Agreement Date"
            type="date"
            placeHolder=""
            name="supplierAgreementDetails.date"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Place"
            type="text"
            placeHolder="Enter Place"
            name="supplierAgreementDetails.place"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Name of company"
            type="text"
            placeHolder="Enter Your Name of company"
            name="supplierAgreementDetails.nameOfCompany1"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Company PAN Details"
            type="text"
            placeHolder="Enter Company PAN Details"
            name="supplierAgreementDetails.companyPanDetails"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Address"
            type="text"
            placeHolder="Enter Address"
            name="supplierAgreementDetails.address1"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Power of Attorny"
            type="date"
            placeHolder="Enter Power of Attorny*"
            name="supplierAgreementDetails.date1"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Name of company"
            type="text"
            placeHolder="Enter Your Name of company"
            name="supplierAgreementDetails.nameOfCompany2"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Wheres Date"
            type="date"
            placeHolder="Enter Wheres Date"
            name="supplierAgreementDetails.date2"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Witness Name 1"
            type="text"
            placeHolder="Enter Your Witness Name 1"
            name="supplierAgreementDetails.witnessName1"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Wheres Witness Name 2"
            type="text"
            placeHolder="Enter Witness Name 2"
            name="supplierAgreementDetails.witnessName"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="In Witness whereof"
            type="date"
            placeHolder="Enter Your In Witness whereof"
            name="supplierAgreementDetails.date3"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Name of company"
            type="text"
            placeHolder="Enter Your Name of company"
            name="supplierAgreementDetails.nameOfCompany3"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Place"
            type="text"
            placeHolder="Enter Your Place"
            name="supplierAgreementDetails.place1"
          />
        </div>
      </div>
      {value.buyerDetails.map((buyer: any, i: string) => {
        return (
          <div className="row" key={i}>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <Inputs
                disabled
                label={`Company Name and Form of Organisation ${
                  value.buyerDetails.length > 1 ? i + 1 : ""
                }`}
                type="text"
                placeHolder="Enter Buyer Name"
                name={`buyerDetails[${i}].buyerName`}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <Inputs
                disabled
                label={`Company Address ${
                  value.buyerDetails.length > 1 ? i + 1 : ""
                }`}
                type="text"
                placeHolder="Enter Buyer Address"
                name={`buyerDetails[${i}].buyerAddress`}
              />
            </div>
          </div>
        );
      })}
      <div className="row">
        <div className="col-auto">
          <button
            type="submit"
            className="mt-5 btn btn-primary me-4"
            // onClick={() =>
            //   saveApplicationHandler(
            //     value.supplierAgreementDetails,
            //     'supplierAgreementDetails',
            //     setFieldError
            //   )
            // }
          >
            {loading ? (
              <span className="indicator-progress d-block">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
