import { useEffect, useState } from "react";
import { getAdminApplicationSummary } from "./../../../API/api";
import { toastError } from "./../../../../_metronic/helpers/ToastifyHelper";
import "./SupplierSummary.css";
import { KTSVG } from "../../../../_metronic/helpers";
import UpdateStatusModal from "../../../../_metronic/layout/components/UpdateStatusModal";
import { useNavigate } from "react-router-dom";
import OtherDocumentModel from "../../../../_metronic/layout/components/OtherDocumentModel";
export default function SupplierSummary() {
  const [applicationSummary, setApplicationSummary] = useState<any>({
    data: [],
    totalPage: 1,
  });
  const [supplierData, setSupplierData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [appId, setAppId] = useState<any>("");
  const navigate = useNavigate();
  const [isModalToggle, setIsModalToggle] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const[searchvalue,setSearchvalue]=useState<any>("");

  useEffect(() => {
    setLoading(true);
    getAdminApplicationSummary({ pageNo, pageSize: 10, searchBy: searchvalue })
      .then((res) => {
        setLoading(false);
        setApplicationSummary(res.data);
      })
      .catch((err) => {
        setLoading(false);
        toastError(err.response?.data?.error || err.response?.data);
      });
  }, [pageNo, searchvalue]);
  
  const submitFormOnEnter = (e:any) => {
    if (e.keyCode === 13) {
      setLoading(true);
      getAdminApplicationSummary({ pageNo, pageSize: 10, searchBy: searchvalue })
        .then((res) => {
          setLoading(false);
          setApplicationSummary(res.data);
        })
        .catch((err) => {
          setLoading(false);
          toastError(err.response?.data?.error || err.response?.data);
        });
    }
  };
  
  const setSupplierHandler = (data: object, e: any) => {
    e.stopPropagation();
    setSupplierData(data);
  };

  const handleOpenModal = (e: any, applicationId: any) => {
    e.stopPropagation();
    setAppId(applicationId);
    setIsModalToggle(!isModalToggle);
  };

  const handlePagination = (e: any, page: number) => {
    e.preventDefault();
    setPageNo(page);
  };

  return (
    <div className="pt-15">
      <UpdateStatusModal supplierData={supplierData} />
      <OtherDocumentModel isModalToggle={isModalToggle} appId={appId} />
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3">Supplier Summary</span>
          </h3>
        </div>
        <div className=" my-2 d-grid  d-md-flex">
          <div className="col fs-24 fw-700"></div>
          <div className="page-search m-0 col-12 col-md-5 col-lg-4 mt-2 mt-md-0">
            <input
              className="col-10 user-btn-focus"
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchvalue(e.target.value)}
              onKeyDown={(e) => submitFormOnEnter(e)}
            />
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <table className="table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted">
                  <th className="min-w-80px">Name of Company</th>
                  <th className="min-w-140px">Buyer Name</th>
                  <th className="min-w-120px">Contact Number</th>
                  <th className="min-w-120px">Contact Email</th>
                  <th className="min-w-120px">Progress</th>
                  <th className="min-w-120px">Status</th>
                  <th className="min-w-130px">Actions</th>
                  <th className="min-w-120px"></th>
                  <th className="min-w-120px text-end"></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={7}>
                      <span className="indicator-progress d-block text-center">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </td>
                  </tr>
                ) : (
                  applicationSummary.data.map((summary: any) => {
                    return (
                      <tr
                        onClick={() =>
                          navigate("/application", {
                            state: { applicationId: summary.applicationId },
                          })
                        }
                        key={summary.applicationId}
                        className="cursor-pointer"
                      >
                        <td
                          onClick={() =>
                            navigate("/application", {
                              state: { applicationId: summary.applicationId },
                            })
                          }
                        >
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <label className="text-dark fw-bold text-hover-primary fs-6">
                                {summary.supplierName}
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <label className="text-dark fw-bold text-hover-primary d-block fs-6">
                            {summary.buyerName.join(", ")}
                          </label>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <label className="text-dark fw-bold text-hover-primary fs-6">
                                {summary.contactNumber}
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <label className="text-dark fw-bold text-hover-primary fs-6">
                                {summary.contactEmail}
                              </label>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <div className="d-flex flex-column w-100 me-2">
                            <div className="d-flex flex-stack mb-2">
                              <span className="text-muted me-2 fs-7 fw-semibold">
                                {summary.progress}%
                              </span>
                            </div>
                            <div className="progress h-6px w-100">
                              <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                style={{ width: `${summary.progress}%` }}
                              ></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <label className="text-dark fw-bold text-hover-primary fs-6">
                                {summary.updateStatus}
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end flex-shrink-0 ">
                            <button
                              onClick={(e) =>
                                setSupplierHandler(
                                  {
                                    applicationCode: summary.applicationId,
                                    status: summary.updateStatus,
                                  },
                                  e
                                )
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_status"
                              className="btn btn-sm btn-primary text-nowrap"
                            >
                              <span className="me-2">Update Status</span>
                              <KTSVG
                                path="/media/icons/duotune/art/art005.svg"
                                className="svg-icon-4"
                              />
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end flex-shrink-0 ">
                            <button
                              onClick={(e) =>
                                handleOpenModal(e, summary.applicationId)
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#document"
                              className="btn btn-sm btn-success text-nowrap"
                            >
                              <span className="me-2">Update Document</span>
                              <KTSVG
                                path="/media/icons/duotune/art/art005.svg"
                                className="svg-icon-4"
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <ul className="pagination flex-end">
            <li
              className={`page-item previous ${pageNo === 1 ? "disabled" : ""}`}
            >
              <a
                href=""
                onClick={(e) => handlePagination(e, pageNo - 1)}
                className="page-link"
              >
                <i className="previous"></i>
              </a>
            </li>
            {new Array(applicationSummary.totalPage)
              .fill("_")
              .map((_, index) => {
                return (
                  <li
                    className={`page-item ${
                      index + 1 === pageNo ? "active" : ""
                    }`}
                    key={index}
                  >
                    <a
                      href=""
                      onClick={(e) => handlePagination(e, index + 1)}
                      className="page-link"
                    >
                      {index + 1}
                    </a>
                  </li>
                );
              })}
            <li
              className={`page-item next ${
                pageNo === applicationSummary.totalPage ? "disabled" : ""
              }`}
            >
              <a
                href=""
                onClick={(e) => handlePagination(e, pageNo + 1)}
                className="page-link"
              >
                <i className="next"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
