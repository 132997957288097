import {ErrorMessage, Field} from 'formik'

interface Props {
  title: string
  name: string
  required?: boolean
  value?: string
}
export default function Checkbox({title, name, required, value}: Props): JSX.Element {
  return (
    <div>
      <div className='d-flex'>
        <div className='d-flex form-check form-check-custom form-check-solid me-4 form-check-sm mt-3'>
          <label className='form-check-label d-flex'>
            <Field className='form-check-input me-2' name={name} type='checkbox' />
            <span className={`${required && 'required'}`}>{title}</span>
          </label>
        </div>
      </div>
      <div className='text-danger m-2'>
        <ErrorMessage name={name} />
      </div>
    </div>
  )
}
