import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import {
  toastError,
  toastSuccess,
} from "../../../../_metronic/helpers/ToastifyHelper";
import { FieldArray, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import {
  checkGSTIN,
  createSupplier,
  getBuyer,
  misReport,
} from "../../../API/api";
import { MultiDropdown } from "../../../../_metronic/layout/components/MultiDropdown";
import { useNavigate } from "react-router-dom";
import { cacheValidation } from "../../../../_metronic/helpers/ArrayHelper";

const initial = {
  buyerName: [],
  newBuyers: [],
};

const validationSchema = Yup.object({
  buyerName: Yup.array().when("newBuyers", (newBuyers, Schema) =>
    newBuyers.length > 0
      ? Schema
      : Schema.min(1, "Buyer Name is required field")
          .required("Buyer Name is required field")
          .nullable()
  ),

  newBuyers: Yup.array().of(
    Yup.object({
      buyerName: Yup.string().required("Buyer name is a required field"),
      buyerAddress: Yup.string().required("Buyer address is a required field"),
    })
  ),
});
export default function MisReport(): JSX.Element {
  const [initialValues] = useState<object>(initial);
  const [buyers, setBuyers] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [buyersNames, setbuyersNames] = useState<any[]>([]);
  //   useState<string>('');
  const [formDate, setFormDate] = useState<String>("");
  const [isBuyerSelected, setIsBuyerSelected] = useState<boolean>(false);
  // const [isDateSelected, setIsDateSelected] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    getBuyer()
      .then((res) => {
        setBuyers(res.data);
      })
      .catch((err) => {
        toastError(err.response?.data?.error || err.response?.data);
      });
  }, []);

  const createSupplierHandler = (values: any, actions: any) => {
    // console.log(values, "values");
    // setIsDateSelected(!!values);
  };

  const downloadReport = (values?: any): void => {
    setLoading(true);

    misReport({
      buyerName: buyersNames,
      toDate: values?.ToDate,
      fromDate: values?.FDate,
    })
      .then((res) => {
        setLoading(false);

        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/vnd.ms-excel" })
        );
        const a = document.createElement("a");
        a.href = url;
        a.style.display = "none";
        a.download = "file.xls";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        setLoading(false);
        toastError(err.response?.data?.error || err.response?.data);
      });
  };

  const downloadMisReport = (values?: any): void => {
    setLoading(true);

    misReport({
      // buyerName: buyersNames,
      toDate: values?.ToDate,
      fromDate: values?.FDate,
    })
      .then((res) => {
        setLoading(false);

        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/vnd.ms-excel" })
        );
        const a = document.createElement("a");
        a.href = url;
        a.style.display = "none";
        a.download = "file.xls";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        setLoading(false);
        toastError(err.response?.data?.error || err.response?.data);
      });
  };

  const handleSelectBuyer = (options: any, setFieldValue: any) => {
    const selectedBuyers = options
      .map((option: any) => option.label)
      .join(", ");
    setIsBuyerSelected(!!options);
    setbuyersNames(selectedBuyers);

    setFieldValue("buyerName", options);
  };

  return (
    <div className="card p-5 w-100 mt-15">
      <h3 className="border-bottom p-2">Mis Reports</h3>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={createSupplierHandler}
        render={(props: FormikProps<any>) => {
          console.log(props.values.FDate==="","true@@")
          return (
            <>
              <div className="row g-3">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <Inputs
                    required={true}
                    label="From Date"
                    type="date"
                    placeHolder="Enter Your GST No"
                    name="FDate"
                    disabled={isBuyerSelected}
                    
                  />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <Inputs
                    required={true}
                    label="To Date"
                    type="date"
                    placeHolder="Enter Your Company Name"
                    name="ToDate"
                    disabled={isBuyerSelected}
                  />
                </div>
                {!isBuyerSelected && (
                  <div>
                  <button
                    type="button"
                    className="btn btn-primary me-4 btn-sm"
                    onClick={() => downloadMisReport(props.values)}
                  >
                    Download
                  </button>
                </div>
                )}
                
                <br/>
                <h3 className="border-bottom p-2">Buyer Reports</h3>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  
                  {!props.values.FDate && !props.values.ToDate && (
                    <MultiDropdown
                      required={true}
                      label="Select Buyer"
                      name="Select Buyer"
                      value={props.values.buyerName}
                      onChange={(options: any) =>
                        handleSelectBuyer(options, props.setFieldValue)
                      }
                      options={buyers.map((buyer) => ({
                        value: buyer.buyerCode,
                        label: buyer.buyerName,
                      }))}
                      error={props.errors?.buyerName}
                    />
                  )}
                </div>
              </div>
              {!props.values.FDate && !props.values.ToDate && (
              <div className="row">
                <div className="col-auto">
                  <button
                    type="submit"
                    onClick={() => downloadReport(props.values)}
                    className="mt-5 btn btn-primary me-4"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span className="indicator-progress d-block text-center">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span>Download</span>
                    )}
                  </button>
                </div>
              </div>
               )}
            </>
          );
        }}
      ></Formik>
    </div>
  );
}
