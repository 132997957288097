import { useEffect, useState } from "react";
import {
  addOtherDocument,
  getApplication,
  getAssigneeList,
  getCurrentAssignee,
  getOtherDocument,
  updateAssignee,
} from "../../../app/API/api";
import { KTSVG } from "../../helpers";
import { toastError, toastSuccess } from "../../helpers/ToastifyHelper";
import { MultiDropdown } from "./MultiDropdown";
import { Dropdowns } from "./Dropdowns";
import { ErrorMessage, Field, FieldArray, Formik, FormikProps } from "formik";

interface Props {
  appId: string;
  isModalToggle: boolean;
}

export default function OtherDocumentModel({
  appId,
  isModalToggle,
}: Props): JSX.Element {
  const [initialValues, setInitialValues] = useState<object>({});
  const [otherDocument, setOtherDocument] = useState<any[]>([]);
  const [otherDocumentValue, setOtherDocumentValue] = useState<any[]>([]);
  const [collectionName, setCollectionName] = useState<string>("");
  const [isLoadingSave, setLoadingSave] = useState<boolean>(false);
  const [isUpdateSave, setIsUpdateSave] = useState<boolean>(false);

  const [isLoadingDoc, setLoadingDoc] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sspOtherDocuments, setSSPOtherDocuments] = useState<any[]>([]);
  const [assignees, setAssignees] = useState<any[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<any>("");
  const [sspOtherDocumentError, setSSPOtherDocumentError] =
    useState<string>("");

  useEffect(() => {
    getOtherDocumentHandler();
    getAsigneeListApiCall();
    if (appId) {
      getCurrentAssigneeApiCall();
    }
  }, [appId, isModalToggle]);

  const getAsigneeListApiCall = () => {
    getAssigneeList()
      .then((res) => {
        setAssignees(res?.data?.data);
      })
      .catch((err) => {
        toastError(err?.response?.data?.error || err?.response?.data);
      });
  };

  const getCurrentAssigneeApiCall = () => {
    getCurrentAssignee(appId)
      .then((res) => {
        if (res?.data?.data?.name) {
          setSelectedAssignee(res?.data?.data?.name);
        } else {
          setSelectedAssignee("");
        }
      })
      .catch((err) => {
        setSelectedAssignee("");
        toastError(err?.response?.data?.error || err?.response?.data);
      });
  };

  const getOtherDocumentHandler = () => {
    setLoadingDoc(true);
    getOtherDocument()
      .then((res) => {
        setLoadingDoc(false);
        const documentOptions = res.data.map((document: any) => ({
          value: document.otherDocCode,
          label: document.otherDocName,
        }));
        setOtherDocument(documentOptions);
        getApplicationHandler(appId, documentOptions);
      })
      .catch((err) => {
        setLoadingDoc(false);
        toastError(err.response?.data?.error || err.response?.data);
      });
  };

  const getApplicationHandler = (appId: string, documentOptions: any) => {
    if (appId) {
      setLoading(true);
      getApplication(appId)
        .then((res) => {
          setLoading(false);
          const constitution =
            res.data.forms.businessInfo[0].dataUnits.constitution.dataUnitValue;
          const collectionName =
            constitution === "Private Limited"
              ? "privateLimitedDoc"
              : constitution === "Public Limited"
                ? "privateLimitedDoc"
                : constitution === "Partnership"
                  ? "partnershipDeepDoc"
                  : constitution === "LLP"
                    ? "LLPDoc"
                    : constitution === "Sole Proprietor" || constitution === "HUF"
                      ? "soloProprietorDoc"
                      : "partnershipDeepDoc";
          setCollectionName(collectionName);
          const { dataUnits } = res.data.forms[collectionName][0];

          const docKey = Object.keys(dataUnits);
          const getOtherDocument = documentOptions.filter((doc: any) =>
            docKey.find((key) => dataUnits[key].dataUnitLabel === doc.label)
          );
          setOtherDocumentValue(getOtherDocument);
        })
        .catch((err) => {
          setLoading(false);
          setOtherDocumentValue([]);
          toastError(err.response?.data?.error || err.response?.data);
        });
    }
  };

  const submitOtherDocument = (appId: string) => {
    const isDocEmpty = sspOtherDocuments.find((doc) => !doc.otherDocName);
    if (isDocEmpty) {
      setSSPOtherDocumentError("Document Option is Required!");
      return;
    }
    setLoadingSave(true);
    const payload = {
      otherDoc: otherDocumentValue.map((p) => p.value),
      sspOtherDocuments,
    };
    addOtherDocument(appId, collectionName, payload)
      .then((res) => {
        setLoadingSave(false);
        toastSuccess(res.data.message);
        setSSPOtherDocuments([]);
        getOtherDocumentHandler();
      })
      .catch((err) => {
        setLoadingSave(false);
        toastError(err.response?.data?.error || err.response?.data);
      });
  };

  const handleOnChange = ({ target }: any, id: number) => {
    setSSPOtherDocumentError("");
    const docCopy: any[] = Object.assign([], sspOtherDocuments);
    const index: number = docCopy.findIndex((doc: any) => doc.id === id);
    docCopy[index] = { id, otherDocName: target.value };
    setSSPOtherDocuments(docCopy);
  };

  const addCustomComments = () => {
    const isDocEmpty = sspOtherDocuments.find((doc) => !doc.otherDocName);
    if (isDocEmpty) {
      setSSPOtherDocumentError("Document Option is Required!");
      return;
    }
    const id = sspOtherDocuments.length
      ? Math.max(...sspOtherDocuments.map((doc) => doc.id)) + 1
      : 1;
    setSSPOtherDocuments((prev) => [...prev, { id, otherDocName: "" }]);
  };

  const handleDeleteOption = (id: any) => {
    setSSPOtherDocumentError("");
    setSSPOtherDocuments(sspOtherDocuments.filter((doc) => doc.id !== id));
  };

  const createSupplierHandler = (values: any, actions: any) => { };

  const updateAssigneeApiCall = () => {
    setIsUpdateSave(true);

    if (selectedAssignee != "") {
      let payload = {};

      assignees?.map((assignee: any) => {
        if (assignee?.name == selectedAssignee) {
          payload = { ...assignee };
          return;
        }
      });

      updateAssignee(appId, payload)
        .then((res) => {
          // console.log("res in update", res);
          if (res?.data?.data != "" || res?.data?.data != null) {
            toastSuccess(res?.data?.data || res?.data?.message);
          } else {
            toastError(res?.data?.data || res?.data?.message);
          }
          setIsUpdateSave(false);
        })
        .catch((err) => {
          setIsUpdateSave(false);
          toastError(err.response?.data?.error || err.response?.data);
        });
    } else {
      toastError("Field Is Empty");
      setIsUpdateSave(false);
    }
  };

  return (
    <div className="modal fade" tabIndex={-1} id="document">
      <div className="modal-dialog">
        <div className="modal-content" style={{ height: "600px" }}>
          <div className="modal-header">
            <h5 className="modal-title">Document / Update Assignee</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setSSPOtherDocuments([])}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          {isLoading || isLoadingDoc ? (
            <div className="p-6">
              <span className="indicator-progress d-block text-center">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </div>
          ) : (
            <div className="p-3">
              <MultiDropdown
                value={otherDocumentValue}
                onChange={(option: any) => setOtherDocumentValue(option)}
                options={otherDocument}
              />
              <div
                className="py-5 overflow-scroll"
              // style={{ height: 270 }}
              >
                {sspOtherDocuments.map((doc) => {
                  return (
                    <div className="d-flex">
                      <input
                        key={doc.id}
                        onChange={(e) => handleOnChange(e, doc.id)}
                        type="text"
                        value={doc.otherDocName}
                        className="form-control form-control-lg form-control-solid mb-3 me-2"
                        placeholder="Enter Option Name"
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-light mb-3"
                        onClick={() => handleDeleteOption(doc.id)}
                      >
                        <KTSVG
                          className="svg-icon-1 m-0"
                          path="/media/icons/duotune/arrows/arr061.svg"
                        />
                      </button>
                    </div>
                  );
                })}
                <p className="text-danger">{sspOtherDocumentError}</p>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => addCustomComments()}
                >
                  Add Option
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-primary ms-2"
                  onClick={() => submitOtherDocument(appId)}
                >
                  {isLoadingSave ? (
                    <span className="indicator-progress d-block text-center">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <span>Save Document</span>
                  )}
                </button>
              </div>

              <div className="p-2">
                <p className="mt-3">
                  <b>Update Assignee</b>
                </p>

                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, actions) => {
                    createSupplierHandler(values, actions);
                  }}
                  render={(props: any) => {
                    return (
                      <>
                        <label className="col-form-label required">
                          Assignee Name
                        </label>
                        <Field
                          disabled={false}
                          as="select"
                          name={"AssigneeName"}
                          className="form-control-lg form-select form-select-solid"
                          onChange={(options: any) => {
                            setSelectedAssignee(options?.target?.value);
                          }}
                          value={selectedAssignee}
                        >
                          <option value="">Select Option</option>
                          {assignees?.map((option, index) => {
                            return (
                              <option key={index} value={option?.name}>
                                {option?.name}
                              </option>
                            );
                          })}
                        </Field>
                        <div className="text-danger m-2">
                          <ErrorMessage name={"AssigneeName"} />
                        </div>

                      </>
                    );

                    {/* <Dropdowns
                          required={true}
                          label="Assigner Name"
                          name="AssignerName"
                          // value={selectedAssignee}
                          // onChange={(options: any) => {
                          //   setSelectedAssignee(options?.target?.value);
                          // }}
                          options={assignees?.map((assignee) => assignee?.name)}
                        // error={props.errors?.buyerName}
                        /> */}
                  }}
                ></Formik>

                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => updateAssigneeApiCall()}
                >
                  {isUpdateSave ? (
                    <span className="indicator-progress d-block text-center">
                      Updating...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <span>Update</span>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
