import {useEffect, useState} from 'react'
import {Dropdowns} from '../../../../_metronic/layout/components/Dropdowns'
import {Inputs} from '../../../../_metronic/layout/components/Inputs'
import {getConstitution, getSector} from '../../../API/api'
import {ICreateAccount} from './../../../modules/wizards/components/CreateAccountWizardHelper'
import {toastError} from './../../../../_metronic/helpers/ToastifyHelper'

interface Props {
  value: ICreateAccount
  saveApplicationHandler: any
  setFieldError: any
  loading: boolean
  setConstitutionList: any
}
export default function BusinessInfo({
  value,
  saveApplicationHandler,
  setFieldError,
  loading,
  setConstitutionList,
}: Props): JSX.Element {
  const [constitutionData, setConstitutionData] = useState<any[]>([])
  const [sectorData, setSectorData] = useState<any[]>([])

  useEffect(() => {
    getSector()
      .then((res) => setSectorData(res.data))
      .catch((err) => toastError(err.response?.data?.error || err.response?.data))

    getConstitution()
      .then((res) => {
        setConstitutionList(res.data)
        setConstitutionData(res.data)
      })
      .catch((err) => toastError(err.response?.data?.error || err.response?.data))
  }, [])
  return (
    
    <div className='card p-5 w-100'>
      <h3 className='border-bottom p-2'>Business Info</h3>
      <div className='row g-3'>
        {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs label='Date' type='date' placeHolder='' name='businessInfo.applicationDate' />
        </div> */}
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='GST linked with registered address'
            type='text'
            placeHolder='Enter Your GST No'
            name='businessInfo.gstNumber'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Name of company'
            type='text'
            placeHolder='Enter Your Name of company'
            name='businessInfo.nameOfCompany'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns
            disabled={!!value.businessInfo.gstNumber}
            label='Constitution'
            name='businessInfo.constitution'
            options={constitutionData?.map((v) => v.constitutionName)}
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns
            required={true}
            label='Name of Business/Sector'
            name='businessInfo.businessSector'
            options={sectorData?.map((v) => v.sectorName)}
          />
        </div>
        {(value.businessInfo.constitution && value.businessInfo.constitution !== 'Partnership' && value.businessInfo.constitution !== 'Sole Proprietor') && (
          <>
          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
            <Inputs
              required={true}
              label='CIN No'
              type='text'
              placeHolder='Enter CIN No'
              name='businessInfo.cinNo'
            />
          </div>
          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
            <Inputs
              required={true}
              label='Name of Entity as per MCA'
              type='text'
              placeHolder='Enter Your Name of Entity as per MCA*'
              name='businessInfo.nameOfEntityAsPerMca'
            />
          </div>
          </>
        )}
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Pan No'
            type='text'
            placeHolder='Enter Pan No'
            name='businessInfo.panNo'
          />
        </div>
        {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Currency'
            type='text'
            placeHolder='Enter Currency'
            name='businessInfo.currency'
          />
        </div> */}
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Sales(Turnover)'
            type='text'
            placeHolder='Enter Sales(Turnover)'
            name='businessInfo.salesTurnover'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Name of Contact Person'
            type='text'
            placeHolder='Enter Name of Contact Person*'
            name='businessInfo.nameOfContactPerson'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Email ID'
            type='email'
            placeHolder='Enter Email ID'
            name='businessInfo.emailId'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Brief Description of Nature of Business'
            type='text'
            placeHolder='Enter Brief Description of Nature of Business'
            name='businessInfo.briefDescriptionOfNatureOfBusiness'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Date of Incorporation'
            type='date'
            placeHolder='Enter Date of Incorporation'
            name='businessInfo.dateOfIncorporation'
            
          />
          
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Balance Sheet Total For Last available year'
            type='text'
            placeHolder='Enter Balance Sheet Total For Last available year'
            name='businessInfo.balanceSheetTotalForLastAvailableYear'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            label='Contact No'
            type='number'
            placeHolder='Enter Contact No*'
            name='businessInfo.contactNo'
          />
        </div>

        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={false}
            label='Remarks'
            type='text'
            placeHolder='Enter Remarks'
            name='businessInfo.remark'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={false}
            label='Comments'
            type='text'
            placeHolder='Enter Comments'
            name='businessInfo.comments'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={false}
            label='Additional Info'
            type='text'
            placeHolder='Enter Additional Information'
            name='businessInfo.additionalInfo'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={false}
            label='Others'
            type='text'
            placeHolder='Enter Other Details'
            name='businessInfo.other'
          />
        </div>
        {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns
            required={true}
            label='Customer Type'
            name='customerType'
            options={['Sole Proprietor', 'Partnership', 'Private Limited', 'Public Limited', 'LLP']}
          />
        </div> */}
      </div>

      {/* <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs required={true} label='GST No' type='text' placeHolder='Enter Your GST No' name='gstNumber' />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs required={true}
            label='Name Of Application As Per GSTIN'
            type='text'
            placeHolder='Enter Name Of Application As Per GSTIN'
            name='nameOfApplication'
          />
        </div>
      </div>
      <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns required={true} label='Industry' name='industry' />
        </div>
      </div>
      <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns required={true} label='Year In Business' name='yearInBusiness' />
        </div>
      </div>       */}
      <div className='row'>
        <div className='col-auto'>
          <button
            type='submit'
            // onClick={() =>
            //   saveApplicationHandler(value.businessInfo, 'businessInfo', setFieldError)
            // }
            className='mt-5 btn btn-primary me-4'
          >
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
