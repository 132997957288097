import {useEffect, useRef, useState} from 'react'
import {Form, Formik, FormikProps} from 'formik'
import {useLocation} from 'react-router-dom'
import './Application.css'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../_metronic/helpers'
import {
  createAccountSchemas,
  ICreateAccount,
  inits,
} from '../../modules/wizards/components/CreateAccountWizardHelper'
import AuthorisedSignatory from './AuthorisedSignatory/AuthorisedSignatory'
import BusinessInfo from './BusinessInfo/BusinessInfo'
import BuyerDetails from './BuyerDetails/BuyerDetails'
import CompanyAddressDetails from './CompanyAddressDetails/CompanyAddressDetails'
import CoverLetterDetails from './CoverLetterDetails/CoverLetterDetails'
// import MoreThanShareholder from './MoreThanShareholder/MoreThanShareholder'
import PromoterInfo from './PromoterInfo/PromoterInfo'
import Shareholder from './Shareholder/Shareholder'
import SIKDetails from './SIKDetails/SIKDetails'
import SupplierAgreementDetails from './SupplierAgreementDetail/SupplierAgreementDetails'
import {deleteForm, getApplication, setConstitutionValue, submitApplication} from '../../API/api'
// import ConfirmationLetter from './ConfirmationLetter/ConfirmationLetter'
// import ListOfOtherDocuments from './ListOfOtherDocuments/ListOfOtherDocuments'
import {saveApplication} from './../../API/api'
import {toastError, toastSuccess} from './../../../_metronic/helpers/ToastifyHelper'
import EOI from './EOI/EOI'
import Document from '../Documents/Document'
import Template from '../Template/Template'
import {stepperList} from './../../../_metronic/helpers/ArrayHelper'
import {getStepperData} from '../../modules/auth/core/StepperHelper'
import WarningPopup from './../../../_metronic/layout/components/WarningPopup'
import {set} from 'lodash';

interface Props {
  applicationId?: string
  step?: number
  applicationSummary?: any
  applicationSubmitted?: boolean
  isSaved: boolean
  setIsSaved: any
  isDocumentTabShow?: boolean
  isTemplateTabShow?: boolean
}

export default function Application({
  applicationId,
  applicationSummary,
  applicationSubmitted,
  setIsSaved,
  isSaved,
  isDocumentTabShow,
  isTemplateTabShow,
}: Props) {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [initValues, setInitValues] = useState<any>(inits)
  const [constitution, setConstitution] = useState<string>('')
  const [currentCollection, setCurrentCollection] = useState<string>('businessInfo')
  const [initialConstitution, setInitialConstitution] = useState<string>('')
  const [constitutionList, setConstitutionList] = useState<Array<any>>([])
  const [currentSchema, setCurrentSchema] = useState<any>(
    createAccountSchemas(initialConstitution, false)[0]
  )
  const [isSubmitButton, setSubmitButton] = useState<boolean>(false)
  const [stepperData, setStepperData] = useState(stepperList)
  const [applicationData, setApplicationData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [isStep, setIsStep] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const location: any = useLocation()
  const appId: any = location.state?.applicationId || applicationId
  const [step, setStep] = useState<any>(location.state?.step)
  const [stepIndex, setStepIndex] = useState<number>(1)

  const formikRef = useRef<FormikProps<ICreateAccount>>(null)
  const valueForm =
    constitution === 'Sole Proprietor'
      ? 'soloProprietor'
      : constitution === 'Private Limited'
      ? 'privateLimited'
      : constitution === 'Public Limited'
      ? 'publicLimited'
      : constitution === 'Partnership'
      ? 'partnership'
      : constitution === 'LLP'
      ? 'LLP'
      : constitution === 'HUF'
      ? 'HUF'
      : ''

  const constitutionFields = [
    'soloProprietor',
    'privateLimited',
    'publicLimited',
    'partnership',
    'LLP',
    'HUF',
  ]

  useEffect(() => {
    loadStepper()
    setIsStep(true)
  }, [])

  useEffect(() => {
    if (location.pathname === '/documents' && stepper.current) {
      if (!isDocumentTabShow) {
        toastError('Please complete previous steps. If completed your application is in review. ')
      } else {
        const index = stepperData.findIndex((p) => p.stepperTitle === 'Document')
        if (isStep) {
          stepper.current?.goto(index + 1)
        } else {
          stepper.current?.goto(index)
        }
      }
    } else if (location.pathname === '/template' && stepper.current) {
      if (!isTemplateTabShow) {
        toastError('Please complete previous steps. If completed your application is in review. ')
      } else {
        const index = stepperData.findIndex((p) => p.stepperTitle === 'Template')
        if (isStep) {
          stepper.current?.goto(index + 1)
        } else {
          stepper.current?.goto(index)
        }
      }
    }
  }, [location.pathname, stepper.current])

  useEffect(() => {
    if (step && stepper.current && Object.keys(applicationData).length) {
      const stepIndex = stepperData.findIndex((s: any) => s.value === step)
      if (isStep) {
        gotoAnotherStep(stepIndex + 1)
      } else {
        gotoAnotherStep(stepIndex)
      }
      setStep(null)
    }
  }, [step, stepper.current, applicationData])

  const getForms = () =>
    getApplication(appId)
      .then((res) => {
        const {forms} = res.data
        
        if (forms) {
          setApplicationData(forms)
          setIsSaved(false)
          const dataKey: any = Object.keys(forms)
          const formObject = dataKey.reduce((pre: any, curr: any) => {
            let data
            if (
              curr === 'buyerDetails' ||
              curr === valueForm ||
              curr === constitutionFields.find((p) => p === curr) ||
              curr === 'directorPromoterInfo' ||
              curr === 'soloProprietor' ||
              curr === 'privateLimited' ||
              curr === 'LLP' ||
              curr === 'shareholder' ||
              curr === 'sikDetails' ||
              curr === 'authorizeSignatory' ||
              curr === 'partnership'
            ) {
              data = forms[curr].map((d: any, i: number) => {
                const key: any = Object.keys(d.dataUnits)
                // key.forEach((k: string) => console.log(curr, k, {v: formikRef.current?.getFieldMeta(`${curr}[${i}].${k}`).value}))
                return key.reduce((childPrev: any, childCurr: any) => {
                  let value = d.dataUnits[childCurr].dataUnitValue
                  if (value === 'false') value = false
                  if (key === 'addressProof' && !value) value = 'APS01'
                  return {
                    ...childPrev,
                    [childCurr]: value,
                  }
                }, {})
              })
            } else {
              const {dataUnits} = forms[curr][0]
              const key: any = Object.keys(dataUnits)
              // key.forEach((k:string) => console.log(curr,k, {v:formikRef.current?.getFieldMeta(`${curr}.${k}`).value}));
              data = key.reduce(
                (childPrev: any, childCurr: any) => ({
                  ...childPrev,
                  [childCurr]: dataUnits[childCurr].dataUnitValue,
                }),
                {}
              )
            }
            return {...pre, [curr]: data}
          }, {})
          setInitialConstitution(formObject.businessInfo.constitution)
          setInitValues(formObject)
          // formikRef.current?.handleChange(console.log);
        }
      })
      .catch((err) => {
        toastError(err.response?.data?.error || err.response?.data)
      })

  useEffect(() => {
    if (isSaved || appId) {
      getForms()
    }
  }, [isSaved, appId])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    if (applicationData)
      setStepperData(getStepperData(constitution, applicationData) || stepperList)
  }, [constitution ,applicationData])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
    setCurrentSchema(
      createAccountSchemas(initialConstitution, false)[stepper.current.currentStepIndex - 1]
    )
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)
  }

  const submitFormHandler = async (value: any) => {
    if (!applicationSubmitted) return
    setSubmitLoading(true)

    let submitData: any = {}
    const collactionkeys = Object.keys(applicationData)
    for (const collactionkey of collactionkeys) {
      const collectionData = applicationData[collactionkey].map((collaction: any) => {
        const fieldKeys = Object.keys(collaction.dataUnits)
        for (const fieldKey of fieldKeys) {
          if (Array.isArray(value[collactionkey])) {
            collaction.dataUnits[fieldKey].dataUnitValue = value[collactionkey][0][fieldKey]
          } else {
            collaction.dataUnits[fieldKey].dataUnitValue = value[collactionkey][fieldKey]
          }
        }
        return collaction
      })
      submitData = Object.assign(submitData, {[collactionkey]: collectionData})
    }

    const payload = {
      applicationId: appId,
      forms: submitData,
    }

    // createAccountSchemas(constitution, true)
    // const stepIndex = stepper.current?.getCurrentStepIndex()
    // setCurrentSchema(createAccountSchemas(constitution, true)[Number(stepIndex) - 1 || 0])
    // try {
    //   // setValidationSchema(createAccountSchemas(constitution, true))
    //
    //   formikRef.current?.validateForm().then(async () => {
    //     if (Object.keys(formikRef.current?.errors || {}).length === 0) {
    //       // If there are no errors, submit the form
    //       console.log(formikRef.current?.values, 'values')
    //     } else {
    //       // If there are errors, mark all fields as touched to display the validation errors
    //       console.log(formikRef.current?.errors)
    //
    //       const val: any = Object.keys(initValues).map((vals) => {
    //         Object.keys(initValues[vals]).reduce((acc: any, key: any) => {
    //           acc[key] = true
    //           return acc
    //         }, {})
    //         // formikRef.current?.setErrors()
    //       })
    //       formikRef.current?.setTouched(val)
    //     }
    //   })
    // } catch (errors) {
    //   console.log(errors)
    // }

    submitApplication(payload, appId)
      .then((res) => {
        // navigate('/summary');
        // actions.resetForm()
        const {forms} = res.data
        if (forms) {
          setApplicationData(forms)
          setIsSaved(false)
          const dataKey: any = Object.keys(forms)
          const formObject = dataKey.reduce((pre: any, curr: any) => {
            let data
            if (
              curr === 'buyerDetails' ||
              curr === valueForm ||
              curr === constitutionFields.find((p) => p === curr) ||
              curr === 'directorPromoterInfo' ||
              curr === 'soloProprietor' ||
              curr === 'privateLimited' ||
              curr === 'LLP' ||
              curr === 'shareholder' ||
              curr === 'sikDetails' ||
              curr === 'authorizeSignatory' ||
              curr === 'partnership'
            ) {
              data = forms[curr].map((d: any) => {
                const key: any = Object.keys(d.dataUnits)
                return key.reduce(
                  (childPrev: any, childCurr: any) => ({
                    ...childPrev,
                    [childCurr]: d.dataUnits[childCurr].dataUnitError,
                  }),
                  {}
                )
              })
            } else {
              const {dataUnits} = forms[curr][0]
              const key: any = Object.keys(dataUnits)
              data = key.reduce(
                (childPrev: any, childCurr: any) => ({
                  ...childPrev,
                  [childCurr]: dataUnits[childCurr].dataUnitError,
                }),
                {}
              )
            }
            return {...pre, [curr]: data}
          }, {})
          if (
            Object.values(formObject)
              .flatMap((p) => p)
              .map((p: any) => Object.values(p))
              .flatMap((k) => k)
              .find((p) => p !== null)
          )
            toastError('Submit Error Form Incomplete')
          else {
            toastSuccess('You Have Submitted Application Successfully')
            getForms()
          }
        }
      })
      .catch((err) => toastError(err.response?.data?.error || err.response?.data))
      .finally(() => setSubmitLoading(false))
  }

  const changeStepHandler = (number: number, schemaValue: string = '') => {
    stepper.current?.goto(number)
    const schemaIndex = createAccountSchemas(initialConstitution, false).findIndex(
      (schema: any) => schema._nodes[0] === schemaValue
    )
    const isShareholderExist =
      schemaIndex >= 0 ? null : stepperData.find((p) => p.stepperTitle === 'Shareholder Details')
    if (schemaIndex >= 0) {
      setCurrentSchema(createAccountSchemas(initialConstitution, false)[schemaIndex])
    } else if (number > 4 && !isShareholderExist) {
      setCurrentSchema(createAccountSchemas(initialConstitution, false)[number])
    } else {
      setCurrentSchema(createAccountSchemas(initialConstitution, false)[number - 1])
    }
  }

  const gotoAnotherStep = (number: number = 0, schemaValue: string = '') => {
    const stepToGo = stepperData[number - 1]
    if (stepToGo && stepToGo.stepperTitle === 'Document' && !isDocumentTabShow) {
      toastError('Please complete previous steps. If completed your application is in review. ')
      return
    }

    if (stepToGo && stepToGo.stepperTitle === 'Template' && !isTemplateTabShow) {
      toastError('Please complete previous steps. If completed your application is in review. ')
      return
    }
    changeStepHandler(number, schemaValue)
  }

  const saveApplicationHandler = (values: any, setFieldError: any) => {
    if (!stepper.current) return
    const collectionName = stepperData[stepper.current.currentStepIndex - 1].value
    const value = values[collectionName]
    let collection = applicationData[collectionName]
    if (collectionName === 'sikDetails' && value[0].approvalOrManuaDiscount === 'jointRights') {
      value.forEach((element: any, i: any) => {
        const key = Object.keys(element)
        for (const sikField of key) {
          if (
            sikField !== 'userId' &&
            sikField !== 'title' &&
            sikField !== 'firstName' &&
            sikField !== 'middleName' &&
            sikField !== 'lastName' &&
            sikField !== 'shortName' &&
            sikField !== 'address' &&
            sikField !== 'state1' &&
            sikField !== 'postalCode1' &&
            sikField !== 'country1' &&
            sikField !== 'telephone1' &&
            sikField !== 'city1'
          ) {
            value[i][sikField] = value[0][sikField]
          }
        }
      })
    }
    if (collectionName === 'sikDetails' && value[0].approvalOrManuaDiscount === 'singleRights') {
      const [first, ...rest] = collection
      rest.forEach((c: any) => {
        const deleteSik: any = {
          applicationId: appId,
          forms: {
            sikDetails: [
              {
                ...c,
              },
            ],
          },
        }
        if (c.collectionId) {
          deleteForm(deleteSik, c.collectionId, appId)
        }
      })
    }
    const data = collection?.map((collection: any, index: number) => {
      const values = Array.isArray(value) ? value[index] : value
      const dataUnits = formatDataForAPI(collection.dataUnits, values)
      return {dataUnits, collectionId: collection.collectionId}
    })
    
    if (collectionName === 'sikDetails' && value[0]?.approvalOrManuaDiscount === 'jointRights'){
      data?.forEach((object:any,index:number)=>{
        set(data[index], "dataUnits.address.dataUnitValue",  value[0]?.address2)
      })
    }   
    

    const payload = {
      applicationId: appId,
      forms: {
        [collectionName]: data,
      },
    }

    setLoading(true)
    saveApplication(payload, collectionName, appId)
      .then((res) => {
        setLoading(false)
        const {dataUnits, collectionId} = res.data.forms[collectionName][0]
        const keys = Object.keys(dataUnits)
        keys.forEach((key: any) => {
          if (dataUnits[key]?.dataUnitError) {
            setFieldError(`${collectionName}.${key}`, dataUnits[key].dataUnitError)
          }
        })
        if (collectionName !== 'businessInfo') setIsSaved(true)
        toastSuccess('You Have Saved Application Successfully')
      })
      .then(() => {
        if (collectionName === 'businessInfo') {
          const isConstitution = constitutionList.find((p) => p.constitutionName === constitution)
          if (isConstitution)
            setConstitutionValue(appId, isConstitution.constitutionCode).then(() =>
              setIsSaved(true)
            )
          else setIsSaved(true)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toastError(err.response?.data?.error || err.response?.data)
      })
  }

  const formatDataForAPI = (dataUnits: any, values: any) => {
    const fields = Object.keys(dataUnits)
    for (const field of fields) {
      if (values) {
        const dataUnit = dataUnits[field]
        const fieldValue = values[field]
        dataUnit.dataUnitValue = fieldValue
      }
    }
    return dataUnits
  }

  const gotoNextStep = () => {
    if (!stepper.current) {
      return
    }
    const stepToGo = stepperData[stepper.current.currentStepIndex]
    if (stepToGo && stepToGo.stepperTitle === 'Document' && !isDocumentTabShow) {
      toastError('Please complete previous steps. If completed your application is in review. ')
      return
    }

    if (stepToGo && stepToGo.stepperTitle === 'Template' && !isTemplateTabShow) {
      toastError('Please complete previous steps. If completed your application is in review. ')
      return
    }
    stepper.current && stepper.current.goNext()
    const isShareholderExist = stepperData.find((p) => p.stepperTitle === 'Shareholder Details')
    if (stepper.current.currentStepIndex > 4 && !isShareholderExist) {
      setCurrentSchema(
        createAccountSchemas(initialConstitution, false)[stepper.current.currentStepIndex]
      )
    } else {
      setCurrentSchema(
        createAccountSchemas(initialConstitution, false)[stepper.current.currentStepIndex - 1]
      )
    }
  }

  const cancelModalHandler = () => {
    formikRef?.current?.resetForm()
    changeStepHandler(stepIndex)
  }

  const saveChangesHandler = async () => {
    await formikRef?.current?.submitForm()
    changeStepHandler(stepIndex)
  }
  return (
    <>
      <WarningPopup cancelModal={cancelModalHandler} saveApplication={saveChangesHandler} />
      <div className='d-flex rounded-1 justify-content-end p-3 mt-5'>
        <button
          type='button'
          disabled={!applicationSubmitted}
          className='btn btn-primary'
          onClick={() => submitFormHandler(applicationData)}
        >
          {submitLoading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          ) : (
            'Submit'
          )}
        </button>
      </div>
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid mt-5 '
        id='kt_create_account_stepper'
      >
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            <div className='stepper-nav'>
              {stepperData.map((list, index) => {
                return (
                  <div
                    key={index}
                    className={`stepper-item ${index === 0 && 'current'}`}
                    data-kt-stepper-element='nav'
                  >
                    <div
                      className='stepper-wrapper pointer'
                      onClick={() => gotoAnotherStep(index + 1, list.value)}
                    >
                      <div className='stepper-icon w-45px h-45px'>
                        {list.value &&
                        applicationSummary &&
                        applicationSummary[list.value] === 100 ? (
                          <i
                            className={`${
                              index + 1 === stepper.current?.currentStepIndex
                                ? 'text-white'
                                : 'text-primary'
                            } fas fa-check`}
                          ></i>
                        ) : (
                          applicationSummary &&
                          list?.value && (
                            <span className='stepper-number d-block'>
                              {applicationSummary[list.value]} %
                            </span>
                          )
                        )}
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>{list.stepperTitle}</h3>
                        <div className='stepper-desc fw-semibold'>{list.stepperDesc}</div>
                      </div>
                    </div>
                    {index !== stepperData.length - 1 && (
                      <div className='stepper-line h-40px'></div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className='d-flex p-2 flex-row-fluid bg-body rounded'>
          <Formik
            innerRef={formikRef}
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={(values, {setFieldError}) => saveApplicationHandler(values, setFieldError)}
            enableReinitialize
          >
            {({values, errors, setFieldValue, setFieldError, handleBlur}) => {
              setConstitution(values.businessInfo.constitution)
              // console.log(errors);
              return (
                <Form className='w-100' noValidate id='kt_create_account_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <BusinessInfo
                      value={values}
                      saveApplicationHandler={saveApplicationHandler}
                      setFieldError={setFieldError}
                      loading={loading}
                      setConstitutionList={setConstitutionList}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <BuyerDetails
                      value={values}
                      saveApplicationHandler={saveApplicationHandler}
                      setFieldError={setFieldError}
                      loading={loading}
                      appId={appId}
                      applicationData={applicationData}
                      setApplicationData={setApplicationData}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <CompanyAddressDetails
                      value={values}
                      setFieldValue={setFieldValue}
                      saveApplicationHandler={saveApplicationHandler}
                      // appId={appId}
                      setFieldError={setFieldError}
                      loading={loading}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <PromoterInfo
                      value={values}
                      saveApplicationHandler={saveApplicationHandler}
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                      loading={loading}
                      applicationData={applicationData}
                      setApplicationData={setApplicationData}
                      initialConstitution={initialConstitution}
                      appId={appId}
                    />
                  </div>
                  {(constitution === 'Private Limited' || constitution === 'Public Limited'|| constitution === 'LLP') && (
                    <div data-kt-stepper-element='content'>
                      {values?.shareholder && (
                        <Shareholder
                          value={values}
                          setValue={setFieldValue}
                          setIsSaved={setIsSaved}
                          saveApplicationHandler={saveApplicationHandler}
                          setFieldError={setFieldError}
                          loading={loading}
                          setFieldValue={setFieldValue}
                          appId={appId}
                          applicationData={applicationData}
                          setApplicationData={setApplicationData}
                        />
                      )}
                    </div>
                  )}
                  <div data-kt-stepper-element='content'>
                    <CoverLetterDetails
                      value={values}
                      saveApplicationHandler={saveApplicationHandler}
                      setFieldError={setFieldError}
                      loading={loading}
                      constitution={constitution}
                      initialConstitution={initialConstitution}
                      setValue={setFieldValue}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <SIKDetails
                      value={values}
                      setValue={setFieldValue}
                      saveApplicationHandler={saveApplicationHandler}
                      setFieldError={setFieldError}
                      loading={loading}
                      appId={appId}
                      applicationData={applicationData}
                      setApplicationData={setApplicationData}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <SupplierAgreementDetails
                      value={values}
                      setValue={setFieldValue}
                      saveApplicationHandler={saveApplicationHandler}
                      setFieldError={setFieldError}
                      loading={loading}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <EOI
                      value={values}
                      setValue={setFieldValue}
                      saveApplicationHandler={saveApplicationHandler}
                      setFieldError={setFieldError}
                      loading={loading}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <AuthorisedSignatory
                      value={values}
                      setValue={setFieldValue}
                      saveApplicationHandler={saveApplicationHandler}
                      setFieldError={setFieldError}
                      loading={loading}
                      setIsSaved={setIsSaved}
                      initialConstitution={initialConstitution}
                      setFieldValue={setFieldValue}
                      appId={appId}
                      applicationData={applicationData}
                      setApplicationData={setApplicationData}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <Document
                      initialConstitution={initialConstitution}
                      applicationCode={appId}
                      setIsSaved={setIsSaved}
                      value={values}
                      application={applicationData}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      setFieldError={setFieldError}
                      errors={errors}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <Template
                      initialConstitution={initialConstitution}
                      applicationCode={appId}
                      setIsSaved={setIsSaved}
                      value={values}
                      application={applicationData}
                      setFieldValue={setFieldValue}
                      applicationSubmitted={applicationSubmitted}
                      handleBlur={handleBlur}
                      setFieldError={setFieldError}
                      errors={errors}
                    />
                  </div>

                  {/* <div data-kt-stepper-element='content'>
                  <ConfirmationLetter value={values} setValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <ListOfOtherDocuments />
                </div> */}
                  {/* <div data-kt-stepper-element='content'>
                <MoreThanShareholder />
              </div> */}

                  <div className='d-flex flex-stack pt-10'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    </div>

                    {stepper.current && stepper.current.currentStepIndex !== stepperData.length && (
                      <div>
                        <button
                          type='button'
                          className='btn btn-lg btn-primary me-3'
                          onClick={gotoNextStep}
                        >
                          <span className='indicator-label'>
                            {!isSubmitButton && 'Continue'}
                            {isSubmitButton && 'Submit'}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}
