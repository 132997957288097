export const URLS = {
  GET_STATUS: "/api/master/operation",
  CONSTITUTION: "/api/master/constitution",
  SECTOR: "/api/master/sector",
  BUYER: "/api/master/buyer",
  CHECK_GSTIN: "/api/scymes/checkgstin",
  CHECK_PAN: "/api/scymes/checkpan",
  DESIGNATION: "/api/master/designation",
  COUNTRY: "/api/master/country",
  ADDRESS_PROOF_DOCUMENT: "/api/master/document",
  APPLICATION: "/api/scymes/application",
  SAVE_APPLICATION: "/api/scymes/application",
  SUBMIT_APPLICATION: "/api/scymes/application/submit",
  POSTLOGIN: "/api/auth/signin",
  POSTSENDOTP: "/api/supplier/sentOtp",
  APPLICATION_SUMMARY: "/api/scymes/applicationSummary",
  SET_CONSTITUTION: "/api/scymes/oncontitution",
  UPLOAD_DOCUMENT: "/api/scymes/upload",
  FORGET_PASSWORD: "/api/rm/forgetPassword",
  RESET_PASSWORD: "/api/rm/resetPassword",
  DELETE_FORM: "/api/scymes/delete",
  DOCUMENT_COMMENT: "/api/scymes/documentComment",
  GET_ASSIGNEE: "/api/rm/listofassignee",
  GET_SELECTED_ASSIGNEE: "/api/supplier/selectedassignee",
  UPDATE_ASSIGNEE: "/api/supplier/assigneeupdate",
  GETPINCODE: "/api/scymes/getpincode",
  GETPAN: "/api/scymes/checkpan",

  //Admin
  ADMIN_LOGIN: "/api/auth/signin",
  ADMIN_APPLICATION_SUMMARY: "/api/scymes/rmApplicationSummary",
  CREATE_SUPPLIER: "/api/supplier/create",
  STATUS_AND_COMMENTS: "/api/scymes/workflowcomment",
  OTHER_DOCUMENT: "/api/master/otherdocument",
  ADD_DOCUMENT: "/api/scymes/addotherdoc",
  GET_UPDATE_STATUS: "/api/master/status",
  GET_REPORT: "/api/mis/getReport",

  // AUTH
  AUTH_REFRESH_TOKEN: "/api/auth/refreshtoken",
};
