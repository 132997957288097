import { KTSVG } from "../../helpers";

export default function WarningPopup(props:any) {
    return (
        <>
            <button type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_warning"
                id="open_warning_popup"
            >
               open Modal
            </button>
            <div className="modal fade" tabIndex={-1} id="kt_modal_warning">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Warning Popup</h5>
                            <div onClick={() => props.cancelModal()}
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>You have Changed Constitution and You Are Leaving Without Save Application Please Save Changes.</p>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => props.cancelModal()}
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" data-bs-dismiss="modal" className="btn btn-primary" onClick={() => props.saveApplication()}>
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
