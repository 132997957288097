import { ErrorMessage, Field } from "formik";
import { FC, useEffect, useState } from "react";
import { Dropdowns } from "../../../../_metronic/layout/components/Dropdowns";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import { getCountry } from "../../../API/api";
import { ICreateAccount } from "../../../modules/wizards/components/CreateAccountWizardHelper";
import { toastError } from "./../../../../_metronic/helpers/ToastifyHelper";
import { defaultSelectedCountry } from "../../../constants/DropdownConstants";

interface props {
  value: ICreateAccount;
  setFieldValue: any;
  saveApplicationHandler: any;
  setFieldError: any;
  loading: boolean;
}
const CompanyAddressDetails: FC<props> = ({
  value,
  setFieldValue,
  saveApplicationHandler,
  setFieldError,
  loading,
}: props) => {
  const [countryData, setCountryData] = useState<any[]>([]);
  const setCountryToIndia = () => {
    setFieldValue("companyAddressDetails.countryRD", "India");
    setFieldValue("companyAddressDetails.country", "India");
  };
  useEffect(() => {
    if (
      (value.companyAddressDetails.address,
      value.companyAddressDetails.pinCode,
      value.companyAddressDetails.city,
      value.companyAddressDetails.telephoneNo,
      value.companyAddressDetails.mobileNo,
      value.companyAddressDetails.state,
      value.companyAddressDetails.country)
    ) {
      if (value.companyAddressDetails.registeredAddress === "YES") {
        setFieldValue(
          "companyAddressDetails.addressRD",
          value.companyAddressDetails.address
        );
        setFieldValue(
          "companyAddressDetails.pinCodeRD",
          value.companyAddressDetails.pinCode
        );
        setFieldValue(
          "companyAddressDetails.cityRD",
          value.companyAddressDetails.city
        );
        setFieldValue(
          "companyAddressDetails.telephoneNoRD",
          value.companyAddressDetails.telephoneNo
        );
        setFieldValue(
          "companyAddressDetails.mobileNoRD",
          value.companyAddressDetails.mobileNo
        );
        setFieldValue(
          "companyAddressDetails.stateRD",
          value.companyAddressDetails.state
        );
        setFieldValue(
          "companyAddressDetails.countryRD",
          value.companyAddressDetails.country
        );
      } else {
        setFieldValue("companyAddressDetails.addressRD", "");
        setFieldValue("companyAddressDetails.pinCodeRD", "");
        setFieldValue("companyAddressDetails.cityRD", "");
        setFieldValue("companyAddressDetails.telephoneNoRD", "");
        setFieldValue("companyAddressDetails.mobileNoRD", "");
        setFieldValue("companyAddressDetails.stateRD", "");
        // setFieldValue('companyAddressDetails.countryRD', '')
      }
    }
    if (value?.companyAddressDetails?.registeredAddress === "NO") {
      setFieldValue("companyAddressDetails.addressProof", "YES");
    }
  }, [
    value.companyAddressDetails.registeredAddress,
    value.companyAddressDetails.address,
    value.companyAddressDetails.pinCode,
    value.companyAddressDetails.city,
    value.companyAddressDetails.telephoneNo,
    value.companyAddressDetails.mobileNo,
    value.companyAddressDetails.state,
    value.companyAddressDetails.country,
  ]);

  useEffect(() => {
    if (
      countryData?.length > 0 &&
      value?.companyAddressDetails?.address != "" &&
      value?.companyAddressDetails?.countryRD == "" &&
      value?.companyAddressDetails?.country == ""
    ) {
      setCountryToIndia();
    }
  }, [countryData, value?.companyAddressDetails?.address]);

  useEffect(() => {
    getCountry()
      .then((res) => {
        let dataArray = res?.data;
        // console.log("dataArray",dataArray)
        let indexOfIndia: any = null;
        dataArray?.forEach((country: any, index: number) => {
          if (country?.countryName == "India") {
            indexOfIndia = index;
          }
        });
        dataArray?.splice(indexOfIndia, 1);
        setCountryData(res.data);
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  }, []);
  return (
    <div className="card p-5 w-100">
      <h3 className="border-bottom p-2">Company Address Detail</h3>
      <div className="row g-3 mb-5">
        <div>
          <label className="form-label required">Address</label>
          <Field
            as="textarea"
            name="companyAddressDetails.address"
            className="form-control form-control-lg form-control-solid"
            rows={3}
          />
          <div className="text-danger m-2">
            <ErrorMessage name="companyAddressDetails.address" />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="Pincode"
            type="number"
            placeHolder="Enter Pincode"
            name="companyAddressDetails.pinCode"
          />
          {/* <div className="col-auto">
            <button
              type="button"
              className="mt-1 btn btn-primary me-3"
              // onClick={() => deletePromoter(d, i)}
            >
              Validate
            </button>
          </div> */}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="City"
            type="text"
            placeHolder="Enter city Name"
            name="companyAddressDetails.city"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="Telephone No"
            type="number"
            placeHolder="Enter Telephone No"
            name="companyAddressDetails.telephoneNo"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="Mobile No"
            type="number"
            placeHolder="Enter Mobile No"
            name="companyAddressDetails.mobileNo"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="State"
            type="text"
            placeHolder="Enter State Name"
            name="companyAddressDetails.state"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            defaultValue={defaultSelectedCountry}
            required={true}
            label="Country"
            name="companyAddressDetails.country"
            options={countryData.map((c) => c.countryName)}
          />
        </div>
      </div>
      <h4 className="border-bottom p-2 mt-5">Correspondence Address</h4>
      {/* <div className='d-flex'>
        <p className='m-0 mt-2 me-2'>Same As Registered Address</p>
        <Field
          type='checkbox'
          name='toggle'
          className='mt-2'
          value={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          checked={isChecked}
        />
      </div> */}
      <div>
        <Dropdowns
          label="Same as Registered Address"
          name="companyAddressDetails.registeredAddress"
          options={["YES", "NO"]}
        />
        <Dropdowns
          required={
            value?.companyAddressDetails?.registeredAddress !== "YES" && true
          }
          label="Address Proof Submitted"
          name="companyAddressDetails.addressProof"
          options={["YES", "NO"]}
        />
      </div>
      <div className="row g-3 mb-5">
        <div>
          <label className="form-label required">Address</label>
          <Field
            as="textarea"
            className="form-control form-control-lg form-control-solid"
            rows={3}
            name="companyAddressDetails.addressRD"
            disabled={
              value.companyAddressDetails.registeredAddress === "YES" && true
            }
          />
          <div className="text-danger m-2">
            <ErrorMessage name="companyAddressDetails.addressRD" />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            disabled={
              value.companyAddressDetails.registeredAddress === "YES" && true
            }
            required={true}
            label="Pincode"
            type="number"
            placeHolder="Enter Pincode"
            name="companyAddressDetails.pinCodeRD"
          />
          {/* <div className="col-auto">
            <button
              type="button"
              className="mt-1 btn btn-primary me-3"
              // onClick={() => deletePromoter(d, i)}
            >
              Validate
            </button>
          </div> */}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            disabled={
              value.companyAddressDetails.registeredAddress === "YES" && true
            }
            required={true}
            label="City"
            type="text"
            placeHolder="Enter city Name"
            name="companyAddressDetails.cityRD"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            disabled={
              value.companyAddressDetails.registeredAddress === "YES" && true
            }
            required={true}
            label="Telephone No"
            type="number"
            placeHolder="Enter Telephone No"
            name="companyAddressDetails.telephoneNoRD"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            disabled={
              value.companyAddressDetails.registeredAddress === "YES" && true
            }
            required={true}
            label="Mobile No"
            type="number"
            placeHolder="Enter Mobile No"
            name="companyAddressDetails.mobileNoRD"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            disabled={
              value.companyAddressDetails.registeredAddress === "YES" && true
            }
            required={true}
            label="State"
            type="text"
            placeHolder="Enter State Name"
            name="companyAddressDetails.stateRD"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            defaultValue={defaultSelectedCountry}
            disabled={
              value.companyAddressDetails.registeredAddress === "YES" && true
            }
            required={true}
            label="Country"
            name="companyAddressDetails.countryRD"
            options={countryData.map((c) => c.countryName)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-auto">
          <button
            type="submit"
            className="mt-5 btn btn-primary me-4"
            onClick={() =>
              saveApplicationHandler(
                value.companyAddressDetails,
                "companyAddressDetails",
                setFieldError
              )
            }
          >
            {loading ? (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyAddressDetails;
