import React, { ChangeEvent, useState } from "react";
import "../../../../_metronic/assets/sass/popup.css";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import { saveApplication, uploadDocument } from "../../../API/api";
import { toastError, toastSuccess } from "../../../../_metronic/helpers/ToastifyHelper";
import CommentModel from "../../../../_metronic/layout/components/CommentModal";
interface PopupCardProps {
  onClose: () => void;
  applicationData: any;
  setFieldValue: any;
  value: any;
  appId: any;
  setApplicationData:any;
  setIsSaved: any;
}

const PopupCard: React.FC<PopupCardProps> = ({
  onClose,
  applicationData,
  setApplicationData,
  value,
  setFieldValue,
  setIsSaved,appId
}) => {
  const [inputValue, setInputValue] = useState('');
  const [commentList, setCommentList] = useState<any[]>([]);
  const [loadingDocument, setLoadingDocument] = useState("");
  const [toggleDocumentComment, setToggleDocumentComment] = useState(null);
  const initialConstitution: string = "";
  const valueForm =
    initialConstitution === "Sole Proprietor"
      ? " ShedularDoc "
      : initialConstitution === "Private Limited"
      ? "ShedularDoc"
      : initialConstitution === "Public Limited"
      ? "ShedularDoc"
      : initialConstitution === "Partnership"
      ? "ShedularDoc "
      : "ShedularDoc";
  const form = applicationData[valueForm];
 
  const [showTextField, setShowTextField] = useState(false);

  const handleAddField = () => {
    setShowTextField(true);
  };

  const handleSave = () => {
    // setShowTextField(false); 
    const currentData = { ...applicationData[valueForm][0] };
    const currentDataCopy = JSON.parse(JSON.stringify(currentData));
    const collectionId =applicationData[valueForm][0]?.collectionId;
  
    const capitalizedValue = inputValue
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join(' ');

    
    
    const inputName = inputValue;
    const updatedJSON = {
      
      dataUnits: {
        ...currentData.dataUnits,
        [inputName]: {
          coreValidationMatrixList:[],
          dataUnitAttribute: null,
          dataUnitCode: "",
          dataUnitName: inputValue, 
          dataUnitError: null,
          dataUnitLabel: capitalizedValue,
          dataUnitType: "file",
          documentComments: null,
          dataUnitValue: "",
          // Add other properties if needed
        },
      },
    };

      
    const collectionName = "ShedularDoc";

    const payload = {
      applicationId: appId,
      forms: {
        [collectionName]: [updatedJSON],
      },
    }

   
    saveApplication(payload, collectionName, appId)
      .then((res) => {
      
        // const {dataUnits, collectionId} = res.data.forms[collectionName][0]
        // const keys = Object.keys(dataUnits)
        // console.log(keys,"kyes")
        // keys.forEach((key: any) => {
        //   if (dataUnits[key]?.dataUnitError) {
        //     // setFieldError(`${collectionName}.${key}`, dataUnits[key].dataUnitError)
        //   }
        // })
        toastSuccess('You Have Saved Application Successfully')
      }).catch((err) => {
       
       
        toastError(err.response?.data?.error || err.response?.data)
      })
   
  
  };
  

  const onFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    if (event.target.files && event.target.files[0]) {
      const fileSize = 1024 * 5;
      const fileSizeKiloBytes = event.target.files[0].size / 1024;
      if (fileSizeKiloBytes <= fileSize) {
        setFieldValue(`${valueForm}.${fieldName}`, event.target?.files[0]);
      } else {
        // setFieldError(
        //   `${valueForm}.${fieldName}`,
        //   "File size must be less then or equal 5MB"
        // );
      }
    }
    if (value?.companyAddressDetails?.addressProof === "YES") {
      // setFieldError(
      //   `${valueForm}.mailingAddressProof`,
      //   "Mailing address proof is a required field"
      // );
    }
  };

  


 
  const applicationCode=appId
  const uploadDocumentHandler = (documentName: string) => {
    // if (!value[valueForm][documentName]) return;
    setLoadingDocument(documentName);
   
    const payload = {
      collectionName: valueForm,
      applicationCode,
      documentCode: form[0].dataUnits[documentName].dataUnitCode,
      documentName,
      fileName: value[valueForm][documentName].name
    };

    // setFieldValue(`${valueForm}.${form[0].dataUnits[documentName].dataUnitCode}`);
    // console.log(value, "value");
    const formData = new FormData();
    formData.append("contentData", value[valueForm][documentName]);
    formData.append("contentMetaData", JSON.stringify(payload));
    uploadDocument(formData)
      .then((res: any) => {
       
        setLoadingDocument("");
        setIsSaved(true);
        toastSuccess("You have uploaded the document successfully");
      })
      .catch((err) => {
        setLoadingDocument("");
        toastError(err.response?.data?.error || err.response?.data);
      });
  };
  return (
    <div className="popup-card-container">
      <div className="card p-5 w-100">
        <div className="popup-card">
          {applicationData[valueForm][0] &&
            value[valueForm] &&
            Object.values(applicationData[valueForm][0].dataUnits).map(
              (dataUnit: any, index) => {
                
                return (
                  <div key={index} className="mb-10">
                    <label>{dataUnit.dataUnitLabel}</label>
                    <div>
                      <input
                        onChange={(e: any) =>
                          onFileChange(e, dataUnit.dataUnitName)
                        }
                        className="form-control"
                        type="file"
                        accept="application/pdf"
                        name={`${valueForm}.${dataUnit.dataUnitName}`}
                      />
                      {dataUnit.dataUnitValue ? (
                        <p className="mt-2 mb-0 document-name-ellipse document-name-max-height">{`Uploaded - ${
                          dataUnit.dataUnitValue.split("_")[
                            dataUnit.dataUnitValue.split("_").length - 1
                          ]
                        }`}</p>
                      ) : null}
                    </div>
                    <br />
                    <div className="col-lg-4 col-md-6 col-xl-6 col-sm-6 d-flex align-items-center">
                      {dataUnit.dataUnitValue &&
                        value[valueForm][dataUnit.dataUnitName] ===
                          dataUnit.dataUnitValue && (
                          <a
                            href={dataUnit.dataUnitValue}
                            download
                            type="button"
                            className="btn btn-primary btn-sm m-1 me-3"
                          >
                            Download
                          </a>
                        )}
                      <button
                        type="button"
                        className="btn btn-primary btn-sm m-1"
                        onClick={() => uploadDocumentHandler(dataUnit.dataUnitName)}
                      >
                        {loadingDocument && loadingDocument === dataUnit.dataUnitName ? (
                          <span className="indicator-progress d-block">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        ) : (
                          "Upload"
                        )}
                      </button>
                      <div className="col-lg-4 col-md-6 col-xl-6 col-sm-6 d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-warning btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_1"
                          onClick={() => setToggleDocumentComment(dataUnit)}
                        >
                          View And Add Comments
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
         {toggleDocumentComment && (
        <CommentModel
          commentList={commentList}
          setCommentList={setCommentList}
          appId={applicationCode}
          valueForm={valueForm}
          dataUnit={toggleDocumentComment}
        />
      )}
          {showTextField ? (
            <div className="mb-10">
              <input
                type="text"
                className="form-control"
                placeholder="Enter text..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <br />
              <button type="button" className="btn btn-primary btn-sm" onClick={handleSave}>
                Save
              </button>
            </div>
          ) : (
            <div className="mb-10">
              <button
                type="button"
                className="btn btn-primary btn-sm "
                onClick={handleAddField}
              >
                ADD
              </button>
            </div>
          )}
        </div>
        <button className="close-icon" onClick={onClose}>
          X
        </button>
      </div>
    </div>
  );
};

export default PopupCard;
