import { stepperList } from '../../../../_metronic/helpers/ArrayHelper'

export const constitutionFields = [
  'soloProprietor',
  'privateLimited',
  'publicLimited',
  'partnership',
  'LLP',
  'HUF',
]

export const summaryValues = [
  'soloProprietorDoc',
  'partnershipDeepDoc',
  'privateLimitedDoc',
  'LLPDoc',
  'coverLetterForSoloProprietor',
  'coverLetterForPartnership',
  'coverLetterPrivateAndPublic',
  'coverLetterForLlp',
  'templateSole',
  'templatePartnership',
  'templatePrivateandpublic',
  'templateLlp',
]

export const getStepperData = (constitution: string, application: any): any[] => {
  let stepperData = stepperList
  if (constitution === 'Private Limited' || constitution === 'Public Limited' || constitution === 'LLP') {
  } else {
    stepperData = stepperList
      .filter((p) => p.stepperTitle !== 'Shareholder Details')
  }
  return stepperData.map(data => {
    switch (data.value) {
      case 'directorPromoterInfo':
        return {
          ...data,
          value: Object.keys(application).find((a: any) => constitutionFields.includes(a)),
        }
      case 'coverLetterDetails':
        return {
          ...data,
          value: Object.keys(application).find((a: any) =>
            summaryValues.filter((d) => d.toLowerCase().includes('cover')).includes(a),
          ),
        }
      case 'documentInfo':
        return {
          ...data,
          value: Object.keys(application).find((a: any) =>
            summaryValues.filter((d) => d.toLowerCase().includes('doc')).includes(a),
          ),
        }
      case 'template':
        return {
          ...data,
          value: Object.keys(application).find((a: any) =>
            summaryValues.filter((d) => d.toLowerCase().includes('template')).includes(a),
          ),
        }
      default:
        return data
    }
  });
}
