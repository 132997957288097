import {useEffect, useState} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {toastError} from '../../_metronic/helpers/ToastifyHelper'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {getApplicationSummaryWithAppId} from '../API/api'
import ResetPassword from '../modules/auth/components/ResetPassword'
import SupplierSummary from '../pages/Admin/SupplierSummary/SupplierSummary'
import SupplierRegistration from './../pages/Admin/SupplierRegistration/SupplierRegistration'
import Application from './../pages/Application/Application'
import MisReport from '../pages/Admin/Mis-Report/MisReport'

const AdminRoutes = (props: any) => {
  const [applicationSummary, setApplicationSummary] = useState<any>(null)
  const location: any = useLocation()
  const appId: any = location.state?.applicationId
  const [isDocumentTabShow, setIsDocumentTabShow] = useState<boolean>()
  const [isTemplateTabShow, setIsTemplateTabShow] = useState<boolean>()
  const [isApplicationSubmitted, setApplicationSubmitted] = useState<boolean>()

  useEffect(() => {
    getSummaryHandler()
  }, [location?.state])

  useEffect(() => {
    if (props.isSaved) {
      getSummaryHandler()
    }
  }, [props.isSaved])

  const getSummaryHandler = () => {
    if (appId)
      getApplicationSummaryWithAppId(appId)
        .then(({data}) => {
          setApplicationSummary(data.data.applicationSummary)
          setIsDocumentTabShow(data.data.isDocumentTabShow)
          setIsTemplateTabShow(data.data.isTemplateTabShow)
          setApplicationSubmitted(data.data.applicationSubmitted)
        })
        .catch((err) => toastError(err.response?.data?.error || err.response?.data))
  }
  return (
    <Routes>
      <Route element={<MasterLayout setLoginUser={props.setLoginUser} />}>
        <Route path='summary' element={<SupplierSummary />} />
        <Route path='supplier-registration' element={<SupplierRegistration />} />
        <Route path= 'Mis-Report' element={<MisReport />} />
        <Route
          path='application'
          element={
            <Application
              isSaved={props.isSaved}
              setIsSaved={props.setIsSaved}
              isDocumentTabShow={isDocumentTabShow}
              isTemplateTabShow={isTemplateTabShow}
              applicationSubmitted={isApplicationSubmitted}
              applicationId={''}
              applicationSummary={applicationSummary}
            />
          }
        />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {AdminRoutes}
