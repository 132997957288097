import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
// import {getUserByToken, login} from '../core/_requests'
// import {useAuth} from '../core/Auth'
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosDefaultHeader, postLogin } from "../../../API/api";
import { toastError } from "./../../../../_metronic/helpers/ToastifyHelper";

const loginSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required"),
});

const initialValues = {
  otp: "",
};

interface Props {
  setLoginUser: any;
}

export default function LoginOtp({ setLoginUser }: Props): JSX.Element {
  const [loading, setLoading] = useState(false);
  // const {saveAuth, setCurrentUser} = useAuth()
  const location: any = useLocation();

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      postLogin({ username: location.state, password: values.otp })
        .then((res) => {
          console.log(res, "res");
          setLoading(false);
          axiosDefaultHeader(res.data.accessToken);
          localStorage.setItem("user", JSON.stringify(res.data));
          navigate("/summary");
          setLoginUser(res.data);
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = err.response?.data.split(":");
          toastError(errorMessage[2] || err.response?.data?.error);
        });
      // try {
      //   const {data: auth} = await login(location.state, values.otp)
      //   saveAuth(auth)
      //   const {data: user} = await getUserByToken(auth.api_token)
      //   setCurrentUser(user)
      // } catch (error) {
      //   console.error(error)
      //   // saveAuth(undefined)
      //   setStatus('incorrect OTP')
      //   setSubmitting(false)
      //   setLoading(false)
      // }
    },
  });
  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Verify OTP</h1>
      </div>
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bold text-dark">Enter OTP</label>
        <input
          placeholder="Enter OTP"
          {...formik.getFieldProps("otp")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.otp && formik.errors.otp },
            {
              "is-valid": formik.touched.otp && !formik.errors.otp,
            }
          )}
          type="text"
          name="otp"
          autoComplete="off"
        />
        {formik.touched.otp && formik.errors.otp && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.otp}</span>
          </div>
        )}
      </div>
      <button
        type="submit"
        id="kt_sign_in_submit"
        className="btn btn-lg btn-primary w-100 mb-5"
        disabled={formik.isSubmitting || !formik.isValid}
      >
        {!loading && <span className="indicator-label">Sign In</span>}
        {loading && (
          <span className="indicator-progress" style={{ display: "block" }}>
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
    </form>
  );
}
