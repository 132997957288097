import { FC } from 'react'
import Select from 'react-select';

import "./styles.css";
interface DropdownModel {
  label?: string
  name?: any
  options?: any[]
  required?: boolean
  onChange?:any
  className?:string
  error?:any
  value?:any
}

export const MultiDropdown: FC<DropdownModel> = ({ label, name, options,value, required,onChange,error}) => {
  return (
    <div>
      {label && <label className={`col-sm-3 col-form-label text-nowrap ${required && 'required'}`}>
        {label}
      </label>}
      <Select
        className='multi-select'
        value={value}
        defaultValue={[]}
        onChange={onChange}
        name={name}
        options={options}
        isMulti={true}
      />
     {error &&
      <div className='text-danger m-2'>
        <p>Buyer Name is a required field</p>
      </div> 
      }
    </div>
  )
}
