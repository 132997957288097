import { useEffect, useRef, useState } from "react";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import Checkbox from "../../../../_metronic/layout/components/Checkbox";
import RadioButton from "../../../../_metronic/layout/components/RadioButton";
import {
  deleteForm,
  getApplication,
  getCountry,
  getpinApi,
} from "../../../API/api";
import { Dropdowns } from "../../../../_metronic/layout/components/Dropdowns";
import { toastError, toastSuccess } from "./../../../../_metronic/helpers/ToastifyHelper";
import { ErrorMessage, Field, FieldArray } from "formik";
import { defaultSelectedCountry } from "../../../constants/DropdownConstants";
import { set } from "lodash";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { stepperList } from "../../../../_metronic/helpers/ArrayHelper";

interface Props {
  value: any;
  setValue: any;
  saveApplicationHandler: any;
  setFieldError: any;
  loading: boolean;
  appId: string;
  applicationData: any;
  setApplicationData: any;
}

export default function SIKDetails({
  value,
  setValue,
  saveApplicationHandler,
  setFieldError,
  loading,
  appId,
  applicationData,
  setApplicationData,
}: Props): JSX.Element {
  const stepper = useRef<StepperComponent | null>(null);
  const [stepperData, setStepperData] = useState(stepperList);
  // const [updateDataId, setUpdateDataId] = useState(null)
  const [countryData, setCountryData] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const gotUser: any = JSON.parse(localStorage.getItem("user") || "");
  const isEditable: boolean = gotUser.roles.includes("RM");
  const [applicationDataPin, setApplicationDataPin] = useState<any>({});

  useEffect(() => {
    getCountry()
      .then((res) => {
        let dataArray = res?.data;
        // console.log("dataArray",dataArray)
        let indexOfIndia: any = null;
        dataArray?.forEach((country: any, index: number) => {
          if (country?.countryName == "India") {
            indexOfIndia = index;
          }
        });
        dataArray?.splice(indexOfIndia, 1);
        setCountryData(res.data);
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  }, []);

  const setCountryToIndia = () => {
    value?.sikDetails?.forEach((form: any, index: number) => {
      if (value?.sikDetails[index]?.country == "") {
        setValue(`sikDetails[${index}].country`, "India");
      }
      if (value?.sikDetails[index]?.country1 == "") {
        setValue(`sikDetails[${index}].country1`, "India");
      }
      if (value?.sikDetails[index]?.country2 == "") {
        setValue(`sikDetails[${index}].country2`, "India");
      }
    });
  };

  const getpincode = (currentIndex: any) => {
    const collectionName = "sikDetails";
    const value1 = [collectionName];
    const collectionId =
      applicationData?.sikDetails[currentIndex]?.collectionId;
    let collection = applicationData[collectionName];

    const data = collection?.map((collectionItem: any, index: any) => {
      const values = Array.isArray(value1) ? value1[index] : value1;
      const dataUnits = formatDataForAPI(collectionItem.dataUnits, values);
      const pinCode = value?.sikDetails[currentIndex].postalCode1;

      // Update specific entry in dataUnits object with user input
      if (dataUnits?.postalCode1) {
        // const dataUnits = formatDataForAPI(collectionItem.dataUnits?.postalCode1.dataUnitValue, pinCode);
        dataUnits.postalCode1.dataUnitValue = pinCode;
        // console.log(dataUnits.postalCode1.dataUnitValue)
      }

      return {
        dataUnits,
        collectionId: collectionItem.collectionId,
      };
    });

    const payload = {
      applicationId: appId,
      forms: {
        [collectionName]: data,
      },
    };

    getpinApi(
      payload,
      collectionName,
      value?.sikDetails[currentIndex].postalCode1,
      collectionId
    )
      .then((res) => {
        const { forms } = res.data;
        const form = JSON.parse(
          JSON.stringify(forms?.sikDetails[currentIndex])
        );

        setValue(
          `sikDetails[${currentIndex}].city1`,
          `${form?.dataUnits?.city1?.dataUnitValue}`
        );
        setValue(
          `sikDetails[${currentIndex}].state1`,
          `${form?.dataUnits?.state1?.dataUnitValue}`
        );
        toastSuccess("Pincode validated");
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  };

  const formatDataForAPI = (dataUnits: any, values: any) => {
    const fields = Object.keys(dataUnits);
    for (const field of fields) {
      if (values) {
        const dataUnit = dataUnits[field];
        const fieldValue = values[field];
        dataUnit.dataUnitValue = fieldValue;
      }
    }

    return dataUnits;
  };

  useEffect(() => {
    if (
      countryData?.length > 0 &&
      value?.sikDetails?.length > 0 &&
      value?.sikDetails[0]?.address
    ) {
      setCountryToIndia();
    }
  }, [countryData, value, applicationData, Inputs]);

  const renderManualDiscountRightsFields = (currentIndex: number) => {
    return (
      <>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="User ID (= email address)"
            type="email"
            placeHolder="Enter email"
            name={`sikDetails[${currentIndex}].userId`}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
          <RadioButton
            required={true}
            title="Title"
            value={[
              { value: "Mr", label: "Mr" },
              { value: "Ms", label: "Ms" },
              { value: "Dr", label: "Dr" },
            ]}
            name={`sikDetails[${currentIndex}].title`}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="First Name"
            type="text"
            placeHolder="Enter First Name"
            name={`sikDetails[${currentIndex}].firstName`}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            label="Middle Name"
            type="text"
            placeHolder="Enter Middle Name"
            name={`sikDetails[${currentIndex}].middleName`}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="Last Name"
            type="text"
            placeHolder="Enter Last Name"
            name={`sikDetails[${currentIndex}].lastName`}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="Short Name"
            type="text"
            placeHolder="Enter Short Name"
            name={`sikDetails[${currentIndex}].shortName`}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="Address"
            type="text"
            placeHolder="Enter Address"
            name={`sikDetails[${currentIndex}].address1`}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div>
            <label
              className={`col-sm-3 col-form-label text-nowrap ${
                true && "required"
              }`}
              htmlFor={`sikDetails[${currentIndex}].postalCode1`}
            >
              Postal Code
            </label>
            <div className="d-flex gap-5">
              <Field
                type="number"
                name={`sikDetails[${currentIndex}].postalCode1`}
                id={`sikDetails[${currentIndex}].postalCode1`}
                className="form-control form-control-lg form-control-solid"
                placeholder={"Enter Your PostalCode"}
              />
              <div className="col-auto">
                <button
                  type="button"
                  className="mt-1 btn btn-primary me-3"
                  onClick={() => {
                    getpincode(currentIndex);
                  }}
                >
                  Validate
                </button>
              </div>
            </div>

            <div className="text-danger m-2">
              <ErrorMessage name={`sikDetails[${currentIndex}].postalCode1`} />
            </div>
          </div>
          {/* <Inputs
            required={true}
            label="Postal Code"
            type="number"
            placeHolder="Enter Postal Code"
            name={`sikDetails[${currentIndex}].postalCode1`}
          /> */}
          {/* <div className="col-auto">
            <button
              type="button"
              className="mt-1 btn btn-primary me-3"
              
              onClick={(values) => {
                getpincode(values,currentIndex);
                getpincode(values);
              }}
            >
              Validate
            </button>
          </div> */}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          {/* <div>
            <label
              className={`col-sm-3 col-form-label text-nowrap ${
                true && "required"
              }`}
              htmlFor={`sikDetails[${currentIndex}].city1`}
            >
             City
            </label>
             <Field
                type="text"
                name={`sikDetails[${currentIndex}].city1`}
                id={`sikDetails[${currentIndex}].postalCode1`}
                className="form-control form-control-lg form-control-solid"
                placeholder={"Enter City"}
                // value={value?.sikDetails[currentIndex].city1}
              />
            </div> */}
          <Inputs
            required={true}
            label="City"
            type="text"
            placeHolder="Enter City"
            name={`sikDetails[${currentIndex}].city1`}
            // value={applicationDataPin}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="State"
            type="text"
            placeHolder="Enter State"
            name={`sikDetails[${currentIndex}].state1`}
          />
        </div>
        {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  label='Province'
                  type='text'
                  placeHolder='Enter Province'
                  name={`sikDetails[${currentIndex}].province1`}
                />
              </div> */}

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            defaultValue={defaultSelectedCountry}
            required={true}
            label="Country"
            name={`sikDetails[${currentIndex}].country1`}
            options={countryData.map((c) => c?.countryName)}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="Telephone"
            type="number"
            placeHolder="Enter Telephone No"
            name={`sikDetails[${currentIndex}].telephone1`}
          />
        </div>
      </>
    );
  };

  const addSikDetails = (d: any) => {
    const currentData = value?.sikDetails[0];
    const currentDataCopy = JSON.parse(JSON.stringify(currentData));
    for (const key in currentDataCopy) {
      currentDataCopy[key] = "";
    }
    d.push(currentDataCopy);

    const form = JSON.parse(JSON.stringify(applicationData.sikDetails[0]));
    form.collectionId = "";
    const keys: any = Object.keys(form.dataUnits);
    for (const fieldKey of keys) {
      form.dataUnits[fieldKey].dataUnitCode = "";
    }
    applicationData.sikDetails.push(form);
    setApplicationData(applicationData);
  };

  const deleteSikDetailData = (d: any, index: any) => {
    d.remove(index);
    const sikDetailsCopy = Object.assign(applicationData?.sikDetails[index]);

    const deleteSik: any = {
      applicationId: appId,
      forms: {
        sikDetails: [
          {
            ...sikDetailsCopy,
          },
        ],
      },
    };
    if (sikDetailsCopy.collectionId) {
      deleteForm(deleteSik, sikDetailsCopy.collectionId, appId);
    }
  };

  return (
    <div className="card p-5 w-100">
      <h3 className="border-bottom p-2">SIK Details</h3>
      <FieldArray
        name="sikDetails"
        render={(d) => (
          <div>
            <div className="row g-3">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Name of company"
                  type="text"
                  placeHolder="Enter Your Name of company"
                  name={`sikDetails[${currentIndex}].nameOfCompany`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Vendor Code"
                  type="text"
                  placeHolder="Enter Your Vendor Code"
                  name={`sikDetails[${currentIndex}].vendorCode`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Short Name"
                  type="text"
                  placeHolder="Enter Short Name"
                  name={`sikDetails[${currentIndex}].shortName1`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Date"
                  type="date"
                  placeHolder="Enter Date"
                  name={`sikDetails[${currentIndex}].date`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Place"
                  type="text"
                  placeHolder="Enter Your place"
                  name={`sikDetails[${currentIndex}].place`}
                />
                {/*<Checkbox*/}
                {/*  required={true}*/}
                {/*  title='Create New Organisation'*/}
                {/*  name={`sikDetails[${currentIndex}].createNewOrganization`}*/}
                {/*/>*/}
              </div>
              {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <RadioButton
                  title="Languages"
                  value={[{ value: "English", label: "English" }]}
                  name={`sikDetails[${currentIndex}].language`}
                />
              </div> */}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Address"
                  type="text"
                  placeHolder="Enter Address"
                  name={`sikDetails[${currentIndex}].address`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="City"
                  type="text"
                  placeHolder="Enter Your City"
                  name={`sikDetails[${currentIndex}].city`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="State"
                  type="text"
                  placeHolder="Enter Your State"
                  name={`sikDetails[${currentIndex}].state`}
                />
              </div>
              {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  label='Province'
                  type='text'
                  placeHolder='Enter Your Province'
                  name={`sikDetails[${currentIndex}].province`}
                />
              </div> */}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Postal Code"
                  type="number"
                  placeHolder="Enter Postal Code"
                  name={`sikDetails[${currentIndex}].postalCode`}
                />
                {/* <div className="col-auto">
                  <button type="button" className="mt-1 btn btn-primary me-3">
                    Validate
                  </button>
                </div> */}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Dropdowns
                  defaultValue={defaultSelectedCountry}
                  required={true}
                  label="Country"
                  name={`sikDetails[${currentIndex}].country`}
                  options={countryData.map((c) => c.countryName)}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Telephone"
                  type="number"
                  placeHolder="Enter Telephone No"
                  name={`sikDetails[${currentIndex}].telephone`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                {/*<Checkbox*/}
                {/*  title='Create New User ID'*/}
                {/*  name={`sikDetails[${currentIndex}].createNewUserId`}*/}
                {/*/>*/}
                <Checkbox
                  title="Supplier User"
                  name={`sikDetails[${currentIndex}].supplierUserInput`}
                />
              </div>
              <hr />
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
                <RadioButton
                  title="Approval of Manual Discount Requests"
                  value={[
                    { value: "singleRights", label: "Single Rights" },
                    { value: "jointRights", label: "Joint Rights" },
                  ]}
                  name={`sikDetails[${0}].approvalOrManuaDiscount`}
                />
                {value?.sikDetails[0]?.approvalOrManuaDiscount ===
                  "jointRights" && (
                  <button
                    className="btn btn-primary btn-sm align-self-start m-5"
                    type="button"
                    onClick={() => addSikDetails(d)}
                  >
                    Add User
                  </button>
                )}
              </div>
              {value?.sikDetails[0]?.approvalOrManuaDiscount ===
              "jointRights" ? (
                <div
                  className="accordion col-xl-12 col-lg-12 col-md-12 col-sm-12"
                  id="kt_accordion_1"
                >
                  {value?.sikDetails.map((sik: any, i: any) => (
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id={`kt_accordion_header_${i}`}
                      >
                        <button
                          className="accordion-button fs-4 fw-bold collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#kt_accordion_body_${i}`}
                          aria-expanded="false"
                          aria-controls={`kt_accordion_body_${i}`}
                          // onClick={() => setCurrentIndex(i)}
                        >
                          {i + 1}. {sik.firstName} {sik.middleName}{" "}
                          {sik.lastName}
                        </button>
                      </h2>
                      <div
                        id={`kt_accordion_body_${i}`}
                        className="accordion-collapse collapse row p-5"
                        aria-labelledby={`kt_accordion_header_${i}`}
                        data-bs-parent="#kt_accordion_1"
                      >
                        {renderManualDiscountRightsFields(i)}
                        {i > 0 && (
                          <div className="col-auto">
                            <button
                              type="button"
                              className="mt-5 btn btn-danger me-4"
                              onClick={() => deleteSikDetailData(d, i)}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                renderManualDiscountRightsFields(0)
              )}
              <hr />
              {/*<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5'>*/}
              {/*  <Checkbox*/}
              {/*    title='"DB Secure Authenticator" (dbSA) Soft token (App for iPhone or Android; iOS)'*/}
              {/*    name={`sikDetails[${currentIndex}].bdSecureAuthentication`}*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <Dropdowns
                  required={true}
                  name={`sikDetails[${currentIndex}].bulkUndiscountPayment`}
                  label="Bulking of undiscounted payments i.e. payments that are not subject to a Discount
            Acceptance"
                  options={["yes", "no"]}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <Dropdowns
                  required={true}
                  name={`sikDetails[${currentIndex}].bulkingDiscountPayment`}
                  label="Bulking of Discount Payments"
                  options={["yes", "no"]}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
                <RadioButton
                  required={true}
                  title="Discounting method"
                  value={[
                    { value: "true", label: "Manual Discounting" },
                    { value: "false", label: "Auto Discounting" },
                  ]}
                  name={`sikDetails[${currentIndex}].discountMethod`}
                />
              </div>
              {/*<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5'>*/}
              {/*  <Checkbox*/}
              {/*    title='All Payments'*/}
              {/*    name={`sikDetails[${currentIndex}].allPayment`}*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required
                  label="Name of Account Holder"
                  type="text"
                  placeHolder="Enter Name of Account Holder"
                  name={`sikDetails[${currentIndex}].nameAccountHolder`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Name of Bank"
                  type="text"
                  placeHolder="Enter Name of Bank"
                  name={`sikDetails[${currentIndex}].nameOfBank`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="IFSC CODE"
                  type="text"
                  placeHolder="Enter IFSC CODE"
                  name={`sikDetails[${currentIndex}].ifscCode`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Account Number / IBAN"
                  type="text"
                  placeHolder="Enter Account Number / IBAN"
                  name={`sikDetails[${currentIndex}].accountNumber`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Currency"
                  type="text"
                  placeHolder="Enter Currency"
                  name={`sikDetails[${currentIndex}].currency`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Name of company"
                  type="text"
                  placeHolder="Enter Name of company"
                  name={`sikDetails[${currentIndex}].nameOfCompany1`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Address"
                  type="text"
                  placeHolder="Enter Address"
                  name={`sikDetails[${currentIndex}].address2`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="City"
                  type="text"
                  placeHolder="Enter City"
                  name={`sikDetails[${currentIndex}].city2`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="State"
                  type="text"
                  placeHolder="Enter State"
                  name={`sikDetails[${currentIndex}].state2`}
                />
              </div>
              {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  label='Province'
                  type='text'
                  placeHolder='Enter Province'
                  name={`sikDetails[${currentIndex}].province2`}
                />
              </div> */}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Postal Code"
                  type="number"
                  placeHolder="Enter Postal Code"
                  name={`sikDetails[${currentIndex}].postalCode2`}
                />
                {/* <div className="col-auto">
                  <button
                    type="button"
                    className="mt-1 btn btn-primary me-3"
                    // onClick={() => deletePromoter(d, i)}
                  >
                    Validate
                  </button>
                </div> */}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Dropdowns
                  defaultValue={defaultSelectedCountry}
                  required={true}
                  label="Country"
                  name={`sikDetails[${currentIndex}].country2`}
                  options={countryData.map((c) => c.countryName)}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Telephone"
                  type="number"
                  placeHolder="Enter Telephone"
                  name={`sikDetails[${currentIndex}].telephone2`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  label="Name of Contact Person"
                  type="text"
                  placeHolder="Enter Name of Contact Person"
                  name={`sikDetails[${currentIndex}].nameOfContactPerson`}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required
                  label="E-Mail"
                  type="email"
                  placeHolder="Enter E-Mail"
                  name={`sikDetails[${currentIndex}].email`}
                />
              </div>
              {/*<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>*/}
              {/*  <Inputs*/}
              {/*    required={true}*/}
              {/*    label='Company Name and Form of Organisation'*/}
              {/*    type='text'*/}
              {/*    placeHolder='Enter Company Name and Form of Organisation'*/}
              {/*    name={`sikDetails[${currentIndex}].companyNameorFormofOrganization`}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>*/}
              {/*  <Inputs*/}
              {/*    required={true}*/}
              {/*    label='Address'*/}
              {/*    type='text'*/}
              {/*    placeHolder='Enter Address'*/}
              {/*    name={`sikDetails[${currentIndex}].address2`}*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Dropdowns
                  defaultValue={defaultSelectedCountry}
                  required={true}
                  label="Country"
                  name={`sikDetails[${currentIndex}].country3`}
                  options={countryData.map((c) => c.countryName)}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Inputs
                  required={true}
                  label="Currencies (applicable currency1 to be specified)"
                  type="text"
                  placeHolder="Enter Currencies (applicable currency1 to be specified)"
                  name={`sikDetails[${currentIndex}].currency1`}
                />
              </div>
              {value.buyerDetails.map((buyer: any, i: string) => {
                return (
                  <div className="row" key={i}>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <Inputs
                        disabled={!isEditable}
                        label={`Company Name and Form of Organisation ${
                          value.buyerDetails.length > 1 ? i + 1 : ""
                        }`}
                        type="text"
                        placeHolder="Enter Buyer Name"
                        name={`buyerDetails[${i}].buyerName`}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <Inputs
                        disabled={!isEditable}
                        label={`Company Address ${
                          value.buyerDetails.length > 1 ? i + 1 : ""
                        }`}
                        type="text"
                        placeHolder="Enter Buyer Address"
                        name={`buyerDetails[${i}].buyerAddress`}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="row">
                <div className="col-auto">
                  <button
                    type="submit"
                    className="mt-5 btn btn-primary me-4"
                    // onClick={() =>
                    //   saveApplicationHandler(value.sikDetails, 'sikDetails', setFieldError)
                    // }
                  >
                    {loading ? (
                      <span className="indicator-progress d-flex">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
                {/* <div className='col-auto'>
                  <button
                    type='button'
                    className='mt-5 btn btn-secondary me-4'
                    onClick={() => showSikDetails(d)}
                  >
                    Add Shareholder
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
}
