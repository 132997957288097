import { ErrorMessage, Field, FieldArray } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { Dropdowns } from "../../../../_metronic/layout/components/Dropdowns";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import {
  checkPAN,
  deleteForm,
  getAddressProofDocument,
  getApplication,
  getCountry,
  getDesignation,
  getModeOperation,
  getpanApi,
  getpinApi,
} from "../../../API/api";
import { countryDropdown } from "../Shareholder/Shareholder";
import { toastError, toastSuccess } from "./../../../../_metronic/helpers/ToastifyHelper";
import { cacheValidation } from "../../../../_metronic/helpers/ArrayHelper";
import * as Yup from "yup";
import { defaultSelectedCountry } from "../../../constants/DropdownConstants";
import { set } from "lodash";

interface Props {
  value: any;
  saveApplicationHandler: any;
  setFieldError: any;
  setFieldValue: any;
  loading: boolean;
  applicationData: any;
  setApplicationData: any;
  initialConstitution: any;
  appId: string;
}

const PromoterInfo: FC<Props> = ({
  value,
  saveApplicationHandler,
  setFieldError,
  setFieldValue,
  loading,
  applicationData,
  setApplicationData,
  initialConstitution,
  appId,
}) => {
  const [modeOfOperation, setModeOfOperation] = useState<any[]>([]);
  const [designation, setDesignation] = useState<any[]>([]);
  const [countryData, setCountryData] = useState<any[]>([]);
  const [addressProofData, setAddressProofData] = useState<any[]>([]);
  const [applicationDataPin, setApplicationDataPin] = useState<any>({});
  // const [currentIndex, setCurrentIndex] = useState(0)
  const isConstitutionPublicOrPrivate =
    initialConstitution === "Private Limited" ||
    initialConstitution === "Public Limited";
  const valueForm =
    initialConstitution === "Sole Proprietor"
      ? "soloProprietor"
      : isConstitutionPublicOrPrivate
      ? "privateLimited"
      : initialConstitution === "Partnership"
      ? "partnership"
      : "LLP";

  useEffect(() => {
    getModeOperation()
      .then((res) => setModeOfOperation(res.data))
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );

    getDesignation()
      .then((res) => setDesignation(res.data))
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
    getAddressProofDocument()
      .then((res) => setAddressProofData(res.data))
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  }, []);

  const setCountryToIndia = () => {
    value[valueForm]?.forEach((form: any, index: number) => {
      if (form?.country == "") {
        set(form, "country", "India");
      }
    });
  };
  const getpanValidated = (values: any, currentIndex: any) => {
    // if(value ==='sikDetails'){
    // Partnership
    const collectionName = "Partnership";

    const value1 = values[collectionName];

    let collection = applicationData[valueForm];
    const collectionId = applicationData[valueForm][currentIndex]?.collectionId;

    const data = collection?.map((collection: any, index: number) => {
      const values = Array.isArray(value1) ? value1[index] : value1;
      const dataUnits = formatDataForAPI(collection.dataUnits, values);
      const panNo = value[valueForm][currentIndex].panNo;

      if (dataUnits?.panNo) {
        dataUnits.panNo.dataUnitValue = panNo;
      }

      return { dataUnits, collectionId: collection.collectionId };
    });

    const pinCheck = {
      applicationId: appId,
      forms: {
        [collectionName]: data,
      },
    };

    getpanApi(
      pinCheck,
      collectionName,
      value[valueForm][currentIndex].panNo,
      collectionId
    )
      .then((res) => {
        const { forms } = res.data;
        const form = JSON.parse(JSON.stringify(forms?.Partnership[0]));

        setFieldValue(
          `${valueForm}[${currentIndex}].firstName`,
          `${form?.dataUnits?.firstName?.dataUnitValue}`
        );
        toastSuccess("PanNo validated");
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  };

  //   .then((res) => {
  //     const {forms} = res.data;
  //     setApplicationDataPin(forms)
  //     const form = JSON.parse(JSON.stringify(applicationData[valueForm][0]));
  //       form.collectionId = "";
  //       const keys: any = Object.keys(form.dataUnits);
  //       for (const fieldKey of keys) {
  //         form.dataUnits[fieldKey].dataUnitCode = "";
  //       }
  //     applicationDataPin[valueForm].push(form);
  //       setApplicationDataPin(applicationDataPin);
  //    console.log(form,valueForm,applicationDataPin,"test console")
  //   })
  //   .catch((err) => {
  //     toastError(err.response?.data?.error || err.response?.data)
  //   })

  // }
  //PinCode API Call//
  const getpincode = (values: any, currentIndex: any) => {
    // if(value ==='sikDetails'){
    // Partnership
    const collectionName = "Partnership";

    const value1 = values[collectionName];

    let collection = applicationData[valueForm];
    const collectionId = applicationData[valueForm][currentIndex]?.collectionId;

    const data = collection?.map((collection: any, index: number) => {
      const values = Array.isArray(value1) ? value1[index] : value1;
      const dataUnits = formatDataForAPI(collection.dataUnits, values);
      const pinCode = value[valueForm][currentIndex].pinCode;

      // Update specific entry in dataUnits object with user input
      if (dataUnits?.pinCode) {
        dataUnits.pinCode.dataUnitValue = pinCode;
      }
      return { dataUnits, collectionId: collection.collectionId };
    });
    // const pinCode = value[valueForm][0].pinCode;

    const pinCheck = {
      applicationId: appId,
      forms: {
        [collectionName]: data,
      },
    };

    getpinApi(
      pinCheck,
      collectionName,
      value[valueForm][currentIndex].pinCode,
      collectionId
    )
      .then((res) => {
        const { forms } = res.data;
        const form = JSON.parse(
          JSON.stringify(forms?.Partnership[currentIndex])
        );

        setFieldValue(
          `${valueForm}[${currentIndex}].city`,
          `${form?.dataUnits?.city?.dataUnitValue}`
        );
        setFieldValue(
          `${valueForm}[${currentIndex}].state`,
          `${form?.dataUnits?.state?.dataUnitValue}`
        );
        toastSuccess("Pincode validated");
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  };

  const formatDataForAPI = (dataUnits: any, values: any) => {
    const fields = Object.keys(dataUnits);
    for (const field of fields) {
      if (values) {
        const dataUnit = dataUnits[field];
        const fieldValue = values[field];
        dataUnit.dataUnitValue = fieldValue;
      }
    }

    return dataUnits;
  };

  useEffect(() => {
    getCountry()
      .then((res) => {
        let dataArray = res?.data;
        // console.log("dataArray",dataArray)
        let indexOfIndia: any = null;
        dataArray?.forEach((country: any, index: number) => {
          if (country?.countryName == "India") {
            indexOfIndia = index;
          }
        });
        dataArray?.splice(indexOfIndia, 1);
        setCountryData(res.data);
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  }, []);

  useEffect(() => {
    if (countryData?.length > 0 && valueForm && value) {
      setCountryToIndia();
    }
  }, [countryData, value, valueForm]);

  const handlePanNoChange = useCallback(
    cacheValidation(async (value: string, { setFieldValue, index }) => {
      try {
        // TODO: remove in prod
        if (!value || value === "JXXXX9999N") return "";
        await Yup.string()
          // .required('Pan no is a required field')
          .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Enter valid pan no")
          .validate(value);
        return checkPAN(value!).then((res) => {
          /*if (res.data.status !== "SUCCESS") {
            setFieldValue(`${valueForm}[${index}].firstName`, "");
            setFieldValue(`${valueForm}[${index}].middleName`, "");
            setFieldValue(`${valueForm}[${index}].lastName`, "");
            return "Enter valid PAN Number (ex. JXXXX9999N)";
          }*/
          /*const name = res.data.data?.split(" ");
          setFieldValue(`${valueForm}[${index}].firstName`, name[0]);
          setFieldValue(`${valueForm}[${index}].middleName`, name[1]);
          setFieldValue(`${valueForm}[${index}].lastName`, name[2]);*/
          return "";
        });
      } catch (e: any) {
        return e.message;
      }
    }),
    []
  );

  const renderPromoterInfoField = (currentIndex: number) => {
    return (
      <div className="row g-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            required={true}
            label="Title"
            name={`${valueForm}[${currentIndex}].title`}
            options={["Mr", "Ms", "MRS"]}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            // required={
            //   // !!(
            //   //   (initialConstitution !== "Private Limited" ||
            //   //     initialConstitution !== "Public Limited") &&
            //   //   value[valueForm] &&
            //   //   value[valueForm][currentIndex]?.nationality === "Indian"
            //   // )
            //   false
            // }
            validate={(val: string) =>
              handlePanNoChange(val, {
                key: `${valueForm}[${currentIndex}].panNo`,
                index: currentIndex,
                setFieldValue,
              })
            }
            label="Pan No"
            type="text"
            placeHolder="Enter Your Pan No"
            name={`${valueForm}[${currentIndex}].panNo`}
          />
          <button
            type="button"
            className="mt-1 btn btn-primary me-3"
            onClick={(values) => {
              getpanValidated(values, currentIndex);
            }}
          >
            Validate
          </button>
          {/* <div className="col-auto">
            <button
              type="button"
              className="mt-1 btn btn-primary me-3"
              onClick={() => {}}
            >
              Validate
            </button>
          </div> */}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            label="First Name"
            type="text"
            placeHolder="Enter Your First Name"
            name={`${valueForm}[${currentIndex}].firstName`}
          />
        </div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              name={`${valueForm}[${currentIndex}].middleName`}
              label="Middle Name"
              type="text"
              placeHolder="Enter Your Middle Name"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              name={`${valueForm}[${currentIndex}].lastName`}
              label="Last Name"
              type="text"
              placeHolder="Enter Your Last Name"
            />
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Dropdowns
              required={true}
              label="Gender"
              name={`${valueForm}[${currentIndex}].gender`}
              options={["MALE", "FEMALE"]}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              name={`${valueForm}[${currentIndex}].dateOfBirth`}
              label="Date of Birth"
              type="date"
              placeHolder=""
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            required={true}
            name={`${valueForm}[${currentIndex}].nationality`}
            label="Nationality"
            options={countryDropdown.map((c) => c)}
          />
        </div>
        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={
              !!(
                (initialConstitution !== "Private Limited" ||
                  initialConstitution !== "Public Limited") &&
                value[valueForm] &&
                value[valueForm][currentIndex]?.nationality === "Indian"
              )
            }
            validate={(val: string) =>
              handlePanNoChange(val, {
                key: `${valueForm}[${currentIndex}].panNo`,
                index: currentIndex,
                setFieldValue,
              })
            }
            label="Pan No"
            type="text"
            placeHolder="Enter Your Pan No"
            name={`${valueForm}[${currentIndex}].panNo`}
          />
          <div className="col-auto">
            <button
              type="button"
              className="mt-1 btn btn-primary me-3"
              onClick={() => {}}
            >
              Validate
            </button>
          </div>
        </div> */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            label="Address Proof Document"
            name={`${valueForm}[${currentIndex}].addressProof`}
            options={addressProofData.map((a) => a.addressProofDocName)}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            name={`${valueForm}[${currentIndex}].addressProofNo`}
            label="Address Proof Number"
            type="text"
            placeHolder="Enter Your Address Proof Number"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            name={`${valueForm}[${currentIndex}].addressLine1`}
            label="Address Line 1"
            type="text"
            placeHolder="Enter Your Address"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            name={`${valueForm}[${currentIndex}].addressLine2`}
            label="Address Line 2"
            type="text"
            placeHolder="Enter Your Address"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          {/* <Inputs
            required={
              value[valueForm] &&
              value[valueForm][currentIndex]?.nationality === "Indian"
            }
            name={`${valueForm}[${currentIndex}].pinCode`}
            label="Pincode"
            type={
              value[valueForm] &&
              value[valueForm][currentIndex]?.nationality === "Indian"
                ? "number"
                : "text"
            }
            placeHolder="Enter Your PinCode"
          /> */}
          <div>
            <label
              className={`col-sm-3 col-form-label text-nowrap ${
                value[valueForm] &&
                value[valueForm][currentIndex]?.nationality === "Indian" &&
                "required"
              }`}
              htmlFor={`${valueForm}[${currentIndex}].pinCode`}
            >
              {"Pincode"}
            </label>
            <div className="d-flex gap-2">
              <Field
                type={
                  value[valueForm] &&
                  value[valueForm][currentIndex]?.nationality === "Indian"
                    ? "number"
                    : "text"
                }
                name={`${valueForm}[${currentIndex}].pinCode`}
                id={`${valueForm}[${currentIndex}].pinCode`}
                className="form-control form-control-lg form-control-solid"
                placeholder={"Enter Your PinCode"}
              />
              <div className="col-auto">
                <button
                  type="button"
                  className="mt-1 btn btn-primary me-3"
                  onClick={(values) => {
                    getpincode(values, currentIndex);
                  }}
                >
                  Validate
                </button>
              </div>
            </div>

            <div className="text-danger m-2">
              <ErrorMessage name={`${valueForm}[${currentIndex}].pinCode`} />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            name={`${valueForm}[${currentIndex}].city`}
            label="City"
            type="text"
            placeHolder="Enter Your City"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            required={true}
            name={`${valueForm}[${currentIndex}].state`}
            label="State"
            type="text"
            placeHolder="Enter Your State"
          />
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            defaultValue={defaultSelectedCountry}
            required={true}
            label="Country"
            name={`${valueForm}[${currentIndex}].country`}
            options={countryData.map((c) => c.countryName)}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            required={true}
            label="Designation"
            name={`${valueForm}[${currentIndex}].designation`}
            options={designation.map((d) => d.designationName)}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            name={`${valueForm}[${currentIndex}].emailID`}
            label="Email ID"
            type="email"
            placeHolder="Enter Your Email"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Inputs
            name={`${valueForm}[${currentIndex}].mobileNo`}
            label="Mobile No"
            type="number"
            placeHolder="Enter Your Mobile Number"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <Dropdowns
            required={true}
            label="Authorised Signatory"
            name={`${valueForm}[${currentIndex}].authorizedSignatory`}
            options={["YES", "NO"]}
          />
        </div>
        {(value.businessInfo.constitution === "Private Limited" ||
          value.businessInfo.constitution === "Public Limited") && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={
                value[valueForm] &&
                value[valueForm][currentIndex]?.authorizedSignatory !== "NO"
              }
              name={`${valueForm}[${currentIndex}].boardMeetingDate`}
              label="Board Meeting Date"
              type="date"
              placeHolder=""
            />
          </div>
        )}
        {(value.businessInfo.constitution === "Private Limited" ||
          value.businessInfo.constitution === "Public Limited" ||
          value.businessInfo.constitution === "Partnership" ||
          value.businessInfo.constitution === "LLP") && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Dropdowns
              required={
                value[valueForm] &&
                value[valueForm][currentIndex]?.authorizedSignatory !== "NO"
              }
              label="Mode of Operation"
              name={`${valueForm}[${currentIndex}].modeOfOperation`}
              options={modeOfOperation?.map((v) => v.operationName)}
            />
          </div>
        )}
        {(value.businessInfo.constitution === "Partnership" ||
          value.businessInfo.constitution === "LLP") && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Dropdowns
              required={
                value[valueForm] &&
                value[valueForm][currentIndex]?.authorizedSignatory !== "NO"
              }
              label="List of New/deleted partners"
              name={`${valueForm}[${currentIndex}].listOfNewAndDeleted`}
              options={["ADDED", "DELETED"]}
            />
          </div>
        )}
        {(value.businessInfo.constitution === "Private Limited" ||
          value.businessInfo.constitution === "Public Limited") && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Dropdowns
              required={
                value[valueForm] &&
                value[valueForm][currentIndex]?.authorizedSignatory !== "NO"
              }
              name={`${valueForm}[${currentIndex}].isSmo`}
              label="IS SMO"
              options={["YES", "NO"]}
            />
          </div>
        )}
      </div>
    );
  };

  const addDirectorPromoter = (d?: any) => {
    if (value[valueForm] && value[valueForm][0]) {
      const currentData = value[valueForm][0];
      const currentDataCopy = JSON.parse(JSON.stringify(currentData));
      for (const key in currentDataCopy) {
        currentDataCopy[key] = "";
      }
      d.push(currentDataCopy);
      const form = JSON.parse(JSON.stringify(applicationData[valueForm][0]));
      form.collectionId = "";
      const keys: any = Object.keys(form.dataUnits);
      for (const fieldKey of keys) {
        form.dataUnits[fieldKey].dataUnitCode = "";
      }
      applicationData[valueForm].push(form);
      setApplicationData(applicationData);
    }
  };

  // console.log("Busin",applicationData)
  const deletePromoter = (d: any, index: any) => {
    d.remove(index);
    const businessInfoDataCopy = Object.assign(
      applicationData[valueForm][index]
    );
    const deleteData: any = {
      applicationId: appId,
      forms: {
        [valueForm]: [
          {
            ...businessInfoDataCopy,
          },
        ],
      },
    };
    if (businessInfoDataCopy.collectionId) {
      deleteForm(deleteData, businessInfoDataCopy.collectionId, appId);
    }
  };

  return (
    <div className="card p-5 w-100">
      <h3 className="border-bottom p-2">Promoter/Director/Partner</h3>
      <FieldArray
        name={valueForm}
        render={(d) => (
          <div>
            {value[valueForm] && value[valueForm].length > 1 ? (
              <div
                className="accordion col-xl-12 col-lg-12 col-md-12 col-sm-12"
                id="kt_accordion_1"
              >
                {value[valueForm] &&
                  value[valueForm].map((promoter: any, i: any) => (
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id={`kt_accordion_header_${i}`}
                      >
                        <button
                          className="accordion-button fs-4 fw-bold collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#kt_accordion_body_${i}`}
                          aria-expanded="false"
                          aria-controls={`kt_accordion_body_${i}`}
                        >
                          {i + 1}. {promoter.firstName} {promoter.middleName}{" "}
                          {promoter.lastName}
                        </button>
                      </h2>
                      <div
                        id={`kt_accordion_body_${i}`}
                        className="accordion-collapse collapse row p-5"
                        aria-labelledby={`kt_accordion_header_${i}`}
                        data-bs-parent="#kt_accordion_1"
                      >
                        {renderPromoterInfoField(i)}
                        {i > 0 && (
                          <div className="col-auto">
                            <button
                              type="button"
                              className="mt-5 btn btn-danger me-4"
                              onClick={() => deletePromoter(d, i)}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              renderPromoterInfoField(0)
            )}
            <div className="row">
              <div className="col-auto">
                <button
                  type="submit"
                  className="mt-5 btn btn-primary me-4"
                  // onClick={() => {
                  //   saveApplicationHandler(value[valueForm], valueForm, setFieldError)
                  // }}
                >
                  {loading ? (
                    <span className="indicator-progress d-block">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              {value.businessInfo.constitution !== "Sole Proprietor" && (
                <div className="col-auto">
                  <button
                    type="button"
                    className="mt-5 btn btn-primary me-4"
                    onClick={() => addDirectorPromoter(d)}
                  >
                    {value.businessInfo.constitution === "Partnership"
                      ? "Add Partners"
                      : "Add Director"}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};
export default PromoterInfo;
