import * as Yup from "yup";

export interface ICreateAccount {
  // Business Info
  businessInfo: {
    // applicationDate: string
    gstNumber: string;
    nameOfCompany: string;
    constitution: string;
    businessSector: string;
    cinNo: string;
    nameOfEntityAsPerMca: string;
    panNo: string;
    currency: string;
    salesTurnover: string;
    nameOfContactPerson: string;
    emailId: string;
    briefDescriptionOfNatureOfBusiness: string;
    dateOfIncorporation: string;
    balanceSheetTotalForLastAvailableYear: string;
    contactNo: string;
    remarks: string;
    comments: string;
    additionalInfo: string;
    others: string;
    // customerType: string
    // nameOfApplication: string
    // industry: string
    // yearInBusiness: string
  };
  // Buyer Details
  buyerDetails: [
    {
      buyerName: string;
      buyerAddress: string;
      // location: string
      // turnover: string
      // billDiscounting: string
      // yearOfRelationship: string
    }
  ];

  // Compony Address Detail
  companyAddressDetails: {
    address: string;
    pinCode: string;
    state: string;
    telephoneNo: string;
    city: string;
    country: string;
    mobileNo: string;
    addressRD: string;
    pinCodeRD: string;
    cityRD: string;
    telephoneNoRD: string;
    mobileNoRD: string;
    stateRD: string;
    countryRD: string;
    registeredAddress: string;
    addressProof: string;
  };

  // Director/Promoter/Proprietor/Partners
  directorPromoterInfo: [
    {
      title: string;
      firstName: string;
      middleName: string;
      lastName: string;
      gender: string;
      dateOfBirth: string;
      panNo: string;
      addressProof: string;
      addressProofNo: string;
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      pinCode: string;
      country: string;
      nationality: string;
      designation: string;
      emailID: string;
      mobileNo: string;
      authorizedSignatory: string;
      boardMeetingDate: string;
      modeOfOperation: string;
      isSmo: string;
      passport: string;
      // nationality: string
      // panNo: string
      // type: string
      // designation: string
      // residentialStatus: string
      // DINNo: string
      // promoteTelephoneNo: string
      // promoteMobileNo: string
      // promotePinCode: string
      // district: string
      // promoteCountry: string
      // dateOfAuthorization: string
      // title2: string
      // firstName2: string
      // middleName2: string
      // lastName2: string
    }
  ];

  // Shareholder Details
  partnershipDeepDoc: {
    cancelledCheque: string;
    companyPanCard: string;
    eitherOfPassportVoterIdDrivingLicence: string;
    gstRegistrationCertificateUdyogAadharCertificate: string;
    mailingAddressProof: string;
    "panCardOfAuthorizedSignatory&Shareholder": string;
    partnershipDeep: string;
  };
  shareholder: [
    {
      typeOfShareholder: string;
      firstName: string;
      lastName: string;
      middleName: string;
      nationality: string;
      dateOfBirth: string;
      address: string;
      panDetails: string;
      shareHolding: string;
      passport: string;
      companyName: string;
      // dateOfIncorporationShareholder: string
    }
  ];

  // cover letter details
  coverLetterDetails: {
    //private
    billDiscountingFacility: string;
    dateOfAuthorization: string;
    latestListOfDirectorsr: boolean;
    latestRegisteredAddress: boolean;
    latestMailingAddress: boolean;
    latestMemorandum: boolean;
    latestArticleOfAssociation: boolean;
    latestCertificateOfIncorp: boolean;
    majorCountriesOfOperation: string;
    numberOfEmployees: string;
    expectedMonthlyTransaction: string;
    // boardResolution: string
    //sole
    certifiedEntityProof: boolean;
    registrationCertificates: boolean;
    utlityBills: boolean;
    complateSalesAndIncomeTaxReturn: boolean;
    saleIncomeTaxReturn: boolean;
    licenseIssuedAuthority: boolean;
    "gst/cst/vatCertificate": boolean;
    certificateOfMuncipal1: boolean;
    certificateIssuedByAuthorities: boolean;
    licenseIssuedAuthority1: boolean;
    certificateOfMuncipal: boolean;
    registeredAddress: boolean;
    mailingAddressForAforesaid: boolean;
    iecImportExportCode: boolean;
    // Partnership
    dateOfPartnership: string;
    // listOfPartners: boolean
    latestListOfPartners: boolean;
    // LLP
    boardMeetingDate: string;
  };

  // SIK Details
  sikDetails: [
    {
      // "cityStateProvince"
      // "cityStateProvince2"
      // "cityStateProvince1"
      // "supplierUser"
      // "dateOfAuthorization"

      nameOfCompany: string;
      vendorCode: string;
      place: string;
      date: string;
      createNewOrganization: boolean;
      language: string;
      approvalOrManuaDiscount: string;
      address: string;
      city: string;
      state: string;
      // province: string
      city1: string;
      state1: string;
      // province1: string
      postalCode: string;
      country: string;
      telephone: string;
      // Input: boolean
      // Approval: boolean
      // Reporting: boolean
      // Viewing: boolean
      createNewUserId: boolean;
      userId: string;
      title: string;
      firstName: string;
      middleName: string;
      lastName: string;
      shortName: string;
      address3: string;
      // citySIK2: string
      // stateSIK2: string
      // provinceSIK: string
      postalCode1: string;
      country1: string;
      telephone1: string;
      bdSecureAuthentication: boolean;
      bulkingDiscountPayment: boolean;
      bulkUndiscountPayment: boolean;
      discountMethod: string;
      allPayment: boolean;
      nameAccountHolder: string;
      nameOfBank: string;
      ifscCode: string;
      accountNumber: string;
      currency: string;
      nameOfCompany1: string;
      address1: string;
      city2: string;
      state2: string;
      // province2: string
      postalCode2: string;
      country2: string;
      telephone2: string;
      nameOfContactPerson: string;
      email: string;
      companyNameorFormofOrganization: string;
      address2: string;
      country3: string;
      currency1: string;
      supplierUserInput: boolean;
      // supplierUserApproval: boolean
      // supplierUserReporting: boolean
      // supplierUserViewing: boolean
    }
  ];

  //Supplier Agreement Details
  supplierAgreementDetails: {
    nameOfCompany: string;
    address: string;
    pinCode: string;
    countryCode: string;
    citySupplier: string;
    date: string;
    place: string;
    nameOfCompany1: string;
    companyPanDetails: string;
    address1: string;
    date1: string;
    nameOfCompany2: string;
    date2: string;
    witnessName1: string;
    witnessName: string;
    date3: string;
    nameOfCompany3: string;
    place1: string;
    buyerName: string;
    buyerAddress: string;
  };

  //Authorized Signatory
  authorizeSignatory: [
    {
      title: string;
      firstName: string;
      middleName: string;
      lastName: string;
      nationality: string;
      designation: string;
      dateOfBirth: string;
      mobileNo: string;
      addressLine1: string;
      addressLine2: string;
      pinCode: string;
      city: string;
      district: string;
      State: string;
      country: string;
      panNo: string;
      passportNumber: string;
      // typeAuthorised: string
      // modeOfOperationAuthorized: string
      // emailIDAuthorised: string
      // residentialStatusAuthorised: string
      // DINNoAuthorised: string
      // promoteTelephoneNoAuthorised: string
      // authorisedSignatoryAuthorised: string
      // dateOfAuthorizationAuthorised: string
      // addressProofSubmittedAuthorised: string
      // addressProofNumberAuthorised: string
      // title2Authorised: string
      // firstName2Authorised: string
      // middleName2Authorised: string
      // lastName2Authorised: string
    }
  ];
  EOI: {
    bankName: string;
    bankAddress: string;
    typeOfFacility: string;
    amount: number;
    nameOfCompany: string;
  };
  //Confirmation Letter
  // nameConfirmation: string
  // addressConfirmation: string
  // mobileNumber: string
  // EmailIDConfirmation: string
  // nameOfCompanyConfirmation: string
  // addressConfirmation2: string
  // contactNumber: string
  // emailIDConfirmation: string

  //List of Other Documents
  //...
  // accountType: string
}

const businessInfoSchema = Yup.object({
  businessInfo: Yup.object({
    gstNumber: Yup.string()
      .nullable()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Enter valid GST no (ex. 12ABCDE1234A1Z1)"
      ),
    nameOfCompany: Yup.string()
      .nullable()
      .matches(/[a-zA-Z ]*$/, "Enter valid Name"),
    constitution: Yup.string().nullable(),
    businessSector: Yup.string().nullable(),
    cinNo: Yup.string()
      .nullable()
      .when("constitution", (constitution, Schema) =>
        constitution === "Private Limited" || constitution === "Public Limited"
          ? Schema.matches(
              /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
              "Enter valid CIN no (ex. L74899DL1988PLC032166)"
            ).max(21, "only 21 characters allowed")
          : constitution === "LLP"
          ? Schema.matches(
              /^[A-Z]{3}-[0-9]{4}$/,
              "Enter valid CIN no (ex. ABC-1234)"
            )
          : Schema
      ),
    nameOfEntityAsPerMca: Yup.string()
      .nullable()
      .when("constitution", (constitution, Schema) =>
        constitution === "LLP"
          ? Schema.matches(/^[a-zA-Z ]*$/, "Enter valid Name")
          : Schema
      ),
    panNo: Yup.string()
      .nullable()
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Enter valid pan no")
      .min(10, "must be at least 10 characters")
      .max(10, "must be at least 10 characters"),
    // currency: Yup.string().nullable().required('Currency is a required field'),
    salesTurnover: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z0-9]*$/, "Enter valid Sales Turnover value"),
    nameOfContactPerson: Yup.string().nullable(),
    // .matches(/^[a-zA-Z ]*$/, 'Enter valid Name'),
    emailId: Yup.string().email("Enter valid email").nullable(),
    briefDescriptionOfNatureOfBusiness: Yup.string()
      .max(250, "only 250 characters allowed")
      .nullable(),
    // .matches(/^[a-zA-Z ]*$/, 'Enter valid Name'),
    dateOfIncorporation: Yup.date(),
    balanceSheetTotalForLastAvailableYear: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z0-9 ]*$/, "Enter valid Name"),
    contactNo: Yup.string()
      .nullable()
      .min(10, "must be at least 10 characters")
      .max(10, "must be at least 10 characters"),
  }),
});
const businessInfoRequiredSchema = Yup.object({
  businessInfo: Yup.object({
    // applicationDate: Yup.date(),
    gstNumber: Yup.string().required("GST no is a required field"),
    nameOfCompany: Yup.string().required("Name of compony is a required field"),
    constitution: Yup.string().required("Constitution is a required field"),
    businessSector: Yup.string().required(
      "Name of business sector is a required field"
    ),
    cinNo: Yup.string().when("constitution", (constitution, Schema) =>
      constitution === "LLP"
        ? Schema.required("CIN no is a required field")
        : Schema
    ),
    nameOfEntityAsPerMca: Yup.string().when(
      "constitution",
      (constitution, Schema) =>
        constitution === "LLP"
          ? Schema.required("Name of entity as per MCA is a required field")
          : Schema
    ),
    panNo: Yup.string().required("Pan no is a required field"),
    salesTurnover: Yup.string().required("Sales turnover is a required field"),
    nameOfContactPerson: Yup.string().required(
      "Name of contact person is a required field"
    ),
    emailId: Yup.string().required("Email Id is a required field"),
    briefDescriptionOfNatureOfBusiness: Yup.string().required(
      "Brief description of nature of business is a required field"
    ),
    dateOfIncorporation: Yup.date().required(
      "Date of incorporation is a required field"
    ),
    balanceSheetTotalForLastAvailableYear: Yup.number().required(
      "Balance sheet total for last available year is a required field"
    ),
    contactNo: Yup.string().required("Contact no is a required field"),
    // customerType: Yup.string().required('Customer type is a required field'),
    // yearInBusiness: Yup.string().required('Year in business is a required field'),
    // industry: Yup.string().required('Industry is a required field'),
    // nameOfApplication: Yup.string().required('Name of application is a required field'),
  }),
});

const companyAddressDetailSchema = Yup.object({
  companyAddressDetails: Yup.object({
    address: Yup.string().nullable().max(250, "only 250 characters allowed"),
    pinCode: Yup.string()
      .nullable()
      .min(6, "Pin code must be at least 6 characters")
      .max(6, "Pin code must be at least 6 characters"),
    city: Yup.string()
      .max(26, "only 26 characters allowed")
      .nullable()
      .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
    telephoneNo: Yup.string()
      .nullable()
      .min(10, "Telephone no must be at least 10 characters")
      .max(10, "Telephone no must be at least 10 characters"),
    mobileNo: Yup.string()
      .nullable()
      .min(10, "Mobile no must be at least 10 characters")
      .max(10, "Mobile no must be at least 10 characters"),
    state: Yup.string()
      .max(26, "only 26 characters allowed")
      .nullable()
      .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
    country: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
    addressRD: Yup.string()
      .max(250, "only 250 characters allowed")
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES" ? Schema : Schema
      ),
    pinCodeRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.min(6, "Pin code must be at least 6 characters").max(
              6,
              "Pin code must be at least 6 characters"
            )
          : Schema
      ),
    cityRD: Yup.string()
      .max(26, "only 26 characters allowed")
      .nullable()
      .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES" ? Schema : Schema
      ),
    telephoneNoRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.min(10, "Telephone no must be at least 10 characters").max(
              10,
              "Telephone no must be at least 10 characters"
            )
          : Schema
      ),
    mobileNoRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.min(10, "Mobile no must be at least 10 characters").max(
              10,
              "Mobile no must be at least 10 characters"
            )
          : Schema
      ),
    stateRD: Yup.string()
      .max(26, "only 26 characters allowed")
      .nullable()
      .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES" ? Schema : Schema
      ),
    countryRD: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES" ? Schema : Schema
      ),
    addressProof: Yup.string().nullable(),
    registeredAddress: Yup.string().nullable(),
  }),
});
const companyAddressDetailRequiredSchema = Yup.object({
  companyAddressDetails: Yup.object({
    address: Yup.string().nullable().required("Address is a required field"),
    pinCode: Yup.string()
      .nullable()
      .required("Pin code is a required field")
      .min(6, "Pin code must be at least 6 characters")
      .max(6, "Pin code must be at least 6 characters"),
    city: Yup.string().nullable().required("City is a required field"),
    telephoneNo: Yup.string()
      .nullable()
      .required("Telephone no is a required field")
      .min(10, "Telephone no must be at least 10 characters")
      .max(10, "Telephone no must be at least 10 characters"),
    mobileNo: Yup.string()
      .nullable()
      .required("Mobile no is a required field")
      .min(10, "Mobile no must be at least 10 characters")
      .max(10, "Mobile no must be at least 10 characters"),
    state: Yup.string().nullable().required("State is a required field"),
    country: Yup.string().nullable().required("Country is a required field"),
    addressRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.required("Address is a required field")
          : Schema
      ),
    pinCodeRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.required("Pin code is a required field")
              .min(6, "Pin code must be at least 6 characters")
              .max(6, "Pin code must be at least 6 characters")
          : Schema
      ),
    cityRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.required("City is a required field")
          : Schema
      ),
    telephoneNoRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.required("Telephone no is a required field")
              .min(10, "Telephone no must be at least 10 characters")
              .max(10, "Telephone no must be at least 10 characters")
          : Schema
      ),
    mobileNoRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.required("Mobile no is a required field")
              .min(10, "Mobile no must be at least 10 characters")
              .max(10, "Mobile no must be at least 10 characters")
          : Schema
      ),
    stateRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.required("State is a required field")
          : Schema
      ),
    countryRD: Yup.string()
      .nullable()
      .when("registeredAddress", (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.required("Country is a required field")
          : Schema
      ),
    addressProof: Yup.string().when(
      "registeredAddress",
      (registeredAddress, Schema) =>
        registeredAddress !== "YES"
          ? Schema.nullable().required(
              "Address proof submitted is a required field"
            )
          : Schema
    ),
    registeredAddress: Yup.string().nullable(),
  }),
});

const directorPromoter = (key: string, constitution: string) =>
  Yup.object().shape({
    [key]: Yup.array().of(
      Yup.object().shape({
        title: Yup.string(),
        firstName: Yup.string()
          .max(26, "only 26 characters allowed")
          .nullable()
          .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
        middleName: Yup.string()
          .max(26, "only 26 characters allowed")
          .nullable()
          .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
        lastName: Yup.string()
          .max(26, "only 26 characters allowed")
          .nullable()
          .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
        gender: Yup.string(),
        dateOfBirth: Yup.date(),
        panNo: Yup.string().when(["nationality"], {
          is: (nationality: any) =>
            (constitution === "Private Limited" ||
              constitution === "Public Limited") &&
            nationality === "Indian",
          then: Yup.string()
            .required("Pan no is a required field")
            .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Enter valid pan no")
            .min(10, "Pan no must be at least 10 characters")
            .max(10, "Pan no must be at least 10 characters"),
          otherwise: Yup.string()
            .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Enter valid pan no")
            .min(10, "Pan no must be at least 10 characters")
            .max(10, "Pan no must be at least 10 characters"),
        }),
        // addressProof: Yup.string().when("nationality", (nationality, Schema) =>
        //   (constitution === "Private Limited" ||
        //     constitution === "Public Limited") &&
        //   nationality !== "Indian"
        //     ? Schema.required("Address proof is a required field")
        //     : Schema
        // ),
        // addressProofNo: Yup.string().when(
        //   "nationality",
        //   (nationality, Schema) =>
        //     (constitution === "Private Limited" ||
        //       constitution === "Public Limited") &&
        //     nationality !== "Indian"
        //       ? Schema.required("Address proof no is a required field").matches(
        //           /^[a-zA-Z0-9-]*$/,
        //           "Enter valid address proof number"
        //         )
        //       : Schema.matches(
        //           /^[a-zA-Z0-9-]*$/,
        //           "Enter valid address proof number"
        //         )
        // ),
        // .min(12, 'Address proof number must be at least 12 characters')
        // .max(12, 'Address proof number must be at least 12 characters'),
        addressLine1: Yup.string(),
        city: Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
          .max(250, "only 250 characters allowed"),
        state: Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
          .max(250, "only 250 characters allowed"),
        pinCode: Yup.string().when("nationality", (nationality, Schema) =>
          nationality === "Indian"
            ? Schema.min(6, "Pin code must be at least 6 characters").max(
                6,
                "Pin code must be at least 6 characters"
              )
            : Schema
        ),
        country: Yup.string(),
        nationality: Yup.string().matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
        designation: Yup.string(),
        emailID: Yup.string().email("Enter valid email"),
        mobileNo: Yup.string()
          .min(10, "Mobile no must be at least 10 characters")
          .max(10, "Mobile no must be at least 10 characters"),
        authorizedSignatory: Yup.string(),
        boardMeetingDate: Yup.date(),
        modeOfOperation: Yup.string(),
        isSmo: Yup.string(),
        // passport: Yup.string().when('nationality', (nationality, Schema) =>
        //   nationality !== 'Indian' ? Schema,
        // ),
        // type: Yup.string(),
        // residentialStatus: Yup.string(),
        // DINNo: Yup.number(),
        // promoteTelephoneNo: Yup.string(),
        // promoteMobileNo: Yup.string(),
        addressLine2: Yup.string().max(250, "only 250 characters allowed"),
        // promotePinCode: Yup.string(),
        // promoteCity: Yup.string(),
        // district: Yup.string(),
        // promoteState: Yup.string(),
        // promoteCountry: Yup.string(),
        // dateOfAuthorization: Yup.date(),
        // title2: Yup.string(),
        // firstName2: Yup.string(),
        // middleName2: Yup.string(),
        // lastName2: Yup.string(),
      })
    ),
  });
const directorPromoterReqiredSchema = (key: string) =>
  Yup.object().shape({
    [key]: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Title is a required field"),
        firstName: Yup.string().required("First name is a required field"),
        middleName: Yup.string(),
        lastName: Yup.string().required("Last name is a required field"),
        gender: Yup.string().required("Gender is a required field"),
        dateOfBirth: Yup.date().required("Date of birth is a required field"),
        panNo: Yup.string()
          .required("Pan no is a required field")
          .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Enter valid pan no")
          .min(10, "Pan no must be at least 10 characters")
          .max(10, "Pan no must be at least 10 characters"),
        addressProof: Yup.string().required(
          "Address proof submitted is a required field"
        ),
        addressProofNo: Yup.string()
          .min(12, "Address proof number must be at least 12 characters")
          .max(12, "Address proof number must be at least 12 characters"),
        addressLine1: Yup.string(),
        city: Yup.string().required("City is a required field"),
        state: Yup.string().required("State is a required field"),
        pinCode: Yup.string()
          .required("Pin code is a required field")
          .min(6, "Pin code must be at least 6 characters")
          .max(6, "Pin code must be at least 6 characters"),
        country: Yup.string().required("Country is a required field"),
        nationality: Yup.string().required("Nationality is a required field"),
        designation: Yup.string().required("Designation is a required field"),
        emailID: Yup.string()
          .required("Email ID is a required field")
          .email("Enter valid email"),
        mobileNo: Yup.string()
          .required("Mobile no is a required field")
          .min(10, "Mobile no must be at least 10 characters")
          .max(10, "Mobile no must be at least 10 characters"),
        authorizedSignatory: Yup.string().required(
          "Authorised signatory is a required field"
        ),
        boardMeetingDate: Yup.date().when(
          "constitution",
          (constitution, Schema) =>
            constitution === "Private Limited"
              ? Schema.required("Board meeting date is a required field")
              : Schema
        ),
        modeOfOperation: Yup.string().when(
          "constitution",
          (constitution, Schema) =>
            constitution === "Private Limited" ||
            constitution === "Sole Proprietor" ||
            constitution === "LLP"
              ? Schema.required("Mode of operation is a required field")
              : Schema
        ),
        isSmo: Yup.string().when("constitution", (constitution, Schema) =>
          constitution === "Private Limited" ||
          constitution === "Public Limited"
            ? Schema.required("IS SMO is a required field")
            : Schema
        ),
        // passport: Yup.string().when('nationality', (nationality, Schema) =>
        //   nationality !== 'Indian' ? Schema.required('Passport is a required field') : Schema
        // ),
        // type: Yup.string().required('Type is a required field'),
        // residentialStatus: Yup.string().required('Residential status is a required field'),
        // DINNo: Yup.number().required('DIN no is a required field'),
        // promoteTelephoneNo: Yup.string().required('Telephone no is a required field').min(10, 'Telephone no must be at least 10 characters').max(10, 'Telephone no must be at least 10 characters'),
        // promoteMobileNo: Yup.string().required('Mobile no is a required field').min(10, 'Mobile no must be at least 10 characters').max(10, 'Mobile no must be at least 10 characters'),
        addressLine2: Yup.string(),
        // promotePinCode: Yup.string().required('Pin code is a required field').min(6, 'Pin code must be at least 6 characters').max(6, 'Pin code must be at least 6 characters'),
        // promoteCity: Yup.string().required('City is a required field'),
        // district: Yup.string().required('District is a required field'),
        // promoteState: Yup.string().required('State is a required field'),
        // promoteCountry: Yup.string().required('Country is a required field'),
        // dateOfAuthorization: Yup.date().required('Date of authorization is a required field'),
        // title2: Yup.string().required('Title is a required field'),
        // firstName2: Yup.string().required('First name is a required field'),
        // middleName2: Yup.string().required('Middle name is a required field'),
        // lastName2: Yup.string().required('Last name is a required field'),
      })
    ),
  });

const buyersDetails = Yup.object().shape({
  buyerDetails: Yup.array().of(
    Yup.object().shape({
      buyerName: Yup.string().nullable(), // these constraints take precedence
      buyerAddress: Yup.string().nullable(), // these constraints take precedence
    })
  ),
});
const buyersDetailsRequiredSchema = Yup.object().shape({
  buyerDetails: Yup.array().of(
    Yup.object().shape({
      buyerName: Yup.string().required("Buyer name is a required field"), // these constraints take precedence
      buyerAddress: Yup.string().required("Location is a required field"), // these constraints take precedence
    })
  ),
});

const shareHolderDetails = Yup.object().shape({
  shareholder: Yup.array().of(
    Yup.object({
      typeOfShareholder: Yup.string().required(
        "Type Of Shareholder is a required field"
      ),
      firstName: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema.matches(/^[a-zA-Z ]*$/, "Enter valid Name")
                .max(26, "only 26 characters allowed")
                .required("First Name is a required field")
            : Schema
      ),
      lastName: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema.matches(/^[a-zA-Z ]*$/, "Enter valid Name")
                .max(26, "only 26 characters allowed")
                .required("Last Name is a required field")
            : Schema
      ),
      middleName: Yup.string()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      nationality: Yup.string().required("Nationality is a required field"),
      dateOfBirth: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema.required("Date of Birth is a required field")
            : Schema
      ),
      dateOfIncorporation: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema
            : Schema.required("Date of Incorporation is a required field")
      ),
      address: Yup.string()
        .max(250, "only 250 characters allowed")
        .required("Address is a required field"),
      panDetails: Yup.string().matches(
        /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
        "Enter valid pan no"
      ),
      shareHolding: Yup.string().required("Shareholding is a required field"),
      companyName: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema
            : Schema.required("Company Name is a required field")
      ),
      gender: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema.required("Gender is a required field")
            : Schema
      ),
    })
  ),
});
// const shareHolderDetails = Yup.object().shape({
//   shareholder: Yup.array().of(
//     Yup.object({
//       typeOfShareholder: Yup.string(),
//       firstName: Yup.string()
//         .matches(/^[a-zA-Z ]*$/, 'Enter valid Name')
//         .max(26, 'only 26 characters allowed'),
//       lastName: Yup.string()
//         .matches(/^[a-zA-Z ]*$/, 'Enter valid Name')
//         .max(26, 'only 26 characters allowed'),
//       middleName: Yup.string()
//         .matches(/^[a-zA-Z ]*$/, 'Enter valid Name')
//         .max(26, 'only 26 characters allowed'),
//       nationality: Yup.string(),
//       dateOfBirth: Yup.date(),
//       address: Yup.string().max(250, 'only 250 characters allowed'),
//       panDetails: Yup.string().when('typeOfShareholder', (typeOfShareholder, Schema) =>
//       typeOfShareholder
//         ? Schema.matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Enter valid pan no')
//         : Schema.matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Enter valid pan no').required('Passport/ Voter ID/DL details is a required field')
//     ),
//       shareHolding: Yup.string(),
//       passport: Yup.string().when('typeOfShareholder', (typeOfShareholder, Schema) =>
//       typeOfShareholder === 'INDIVIDUAL'
//         ? Schema
//         : Schema.required('Passport/ Voter ID/DL details is a required field')
//     ),
//       companyName: Yup.string().matches(/[a-zA-Z ]*$/, 'Enter valid Name'),
//       // dateOfIncorporationShareholder: Yup.string().required('Date of in corporation is a required field'),
//     })
//   ),
// })
const shareHolderDetailsRequiredSchema = Yup.object().shape({
  shareholder: Yup.array().of(
    Yup.object({
      typeOfShareholder: Yup.string().required(
        "Type of shareholder is a required field"
      ),
      firstName: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema.required("First name is a required field")
            : Schema
      ),
      lastName: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema.required("Last name is a required field")
            : Schema
      ),
      middleName: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema.required("Middle name is a required field")
            : Schema
      ),
      nationality: Yup.string().required("Nationality is a required field"),
      dateOfBirth: Yup.date().required("Date of Birth is a required field"),
      address: Yup.string().required("Address is a required field"),
      panDetails: Yup.string()
        .required("Pan no is a required field")
        .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Enter valid pan no"),
      shareHolding: Yup.string().required("Shareholding is a required field"),
      passport: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "INDIVIDUAL"
            ? Schema.required(
                "Passport/ Voter ID/DL details is a required field"
              )
            : Schema
      ),
      companyName: Yup.string().when(
        "typeOfShareholder",
        (typeOfShareholder, Schema) =>
          typeOfShareholder === "ENTITY"
            ? Schema.required("Company name is a required field")
            : Schema
      ),
      // dateOfIncorporationShareholder: Yup.string().required('Date of in corporation is a required field'),
    })
  ),
});

const coverLetterDetailsSchema = (key: string) =>
  Yup.object({
    [key]: Yup.object().shape({
      billDiscountingFacility: Yup.string(),
      // dateOfAuthorization: Yup.date().when('constitution',
      //   (constitution, Schema) => constitution !== 'Sole Proprietor'
      //     ? Schema.required('Date is a required field')
      //     : Schema),
      latestListOfDirectorsr: Yup.string(),
      latestRegisteredAddress: Yup.string(),
      latestMailingAddress: Yup.string(),
      latestMemorandum: Yup.string(),
      latestArticleOfAssociation: Yup.string(),
      latestCertificateOfIncorp: Yup.string(),
      [key.toLowerCase().includes("partner")
        ? "majorCountriesOfOperation2"
        : "majorCountriesOfOperation"]: Yup.string().matches(
        /^[a-zA-Z, ]*$/,
        "Enter valid Name"
      ),
      [key.toLowerCase().includes("solo")
        ? "numberOfEmployees1"
        : key.toLowerCase().includes("partner")
        ? "numberOfEmployees2"
        : "numberOfEmployees"]: Yup.string(),
      expectedMonthlyTransaction: Yup.string(),
      certifiedEntityProof: Yup.string(),
      registrationCertificates: Yup.string(),
      saleIncomeTaxReturn: Yup.string(),
      "gst/cst/vatCertificate": Yup.string(),
      certificateIssuedByAuthorities: Yup.string(),
      licenseIssuedAuthority1: Yup.string(),
      certificateOfMuncipal: Yup.string(),
      complateSalesAndIncomeTaxReturn: Yup.string(),
      iecImportExportCode: Yup.string(),
      licenseIssuedAuthority: Yup.string(),
      registeredAddress: Yup.string(),
      utlityBills: Yup.string(),
      mailingAddressForAforesaid: Yup.string(),
      // dateOfPartnership: Yup.string().required(),
      // listOfPartners: Yup.string(),
      latestListOfPartners: Yup.string(),
      // boardMeetingDate: Yup.string().required(),
    }),
  });
const coverLetterDetailsSchemaRequired = (key: string) =>
  Yup.object({
    [key]: Yup.object().shape({
      billDiscountingFacility: Yup.string(),
      // dateOfAuthorization: Yup.date().when('constitution',
      //   (constitution, Schema) => constitution !== 'Sole Proprietor'
      //     ? Schema.required('Date is a required field')
      //     : Schema),
      latestListOfDirectorsr: Yup.string().when(
        "constitution",
        (constitution, Schema) =>
          constitution === "Private Limited" ||
          constitution === "Public Limited"
            ? Schema.oneOf(
                [true],
                "Latest List of Directors is a required field"
              )
            : Schema
      ),
      latestRegisteredAddress: Yup.string().when(
        "constitution",
        (constitution, Schema) =>
          constitution === "Private Limited" ||
          constitution === "Public Limited"
            ? Schema.oneOf(
                [true],
                "Latest Registered Address is a required field"
              )
            : Schema
      ),
      latestMailingAddress: Yup.string().when(
        "constitution",
        (constitution, Schema) =>
          constitution === "Private Limited" ||
          constitution === "Public Limited"
            ? Schema.oneOf([true], "Latest Mailing Address is a required field")
            : Schema
      ),
      latestMemorandum: Yup.string().when(
        "constitution",
        (constitution, Schema) =>
          constitution === "Private Limited" ||
          constitution === "Public Limited"
            ? Schema.oneOf(
                [true],
                "Latest Memorandum of Association (MOA) is a required field"
              )
            : Schema
      ),
      latestArticleOfAssociation: Yup.string().when(
        "constitution",
        (constitution, Schema) =>
          constitution === "Private Limited" ||
          constitution === "Public Limited"
            ? Schema.oneOf(
                [true],
                "Latest Article of Association (AOA) is a required field"
              )
            : Schema
      ),
      latestCertificateOfIncorp: Yup.string().when(
        "constitution",
        (constitution, Schema) =>
          constitution === "Private Limited" ||
          constitution === "Public Limited"
            ? Schema.oneOf(
                [true],
                "latest certificate of incorporation/ commencement of business is a required field"
              )
            : Schema
      ),
      majorCountriesOfOperation: Yup.string().when(
        "constitution",
        (constitution, Schema) =>
          constitution !== "LLP"
            ? Schema.required("Major countries is a required field")
            : Schema
      ),
      numberOfEmployees: Yup.string().when(
        "constitution",
        (constitution, Schema) =>
          constitution !== "LLP"
            ? Schema
            : // .required('Number of employees is a required field')
              Schema
      ),
      expectedMonthlyTransaction: Yup.string().when(
        "constitution",
        (constitution, Schema) =>
          constitution !== "LLP"
            ? Schema.required("Expected monthly is a required field")
            : Schema
      ),
      certifiedEntityProof: Yup.string(),
      registrationCertificates: Yup.string(),
      saleIncomeTaxReturn: Yup.string(),
      "gst/cst/vatCertificate": Yup.string(),
      certificateIssuedByAuthorities: Yup.string(),
      licenseIssuedAuthority1: Yup.string(),
      certificateOfMuncipal: Yup.string(),
      complateSalesAndIncomeTaxReturn: Yup.string(),
      iecImportExportCode: Yup.string().required(
        "Expected monthly is a required field"
      ),
      licenseIssuedAuthority: Yup.string(),
      registeredAddress: Yup.string(),
      utlityBills: Yup.string(),
      mailingAddressForAforesaid: Yup.string(),
      // dateOfPartnership: Yup.string().required(),
      // listOfPartners: Yup.string(),
      latestListOfPartners: Yup.string(),
      // boardMeetingDate: Yup.string().required(),
    }),
  });

const sikDetails = Yup.object().shape({
  sikDetails: Yup.array().of(
    Yup.object({
      nameOfCompany: Yup.string().nullable(),
      // .matches(/[a-zA-Z ]*$/, 'Enter valid Name'),
      vendorCode: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z0-9 ]*$/, "Enter valid Name"),
      place: Yup.string().nullable(),
      // .matches(/^[a-zA-Z ]*$/, 'Enter valid Name'),
      date: Yup.date().nullable(),
      createNewOrganization: Yup.boolean().nullable(),
      language: Yup.string().nullable(),
      approvalOrManuaDiscount: Yup.string().nullable(),
      address: Yup.string().nullable(),
      city: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      state: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      // province: Yup.string().nullable,
      postalCode: Yup.string()
        .nullable()
        .min(6, "must be at least 6 characters")
        .max(6, "must be at least 6 characters"),
      country: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
      telephone: Yup.string()
        .nullable()
        .min(10, "Telephone No must be at least 10 characters")
        .max(10, "Telephone No must be at least 10 characters"),
      // Input: Yup.boolean(),
      // Approval: Yup.boolean(),
      // Reporting: Yup.boolean(),
      // Viewing: Yup.boolean(),
      createNewUserId: Yup.boolean().nullable(),
      userId: Yup.string().nullable().email("Enter valid user id"),
      title: Yup.string().nullable(),
      firstName: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      middleName: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      lastName: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      shortName: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      address3: Yup.string().nullable().max(250, "only 250 characters allowed"),
      city1: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      state1: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      // province1: Yup.string().nullable,
      postalCode1: Yup.string()
        .nullable()
        .min(6, "must be at least 6 characters")
        .max(6, "must be at least 6 characters"),
      country1: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
      telephone1: Yup.string()
        .nullable()
        .min(10, "must be at least 10 characters")
        .max(10, "must be at least 10 characters"),
      bdSecureAuthentication: Yup.string().nullable(),
      bulkingDiscountPayment: Yup.string().nullable(),
      discountMethod: Yup.string().nullable(),
      allPayment: Yup.string().nullable(),
      nameAccountHolder: Yup.string().nullable(),
      // .matches(/^[a-zA-Z ]*$/, 'Enter valid Name'),
      nameOfBank: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid bank Name")
        .max(26, "only 26 characters allowed"),
      ifscCode: Yup.string()
        .nullable()
        .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Enter Valid ifsc code"),
      accountNumber: Yup.string()
        .nullable()
        .matches(/^[0-9-]*$/, "Enter valid Name"),
      currency: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z0-9-]*$/, "Enter valid Name"),
      nameOfCompany1: Yup.string()
        .nullable()
        .matches(/[a-zA-Z ]*$/, "Enter valid Name"),
      address1: Yup.string().nullable().max(250, "only 250 characters allowed"),
      city2: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      state2: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      // province2: Yup.string().nullable,
      postalCode2: Yup.string()
        .nullable()
        .min(6, "must be at least 6 characters")
        .max(6, "must be at least 6 characters"),
      country2: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
      telephone2: Yup.string()
        .nullable()
        .min(10, "must be at least 10 characters")
        .max(10, "must be at least 10 characters"),
      nameOfContactPerson: Yup.string().nullable(),
      // .matches(/^[a-zA-Z ]*$/, 'Enter valid Name'),
      email: Yup.string().nullable().email("Enter valid email"),
      companyNameorFormofOrganization: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
      address2: Yup.string().nullable().max(250, "only 250 characters allowed"),
      country3: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
      currency1: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z0-9-]*$/, "Enter valid Name"),
    })
  ),
});
const sikDetailsRequiredSchema = Yup.object().shape({
  sikDetails: Yup.array().of(
    Yup.object({
      nameOfCompany: Yup.string().required(
        "Name of company is a required field"
      ),
      vendorCode: Yup.string().required("Vendor code is a required field"),
      place: Yup.string().required("Place is a required field"),
      date: Yup.date().required("Date is a required field"),
      createNewOrganization: Yup.boolean().required(
        "Create new organization is a required field"
      ),
      language: Yup.string(),
      approvalOrManuaDiscount: Yup.string(),
      address: Yup.string().required("Address is a required field"),
      city: Yup.string().required("City is a required field"),
      state: Yup.string().required("State is a required field"),
      // province: Yup.string().required('Province is a required field'),
      postalCode: Yup.string()
        .required("Postal code is a required field")
        .min(6, "must be at least 6 characters")
        .max(6, "must be at least 6 characters"),
      country: Yup.string().required("Country is a required field"),
      telephone: Yup.string()
        .required("Telephone No is a required field")
        .min(10, "Telephone No must be at least 10 characters")
        .max(10, "Telephone No must be at least 10 characters"),
      // Input: Yup.boolean(),
      // Approval: Yup.boolean(),
      // Reporting: Yup.boolean(),
      // Viewing: Yup.boolean(),
      createNewUserId: Yup.boolean(),
      userId: Yup.string()
        .required("User ID is a required field")
        .email("Enter valid user id"),
      title: Yup.string().required("Title is a required field"),
      firstName: Yup.string().required("First name is a required field"),
      middleName: Yup.string(),
      lastName: Yup.string().required("Last name is a required field"),
      shortName: Yup.string().required("Short name is a required field"),
      address3: Yup.string().required("Address is a required field"),
      city1: Yup.string().required("City is a required field"),
      state1: Yup.string().required("State is a required field"),
      // provinceSIK: Yup.string().required('Province is a required field'),
      postalCode1: Yup.string()
        .required("Postal code is a required field")
        .min(6, "must be at least 6 characters")
        .max(6, "must be at least 6 characters"),
      country1: Yup.string().required("Country is a required field"),
      telephone1: Yup.string()
        .required("Telephone is a required field")
        .min(10, "must be at least 10 characters")
        .max(10, "must be at least 10 characters"),
      bdSecureAuthentication: Yup.string(),
      bulkingDiscountPayment: Yup.string().required(
        "Bulking of unDiscounted is a required field"
      ),
      discountMethod: Yup.string().required(
        "Discounting method is a required field"
      ),
      allPayment: Yup.string(),
      nameAccountHolder: Yup.string(),
      nameOfBank: Yup.string().required("Name of bank is a required field"),
      ifscCode: Yup.string().required("IFSC CODE is a required field"),
      accountNumber: Yup.string().required(
        "Account number is a required field"
      ),
      currency: Yup.string().required("Currency is a required field"),
      nameOfCompany1: Yup.string().required(
        "Name of company is a required field"
      ),
      address1: Yup.string().required("Address is a required field"),
      city2: Yup.string().required("City is a required field"),
      state2: Yup.string().required("State is a required field"),
      // province2: Yup.string().required('Province is a required field'),
      postalCode2: Yup.string()
        .required("Postal code is a required field")
        .min(6, "must be at least 6 characters")
        .max(6, "must be at least 6 characters"),
      country2: Yup.string().required("Country is a required field"),
      telephone2: Yup.string()
        .required("Telephone is a required field")
        .min(10, "must be at least 10 characters")
        .max(10, "must be at least 10 characters"),
      nameOfContactPerson: Yup.string(),
      email: Yup.string().email("Enter valid email"),
      companyNameorFormofOrganization: Yup.string().required(
        "Company name and form is a required field"
      ),
      address2: Yup.string().required("Address is a required field"),
      country3: Yup.string().required("Country is a required field"),
      currency1: Yup.string().required("Currencies is a required field"),
    })
  ),
});

const EOI = Yup.object({
  EOI: Yup.object({
    bankName: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
      .max(26, "only 26 characters allowed"),
    bankAddress: Yup.string().max(250, "only 250 characters allowed"),
    typeOfFacility: Yup.string().matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
    amount: Yup.string(),
    nameOfCompany: Yup.string(),
  }),
});
const EOIRequired = Yup.object({
  EOI: Yup.object({
    bankName: Yup.string().required("Bank name is a required field"),
    bankAddress: Yup.string().required("Bank address is a required field"),
    typeOfFacility: Yup.string().required(
      "Type of facility is a required field"
    ),
    amount: Yup.string().required("Amount is a required field"),
    nameOfCompany: Yup.string().required("Company name is a required field"),
  }),
});

const authorizeSignatory = Yup.object({
  authorizeSignatory: Yup.array().of(
    Yup.object({
      title: Yup.string(),
      firstName: Yup.string()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      middleName: Yup.string()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      lastName: Yup.string()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      nationality: Yup.string(),
      designation: Yup.string(),
      dateOfBirth: Yup.date(),
      mobileNo: Yup.string()
        .min(10, "Mobile no must be at least 10 characters")
        .max(10, "Mobile no must be at least 10 characters"),
      addressLine1: Yup.string().max(250, "only 250 characters allowed"),
      addressLine2: Yup.string().max(250, "only 250 characters allowed"),
      pinCode: Yup.string()
        .min(6, "Pin code must be at least 6 characters")
        .max(6, "Pin code must be at least 6 characters"),
      city: Yup.string()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      district: Yup.string()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      State: Yup.string()
        .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
        .max(26, "only 26 characters allowed"),
      country: Yup.string(),
      passportNumber: Yup.string(),
      panNo: Yup.string().matches(
        /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
        "Enter valid pan no"
      ),
      // panNoAuthorised: Yup.string().required().matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Enter valid pan no'),
      // typeAuthorised: Yup.string().required('Type is a required field'),
      // modeOfOperationAuthorized: Yup.string().required('Mode of operation is a required field'),
      // emailIDAuthorised: Yup.string().email().required('Email ID is a required field'),
      // residentialStatusAuthorised: Yup.string().required('Residential status is a required field'),
      // DINNoAuthorised: Yup.string().required('DIN no is a required field'),
      // promoteTelephoneNoAuthorised: Yup.string().required('Telephone no is a required field').min(10, 'Telephone no must be at least 10 characters').max(10, 'Telephone no must be at least 10 characters'),
      // authorisedSignatoryAuthorised: Yup.string().required('Authorised signatory is a required field'),
      // dateOfAuthorizationAuthorised: Yup.date().required('Date of authorization is a required field'),
      // addressProofSubmittedAuthorised: Yup.string().required('Address proof submitted is a required field'),
      // addressProofNumberAuthorised: Yup.number().required('Address proof number is a required field'),
      // title2Authorised: Yup.string().required('Title is a required field'),
      // firstName2Authorised: Yup.string().required('First name is a required field'),
      // middleName2Authorised: Yup.string().required('Middle name is a required field'),
      // lastName2Authorised: Yup.string().required('Last name is a required field'),
    })
  ),
});
const authorizeSignatoryRequiredSchema = Yup.object({
  authorizeSignatory: Yup.array().of(
    Yup.object({
      title: Yup.string().required("Title is a required field"),
      firstName: Yup.string().required("First name is a required field"),
      middleName: Yup.string().required("Middle name is a required field"),
      lastName: Yup.string().required("Last name is a required field"),
      nationality: Yup.string().required("Nationality is a required field"),
      designation: Yup.string().required("Designation is a required field"),
      dateOfBirth: Yup.date().required("Date of birth is a required field"),
      mobileNo: Yup.string()
        .required("Mobile no is a required field")
        .min(10, "Mobile no must be at least 10 characters")
        .max(10, "Mobile no must be at least 10 characters"),
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      pinCode: Yup.string()
        .required("Pin code is a required field")
        .min(6, "Pin code must be at least 6 characters")
        .max(6, "Pin code must be at least 6 characters"),
      city: Yup.string().required("City is a required field"),
      district: Yup.string(),
      State: Yup.string().required("State is a required field"),
      country: Yup.string().required("Country is a required field"),
      passportNumber: Yup.string(),
      //for referring
      // .when("nationality", (nationality, Schema) =>
      //   nationality !== "Indian"
      //     ? Schema.required("Passport is a required field")
      //     : Schema
      // )
      panNo: Yup.string().when("nationality", (nationality, Schema) =>
        nationality === "Indian"
          ? Schema.required("Pan no is a required field")
              .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Enter valid pan no")
              .min(10, "Pan no must be at least 10 characters")
              .max(10, "Pan no must be at least 10 characters")
          : Schema
      ),
      // panNoAuthorised: Yup.string().required().matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Enter valid pan no'),
      // typeAuthorised: Yup.string().required('Type is a required field'),
      // modeOfOperationAuthorized: Yup.string().required('Mode of operation is a required field'),
      // emailIDAuthorised: Yup.string().email().required('Email ID is a required field'),
      // residentialStatusAuthorised: Yup.string().required('Residential status is a required field'),
      // DINNoAuthorised: Yup.string().required('DIN no is a required field'),
      // promoteTelephoneNoAuthorised: Yup.string().required('Telephone no is a required field').min(10, 'Telephone no must be at least 10 characters').max(10, 'Telephone no must be at least 10 characters'),
      // authorisedSignatoryAuthorised: Yup.string().required('Authorised signatory is a required field'),
      // dateOfAuthorizationAuthorised: Yup.date().required('Date of authorization is a required field'),
      // addressProofSubmittedAuthorised: Yup.string().required('Address proof submitted is a required field'),
      // addressProofNumberAuthorised: Yup.number().required('Address proof number is a required field'),
      // title2Authorised: Yup.string().required('Title is a required field'),
      // firstName2Authorised: Yup.string().required('First name is a required field'),
      // middleName2Authorised: Yup.string().required('Middle name is a required field'),
      // lastName2Authorised: Yup.string().required('Last name is a required field'),
    })
  ),
});

const createAccountSchemas = (constitution: any = "", isSubmit?: boolean) => {
  const directorPromoterInfo: any =
    constitution === "Sole Proprietor"
      ? "soloProprietor"
      : constitution === "Private Limited"
      ? "privateLimited"
      : constitution === "Public Limited"
      ? "privateLimited"
      : constitution === "Partnership"
      ? "partnership"
      : constitution === "LLP"
      ? "LLP"
      : "directorPromoterInfo";

  const coverLetterDetails =
    constitution === "Sole Proprietor"
      ? "coverLetterForSoloProprietor"
      : constitution === "Private Limited"
      ? "coverLetterPrivateAndPublic"
      : constitution === "Public Limited"
      ? "coverLetterPrivateAndPublic"
      : constitution === "Partnership"
      ? "coverLetterForPartnership"
      : constitution === "LLP"
      ? "coverLetterForLlp"
      : "coverLetterForSoloProprietor";

  const documents =
    constitution === "Sole Proprietor"
      ? "soloProprietorDoc"
      : constitution === "Private Limited"
      ? "privateLimitedDoc"
      : constitution === "Public Limited"
      ? "privateLimitedDoc"
      : constitution === "Partnership"
      ? "partnershipDeepDoc"
      : "LLPDoc";

  const templates =
    constitution === "Sole Proprietor"
      ? "templateSole"
      : constitution === "Private Limited"
      ? "templatePrivateandpublic"
      : constitution === "Public Limited"
      ? "templatePrivateandpublic"
      : constitution === "Partnership"
      ? "templatePartnership"
      : "templateLlp";

  return [
    // Business Info
    isSubmit
      ? businessInfoSchema.concat(businessInfoRequiredSchema.nullable())
      : businessInfoSchema,
    // Buyer Details
    buyersDetailsRequiredSchema,
    // Compony Address Detail
    companyAddressDetailSchema,
    //Director/Promoter/Proprietor/Partners
    directorPromoter(directorPromoterInfo, constitution),
    //Shareholder Details
    shareHolderDetails,
    //Cover Letter Details
    coverLetterDetailsSchema(coverLetterDetails),

    //SIK Details
    sikDetails,
    //Supplier Agreement Details
    Yup.object({
      supplierAgreementDetails: Yup.object({
        nameOfCompany: Yup.string(),
        address: Yup.string().max(250, "only 250 characters allowed"),
        pinCode: Yup.string()
          .min(6, "must be at least 6 characters")
          .max(6, "must be at least 6 characters"),
        countryCode: Yup.string(),
        citySupplier: Yup.string().max(26, "only 26 characters allowed"),
        date: Yup.date(),
        place: Yup.string(),
        nameOfCompany1: Yup.string(),
        companyPanDetails: Yup.string()
          .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Enter valid pan no")
          .min(10, "Pan no must be at least 10 characters")
          .max(10, "Pan no must be at least 10 characters"),
        address1: Yup.string().max(250, "only 250 characters allowed"),
        date1: Yup.date(),
        nameOfCompany2: Yup.string(),
        date2: Yup.date(),
        witnessName1: Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
          .max(26, "only 26 characters allowed"),
        witnessName: Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Enter valid Name")
          .max(26, "only 26 characters allowed"),
        date3: Yup.date(),
        nameOfCompany3: Yup.string(),
        place1: Yup.string().matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
        buyerName: Yup.string().matches(/^[a-zA-Z ]*$/, "Enter valid Name"),
        buyerAddress: Yup.string(),
      }),
    }),
    //EOI
    EOI,
    //Authorized Signatory
    authorizeSignatory,

    Yup.object({
      [documents]: Yup.object({
        cancelledCheque: Yup.string(),
        companyPanCard: Yup.string(),
        eitherOfPassportVoterIdDrivingLicence: Yup.string(),
        gstRegistrationCertificateUdyogAadharCertificate: Yup.string(),
        mailingAddressProof: Yup.string(),
        "panCardOfAuthorizedSignatory&Shareholder": Yup.string(),
        partnershipDeep: Yup.string(),
        nameMismatchLetter: Yup.string(),
        nationalityMismatchLetter: Yup.string(),
        noUboDeclaration: Yup.string(),
        pinCodeConfirmationLetter: Yup.string(),
        pincodeMismatchLetter: Yup.string(),
      }),
    }),
    Yup.object({
      [templates]: Yup.object({
        cancelledCheque: Yup.string(),
        companyPanCard: Yup.string(),
        eitherOfPassportVoterIdDrivingLicence: Yup.string(),
        gstRegistrationCertificateUdyogAadharCertificate: Yup.string(),
        mailingAddressProof: Yup.string(),
        "panCardOfAuthorizedSignatory&Shareholder": Yup.string(),
        partnershipDeep: Yup.string(),
        nameMismatchLetter: Yup.string(),
        nationalityMismatchLetter: Yup.string(),
        noUboDeclaration: Yup.string(),
        pinCodeConfirmationLetter: Yup.string(),
        pincodeMismatchLetter: Yup.string(),
      }),
    }),

    //Confirmation Letter
    // Yup.object({
    //   nameConfirmation: Yup.string().required('Name is a required field'),
    //   addressConfirmation: Yup.string().required('Address is a required field'),
    //   mobileNumber: Yup.string().required('Mobile number is a required field').min(10, 'Mobile number must be at least 10 characters').max(10, 'Mobile number must be at least 10 characters'),
    //   EmailIDConfirmation: Yup.string().required(' is a required field').email(),
    //   nameOfCompanyConfirmation: Yup.string().required('Name of company is a required field'),
    //   addressConfirmation2: Yup.string().required('Address is a required field'),
    //   contactNumber: Yup.string().required('Contact number is a required field').min(10, 'Contact number must be at least 10 characters').max(10, 'Contact number must be at least 10 characters'),
    //   emailIDConfirmation: Yup.string().required('Email ID is a required field'),
    // }),
    // //List of Other Documents
    // Yup.object({
    //   accountType: Yup.string().required(),
    // }),
  ];
};

const inits: ICreateAccount = {
  // Business Info
  businessInfo: {
    // applicationDate: '09-03-2023',
    gstNumber: "",
    nameOfCompany: "",
    constitution: "",
    businessSector: "",
    cinNo: "",
    nameOfEntityAsPerMca: "",
    panNo: "",
    currency: "INR",
    salesTurnover: "",
    nameOfContactPerson: "",
    emailId: "",
    briefDescriptionOfNatureOfBusiness: "",
    dateOfIncorporation: "",
    balanceSheetTotalForLastAvailableYear: "",
    contactNo: "",
    remarks: "",
    comments: "",
    additionalInfo: "",
    others: "",
    // customerType: '',
    // nameOfApplication: string
    // industry: string
    // yearInBusiness: string
  },

  // Buyer Details
  buyerDetails: [
    {
      buyerName: "",
      buyerAddress: "",
      // location: '',
      // turnover: '',
      // yearOfRelationship: '',
      // billDiscounting: '',
    },
  ],

  //Compony Address Detail
  companyAddressDetails: {
    address: "",
    pinCode: "",
    state: "",
    telephoneNo: "",
    city: "",
    country: "",
    mobileNo: "",
    addressRD: "",
    pinCodeRD: "",
    cityRD: "",
    telephoneNoRD: "",
    mobileNoRD: "",
    stateRD: "",
    countryRD: "",
    registeredAddress: "",
    addressProof: "",
  },

  //Director/Promoter/Proprietor/Partners
  directorPromoterInfo: [
    {
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      dateOfBirth: "",
      panNo: "",
      addressProof: "",
      addressProofNo: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pinCode: "",
      country: "",
      nationality: "",
      designation: "",
      emailID: "",
      mobileNo: "",
      authorizedSignatory: "",
      boardMeetingDate: "",
      modeOfOperation: "",
      isSmo: "",
      passport: "",
      // telephoneNo: '',
      // cityRD: '',
      // telephoneNoRD: '',
      // mobileNoRD: '',
      // stateRD: '',
      // countryRD: '',
      // promoteMobileNo: '',
      // promotePinCode: '',
      // promoteCity: '',
      // promoteState: '',
      // promoteCountry: '',
      // dateOfAuthorization: '',
      // addressRD: '',
      // type: '',
      // residentialStatus: '',
      // DINNo: '',
      // promoteTelephoneNo: '',
      // district: '',
      // title2: '',
      // firstName2: '',
      // middleName2: '',
      // lastName2: '',
    },
  ],
  //partnershipDeepDoc
  partnershipDeepDoc: {
    cancelledCheque: "",
    companyPanCard: "",
    eitherOfPassportVoterIdDrivingLicence: "",
    gstRegistrationCertificateUdyogAadharCertificate: "",
    mailingAddressProof: "",
    "panCardOfAuthorizedSignatory&Shareholder": "",
    partnershipDeep: "",
  },
  //Shareholder Details
  shareholder: [
    {
      typeOfShareholder: "",
      firstName: "",
      lastName: "",
      middleName: "",
      nationality: "",
      dateOfBirth: "",
      address: "",
      panDetails: "",
      shareHolding: "",
      passport: "",
      companyName: "",
      // dateOfIncorporationShareholder: '',
    },
  ],

  //Cover Letter Details
  coverLetterDetails: {
    billDiscountingFacility: "",
    //private
    dateOfAuthorization: "",
    latestListOfDirectorsr: false,
    latestRegisteredAddress: false,
    latestMailingAddress: false,
    latestMemorandum: false,
    latestArticleOfAssociation: false,
    latestCertificateOfIncorp: false,
    majorCountriesOfOperation: "",
    numberOfEmployees: "",
    expectedMonthlyTransaction: "",
    // boardResolution: string
    //sole
    certifiedEntityProof: false,
    registrationCertificates: false,
    utlityBills: false,
    complateSalesAndIncomeTaxReturn: false,
    saleIncomeTaxReturn: false,
    licenseIssuedAuthority: false,
    "gst/cst/vatCertificate": false,
    certificateIssuedByAuthorities: false,
    licenseIssuedAuthority1: false,
    certificateOfMuncipal1: false,
    certificateOfMuncipal: false,
    registeredAddress: false,
    mailingAddressForAforesaid: false,
    iecImportExportCode: false,
    // Partnership
    dateOfPartnership: "",
    // listOfPartners: '',
    latestListOfPartners: false,
    // LLP
    boardMeetingDate: "",
  },

  // sik Details
  sikDetails: [
    {
      nameOfCompany: "",
      vendorCode: "",
      place: "",
      date: "",
      createNewOrganization: false,
      language: "English",
      approvalOrManuaDiscount: "",
      address: "",
      city: "",
      state: "",
      // province: '',
      city1: "",
      state1: "",
      // province1: '',
      postalCode: "",
      country: "",
      telephone: "",
      // Input: false,
      // Approval: false,
      // Reporting: false,
      // Viewing: false,
      createNewUserId: true,
      userId: "",
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      shortName: "",
      address3: "",
      city2: "",
      state2: "",
      // province2: '',
      postalCode1: "",
      country1: "",
      telephone1: "",
      bdSecureAuthentication: false,
      bulkingDiscountPayment: false,
      bulkUndiscountPayment: false,
      discountMethod: "",
      allPayment: false,
      nameAccountHolder: "",
      nameOfBank: "",
      ifscCode: "",
      accountNumber: "",
      currency: "",
      nameOfCompany1: "",
      address1: "",
      // citySIK3: '',
      // stateSIK3: '',
      // provinceSIK2: '',
      postalCode2: "",
      country2: "",
      telephone2: "",
      nameOfContactPerson: "",
      email: "",
      companyNameorFormofOrganization: "",
      address2: "",
      country3: "INDIA",
      currency1: "INR",
      supplierUserInput: false,
      // supplierUserApproval: false,
      // supplierUserReporting: false,
      // supplierUserViewing: false,
    },
  ],

  //Supplier Agreement Details
  supplierAgreementDetails: {
    nameOfCompany: "",
    address: "",
    pinCode: "",
    countryCode: "",
    citySupplier: "",
    date: "",
    place: "",
    nameOfCompany1: "",
    companyPanDetails: "",
    address1: "",
    date1: "",
    nameOfCompany2: "",
    date2: "",
    witnessName1: "",
    witnessName: "",
    date3: "",
    nameOfCompany3: "",
    place1: "",
    buyerName: "",
    buyerAddress: "",
  },

  //Authorized Signatory
  authorizeSignatory: [
    {
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      nationality: "",
      designation: "",
      dateOfBirth: "",
      mobileNo: "",
      addressLine1: "",
      addressLine2: "",
      pinCode: "",
      city: "",
      district: "",
      State: "",
      country: "",
      panNo: "",
      passportNumber: "",
      // typeAuthorised: '',
      // modeOfOperationAuthorized: '',
      // emailIDAuthorised: '',
      // residentialStatusAuthorised: '',
      // DINNoAuthorised: '',
      // promoteTelephoneNoAuthorised: '',
      // authorisedSignatoryAuthorised: '',
      // dateOfAuthorizationAuthorised: '',
      // addressProofSubmittedAuthorised: '',
      // addressProofNumberAuthorised: '',
      // title2Authorised: '',
      // firstName2Authorised: '',
      // middleName2Authorised: '',
      // lastName2Authorised: '',
    },
  ],

  EOI: {
    bankName: "",
    bankAddress: "",
    typeOfFacility: "",
    amount: 0,
    nameOfCompany: "",
  },
  //Confirmation Letter
  // nameConfirmation: '',
  // addressConfirmation: '',
  // mobileNumber: '',
  // EmailIDConfirmation: '',
  // nameOfCompanyConfirmation: '',
  // addressConfirmation2: '',
  // contactNumber: '',
  // emailIDConfirmation: '',

  //List of Other Documents
  //...
  // accountType: 'personal',
};

export { createAccountSchemas, inits };
