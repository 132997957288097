import axios from "axios";
import { URLS } from "./URLS";

// export const baseURL: string = "https://supplychain.scymesglobal.com";
export const baseURL: string = "https://scymesapi.catseyesystems.com";
// export const baseURL: string = 'http://localhost:80'

export const axiosDefaultHeader = (token: any) =>
  (axios.defaults.headers.common["Authorization"] = `Bearer ${token}`);

export const getModeOperation = () => axios.get(`${baseURL}${URLS.GET_STATUS}`);

export const getConstitution = () =>
  axios.get(`${baseURL}${URLS.CONSTITUTION}`);

export const setConstitutionValue = (appId: string, constitutionCode: string) =>
  axios.get(
    `${baseURL}${URLS.SET_CONSTITUTION}?appId=${appId}&constitutionValue=${constitutionCode}`
  );

export const getSector = () => axios.get(`${baseURL}${URLS.SECTOR}`);

export const getBuyer = () => axios.get(`${baseURL}${URLS.BUYER}`);

export const getAssigneeList = () =>
  axios.get(`${baseURL}${URLS.GET_ASSIGNEE}`);

export const getCurrentAssignee = (appId: string) =>
  axios.post(`${baseURL}${URLS.GET_SELECTED_ASSIGNEE}?appId=${appId}`);

export const updateAssignee = (appId: string, data: any) =>
  axios.post(`${baseURL}${URLS.UPDATE_ASSIGNEE}?appId=${appId}`, {
    assignees: [data],
  });

export const checkGSTIN = (gstinNumber: string) =>
  axios.post(`${baseURL}${URLS.CHECK_GSTIN}`, { gstinNumber });

export const checkPAN = (panNumber: string) =>
  axios.post(`${baseURL}${URLS.CHECK_PAN}`, { panNumber });

export const getDesignation = () => axios.get(`${baseURL}${URLS.DESIGNATION}`);

export const getCountry = () => axios.get(`${baseURL}${URLS.COUNTRY}`);

export const getAddressProofDocument = () =>
  axios.get(`${baseURL}${URLS.ADDRESS_PROOF_DOCUMENT}`);

export const getApplication = (appId: string) =>
  axios.get(`${baseURL}${URLS.APPLICATION}?appId=${appId}`);

export const saveApplication = (
  data: any,
  collectionName: string,
  appId: string
) =>
  axios.post(
    `${baseURL}${URLS.SAVE_APPLICATION}?appId=${appId}&collectionName=${collectionName}`,
    data
  );

export const getpinApi = (
  data: any,
  collectionName: string,
  pincode: number,
  collectionId: number
) =>
  axios.post(
    `${baseURL}${URLS.GETPINCODE}?pincode=${pincode}&collectionName=${collectionName}&collectionId=${collectionId}`,
    data
  );

export const getpanApi = (
  data: any,
  collectionName: string,
  pancard: any,
  collectionId: number
) =>
  axios.post(
    `${baseURL}${URLS.CHECK_PAN}?pancard=${pancard}&collectionName=${collectionName}&collectionId=${collectionId}`,
    data
  );

export const submitApplication = (data: any, appId: string) =>
  axios.post(`${baseURL}${URLS.SUBMIT_APPLICATION}?appId=${appId}`, data);

export const postLogin = (data: any) =>
  axios.post(`${baseURL}${URLS.POSTLOGIN}`, data);

export const postSendOTP = (data: any) =>
  axios.post(`${baseURL}${URLS.POSTSENDOTP}`, data);

export const getApplicationSummary = () =>
  axios.post(`${baseURL}${URLS.APPLICATION_SUMMARY}`);

export const uploadDocument = (data: any) =>
  axios.post(`${baseURL}${URLS.UPLOAD_DOCUMENT}`, data);

export const forgetPassword = (data: any) =>
  axios.post(`${baseURL}${URLS.FORGET_PASSWORD}`, data);

export const resetPassword = (data: any) =>
  axios.post(`${baseURL}${URLS.RESET_PASSWORD}`, data);

export const deleteForm = (data: any, collectionName: string, appId: string) =>
  axios.post(
    `${baseURL}${URLS.DELETE_FORM}?appId=${appId}&collectionCode=${collectionName}`,
    data
  );

export const documentComment = (data: any) =>
  axios.post(`${baseURL}${URLS.DOCUMENT_COMMENT}`, data);

// Admin

export const adminLogin = (data: any) =>
  axios.post(`${baseURL}${URLS.ADMIN_LOGIN}`, data);

export const getAdminApplicationSummary = (data: object) =>
  axios.post(`${baseURL}${URLS.ADMIN_APPLICATION_SUMMARY}`, data);

export const createSupplier = (data: any) =>
  axios.post(`${baseURL}${URLS.CREATE_SUPPLIER}`, data);

export const getStatusAndComments = (appId: string) =>
  axios.get(`${baseURL}${URLS.STATUS_AND_COMMENTS}?appId=${appId}`);

export const createStatusAndComments = (data: any) =>
  axios.post(`${baseURL}${URLS.STATUS_AND_COMMENTS}`, data);

export const getRefreshToken = (token: any) =>
  axios.get(`${baseURL}${URLS.AUTH_REFRESH_TOKEN}`, {
    headers: { isRefreshToken: token },
  });

export const getOtherDocument = () =>
  axios.get(`${baseURL}${URLS.OTHER_DOCUMENT}`);

export const addOtherDocument = (
  appId: string,
  collectionName: string,
  data: any
) =>
  axios.post(
    `${baseURL}${URLS.ADD_DOCUMENT}?appId=${appId}&collectionName=${collectionName}`,
    data
  );

export const getApplicationSummaryWithAppId = (appId: string) =>
  axios.post(`${baseURL}${URLS.APPLICATION_SUMMARY}?appId=${appId}`);

export const getUpdateStatus = () =>
  axios.get(`${baseURL}${URLS.GET_UPDATE_STATUS}`);

export const misReport = (data: any) =>
  axios.post(`${baseURL}${URLS.GET_REPORT}`, data, { responseType: "blob" });
