import { useEffect, useRef, useState } from "react";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import {
  toastError,
  toastSuccess,
} from "./../../../../_metronic/helpers/ToastifyHelper";
import { ErrorMessage, Field, FieldArray, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  checkGSTIN,
  createSupplier,
  getAssigneeList,
  getBuyer,
} from "../../../API/api";
import { MultiDropdown } from "./../../../../_metronic/layout/components/MultiDropdown";
import { useNavigate } from "react-router-dom";
import { cacheValidation } from "../../../../_metronic/helpers/ArrayHelper";
import { Dropdowns } from "../../../../_metronic/layout/components/Dropdowns";

const initial = {
  gstnNumber: "30AAACR5055K1ZK",
  companyName: "",
  buyerName: [],
  contactNumber: "2165465412",
  contactPerson: "test",
  emailAddress: "test@mailinator.com",
  comments: "test",
  newBuyers: [],
  assignees: [],
};

const validationSchema = Yup.object({
  gstnNumber: Yup.string()
    .required("GST no is a required field")
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter valid GST no (ex. 12ABCDE1234A1Z1)"
    ),
  companyName: Yup.string().required("Compony Name is a required field"),
  buyerName: Yup.array().when("newBuyers", (newBuyers, Schema) =>
    newBuyers.length > 0
      ? Schema
      : Schema.min(1, "Buyer Name is required field")
        .required("Buyer Name is required field")
        .nullable()
  ),
  contactNumber: Yup.string()
    .required("Contact no is a required field")
    .min(10, "must be at least 10 characters")
    .max(10, "must be at least 10 characters"),
  contactPerson: Yup.string().required(
    "Contact Person Name is a required field"
  ),
  emailAddress: Yup.string()
    .required("Email Address is a required field")
    .email("Email Address must be a valid email"),
  newBuyers: Yup.array().of(
    Yup.object({
      buyerName: Yup.string().required("Buyer name is a required field"),
      buyerAddress: Yup.string().required("Buyer address is a required field"),
    })
  ),
});
export default function SupplierRegistration(): JSX.Element {
  const [initialValues, setInitialValues] = useState<object>(initial);
  const [buyers, setBuyers] = useState<any[]>([]);
  const [assignees, setAssignees] = useState<any[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedAssignee != "" || selectedAssignee != null) {
      assignees?.map((assignee: any) => {
        if (assignee?.name == selectedAssignee) {
          setInitialValues({
            ...initialValues,
            contactNumber: assignee?.contactNumber,
          });
        }
      });
    }
  }, [selectedAssignee]);

  useEffect(() => {
    getBuyer()
      .then((res) => {
        setBuyers(res.data);
      })
      .catch((err) => {
        toastError(err.response?.data?.error || err.response?.data);
      });
  }, []);

  const getAsigneeApiCall = () => {
    getAssigneeList()
      .then((res) => {
        setAssignees(res?.data?.data);
      })
      .catch((err) => {
        toastError(err?.response?.data?.error || err?.response?.data);
      });
  };

  useEffect(() => {
    getAsigneeApiCall();
  }, []);

  const createSupplierHandler = (values: any, actions: any) => {
    console.log("value", values);
    setLoading(true);

    let selectedAssigneeObject = {};

    assignees?.map((assignee: any) => {
      if (assignee?.name == selectedAssignee) {
        selectedAssigneeObject = assignee;
      }
    });

    createSupplier({
      ...values,
      buyerName: values.buyerName.map((buyer: any) => buyer.value),
      assignees: [selectedAssigneeObject],
    })
      .then((res) => {
        setLoading(false);
        if (res.data.status === "SUCCESS") {
          actions.resetForm();
          toastSuccess(res.data.message);
          navigate("/summary");
        } else toastError(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        toastError(err.response?.data?.error || err.response?.data);
      });
  };

  const handleSelectBuyer = (options: any, setFieldValue: any) => {
    setFieldValue("buyerName", options);
  };

  const handleContactNumberChange = (options: any, setFieldValue: any) => {
    setFieldValue("contactNumber", options);
  };

  const handleGSTINChange = cacheValidation(
    (value: string, { setFieldValue }: FormikProps<any>) => {
      return checkGSTIN(value!).then((res) => {
        if (res.data.status !== "SUCCESS") {
          setFieldValue("companyName", "");
          return "Enter valid GST no (ex. 12ABCDE1234A1Z1)";
        }
        setFieldValue("companyName", res.data.data);
        return "";
      });
    }
  );

  return (
    <div className="card p-5 w-100 mt-15">
      <h3 className="border-bottom p-2">New Supplier Registration</h3>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          createSupplierHandler(values, actions);
        }}
        render={(props: any) => {
          console.log("props in 176", props?.values);
          return (
            <>
              <div className="row g-3">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <Inputs
                    required={true}
                    label="GST of Registered Office"
                    type="text"
                    validate={(value: string) =>
                      handleGSTINChange(value, { ...props, key: "gstnNumber" })
                    }
                    placeHolder="Enter Your GST No"
                    name="gstnNumber"
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <Inputs
                    required={true}
                    label="Company Name"
                    type="text"
                    placeHolder="Enter Your Company Name"
                    name="companyName"
                  // value={props?.values?.companyName}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <MultiDropdown
                    required={true}
                    label="Buyer Name"
                    name="buyerName"
                    value={props.values.buyerName}
                    onChange={(options: any) =>
                      handleSelectBuyer(options, props.setFieldValue)
                    }
                    options={buyers.map((buyer) => ({
                      value: buyer.buyerCode,
                      label: buyer.buyerName,
                    }))}
                    error={props.errors?.buyerName}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <Inputs
                    required={true}
                    label="Contact Number"
                    type="number"
                    placeHolder="Enter Contact No*"
                    name="contactNumber"
                  // disabled={true}
                  // value={props?.values?.contactNumber}
                  />
                </div>
                <FieldArray
                  name="newBuyers"
                  render={(d) => (
                    <div>
                      <button
                        type="button"
                        onClick={() =>
                          d.push({ buyerName: "", buyerAddress: "" })
                        }
                        className="btn btn-primary me-4 btn-sm"
                      >
                        Add Buyer
                      </button>
                      {props.values.newBuyers.map((p: any, index: any) => {
                        return (
                          <div className="row g-3" key={index}>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                              <Inputs
                                required={true}
                                label={`Buyer Name ${props.values.newBuyers.length > 1
                                  ? index + 1
                                  : ""
                                  }`}
                                type="text"
                                placeHolder="Enter Buyer Name"
                                name={`newBuyers[${index}].buyerName`}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                              <Inputs
                                required={true}
                                label={`Buyer Address ${props.values.newBuyers.length > 1
                                  ? index + 1
                                  : ""
                                  }`}
                                type="text"
                                placeHolder="Enter Your Buyer Address"
                                name={`newBuyers[${index}].buyerAddress`}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                />
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <Inputs
                    required={true}
                    label="Contact Person Name"
                    type="text"
                    placeHolder="Enter Contact Person Name*"
                    name="contactPerson"
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <Inputs
                    required={true}
                    label="Email Address"
                    type="email"
                    placeHolder="Enter Email Address"
                    name="emailAddress"
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <Inputs
                    required={false}
                    label="Comments"
                    type="text"
                    placeHolder="Enter Comments"
                    name="comments"
                  />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                  {/* <Dropdowns
                    required={true}
                    label="Assignee Name"
                    name="AssigneeName"
                    // onChange={(options: any) => {
                    //   setSelectedAssignee(options?.target?.value);
                    //   assignees?.map((assignee: any) => {
                    //     if (assignee?.name == options?.target?.value) {
                    //       props?.setFieldValue("Assignee Contact Number", assignee?.contactNumber);
                    //     }
                    //   });
                    // }}
                    options={assignees?.map((assignee) => assignee?.name)}
                  // error={props.errors?.buyerName}
                  /> */}

                  <div>
                    <label className="col-form-label required">
                      Assignee Name
                    </label>
                    <Field
                      disabled={false}
                      as="select"
                      name={"AssigneeName"}
                      className="form-control-lg form-select form-select-solid"
                      onChange={(options: any) => {
                        setSelectedAssignee(options?.target?.value);
                        assignees?.map((assignee: any) => {
                          if (assignee?.name == options?.target?.value) {
                            props?.setFieldValue("Assignee Contact Number", assignee?.contactNumber);
                          }
                        });
                      }}
                      value={selectedAssignee}
                    >
                      <option value="">Select Option</option>
                      {assignees?.map((option, index) => {
                        return (
                          <option key={index} value={option?.name}>
                            {option?.name}
                          </option>
                        );
                      })}
                    </Field>
                    <div className="text-danger m-2">
                      <ErrorMessage name={"AssigneeName"} />
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                  <Inputs
                    // required={true}
                    label="Assignee Contact Number"
                    type="number"
                    placeHolder=""
                    name="Assignee Contact Number"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-auto">
                  {selectedAssignee != "" && selectedAssignee != null &&(
                  <button
                    type="button"
                    onClick={() => props.handleSubmit()}
                    // disabled={selectedAssignee != "" || selectedAssignee != null}
                    className="mt-5 btn btn-primary me-4"
                    
                  >
                    {isLoading ? (
                      <span className="indicator-progress d-block text-center">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                   )} 
                </div>
              </div>
            </>
          );
        }}
      ></Formik>
    </div>
  );
}
