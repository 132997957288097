interface Props {
  setValue: any
  valueForm: any
  value: any
  label: string
  name: string
  required?: boolean
  onChangeCheckbox?: any
}
export default function CustomCheckbox({
  valueForm,
  value,
  label,
  name,
  required,
  onChangeCheckbox,
}: Props): JSX.Element {
  return (
    <div className='d-flex form-check form-check-custom form-check-solid me-4 form-check-sm mt-3'>
      <label className='form-check-label d-flex'>
        <input
          className='form-check-input me-2'
          type='checkbox'
          onChange={(e) => onChangeCheckbox(e, name)}
          name={`${valueForm}.${name}`}
          value={value}
          checked={typeof value === 'string' ? value === 'true' : value}
        />
        <span className={`${required && 'required'}`}>{label}</span>
      </label>
    </div>
  )
}
