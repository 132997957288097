import { FC, useEffect, useState } from "react";
import { Dropdowns } from "../../../../_metronic/layout/components/Dropdowns";
import { ErrorMessage, Field, FieldArray } from "formik";
import { Inputs } from "../../../../_metronic/layout/components/Inputs";
import RadioButton from "../../../../_metronic/layout/components/RadioButton";
import {
  deleteForm,
  getCountry,
  getDesignation,
  getpinApi,
} from "../../../API/api";
import { countryDropdown } from "../Shareholder/Shareholder";
import { toastError, toastSuccess } from "./../../../../_metronic/helpers/ToastifyHelper";
import { defaultSelectedCountry } from "../../../constants/DropdownConstants";
import { set } from "lodash";
import PopupCard from "../Shareholder/PopupCard";
import PopupCards from "../AuthorisedSignatory/PopupCards";

interface Props {
  value: any;
  setValue: any;
  saveApplicationHandler: any;
  setFieldError: any;
  loading: boolean;
  initialConstitution: string;
  setFieldValue: any;
  appId: string;
  setIsSaved: any;
  applicationData: any;
  setApplicationData: any;
}

const AuthorisedSignatory: FC<Props> = ({
  value,
  initialConstitution,
  setValue,
  saveApplicationHandler,
  setFieldError,
  loading,
  setFieldValue,
  appId,
  setIsSaved,
  applicationData,
  setApplicationData,
}) => {
  const [updateDataId, setUpdateDataId] = useState(null);
  const [designation, setDesignation] = useState<any[]>([]);
  const [countryData, setCountryData] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);


  const setCountryToIndia = () => {
    value?.authorizeSignatory?.forEach(
      (authorizeSignatoryObject: any, index: number) => {
        if (authorizeSignatoryObject?.country == "") {
          set(value, `authorizeSignatory[${index}].country`, "India");
        }
      }
    );
  };

  const valueForm =
    initialConstitution === "Sole Proprietor"
      ? "soloProprietor"
      : initialConstitution === "Private Limited"
      ? "privateLimited"
      : initialConstitution === "Public Limited"
      ? "privateLimited"
      : initialConstitution === "Partnership"
      ? "partnership"
      : "LLP";

  useEffect(() => {
    if (countryData?.length > 0 && value?.authorizeSignatory?.length > 0) {
      setCountryToIndia();
    }
  }, [countryData, value]);

  useEffect(() => {
    getDesignation()
      .then((res) => setDesignation(res.data))
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );

    getCountry()
      .then((res) => {
        let dataArray = res?.data;
        let indexOfIndia: any = null;
        dataArray?.forEach((country: any, index: number) => {
          if (country?.countryName == "India") {
            indexOfIndia = index;
          }
        });
        dataArray?.splice(indexOfIndia, 1);

        setCountryData(res.data);
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  }, []);
  const getpincode = (currentIndex: any) => {
    // if(value ==='sikDetails'){
    const collectionName = "authorizeSignatory";
    const value1 = [collectionName];
    const collectionId = applicationData?.authorizeSignatory[currentIndex]?.collectionId;
    let collection = applicationData[collectionName];
    
    const data = collection?.map((collection: any, index: number) => {
      const values = Array.isArray(value1) ? value1[index] : value1;
      const dataUnits = formatDataForAPI(collection.dataUnits, values);
      const pinCode = value?.authorizeSignatory[currentIndex].pinCode;
      if (dataUnits?.postalCode1) {
        dataUnits.pinCode.dataUnitValue = pinCode;

      }
      return { dataUnits, collectionId: collection.collectionId };
    });

    const payload = {
      applicationId: appId,
      forms: {
        [collectionName]: data,
      },
    };

    getpinApi(payload, collectionName, value?.authorizeSignatory[currentIndex].pinCode, collectionId)
      .then((res) => {
        const { forms } = res.data;
      const form = JSON.parse(JSON.stringify(forms?.authorizeSignatory[0]));
     
        setValue(`authorizeSignatory[${currentIndex}].city`, `${form?.dataUnits?.city?.dataUnitValue}`);
        setFieldValue(`authorizeSignatory[${currentIndex}].state`, `${form?.dataUnits?.state?.dataUnitValue}`);
        toastSuccess("Pincode validated");
      })
      .catch((err) =>
        toastError(err.response?.data?.error || err.response?.data)
      );
  };
  const formatDataForAPI = (dataUnits: any, values: any) => {
    const fields = Object.keys(dataUnits);
    for (const field of fields) {
      if (values) {
        const dataUnit = dataUnits[field];
        const fieldValue = values[field];
        dataUnit.dataUnitValue = fieldValue;
      }
    }

    return dataUnits;
  };

  const renderAuthorized = (currentIndex: number) => {
    return (
      <div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
            <RadioButton
              required={true}
              title="Title"
              value={[
                { value: "Mr", label: "Mr" },
                { value: "Ms", label: "Ms" },
                { value: "Dr", label: "Dr" },
              ]}
              name={`authorizeSignatory[${currentIndex}].title`}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              label="First Name"
              type="text"
              placeHolder="Enter Your First Name"
              name={`authorizeSignatory[${currentIndex}].firstName`}
            />
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              // required={true}
              name={`authorizeSignatory[${currentIndex}].middleName`}
              label="Middle Name"
              type="text"
              placeHolder="Enter Your Middle Name"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              name={`authorizeSignatory[${currentIndex}].lastName`}
              label="Last Name"
              type="text"
              placeHolder="Enter Your Last Name"
            />
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Dropdowns
              required={true}
              label="Nationality"
              name={`authorizeSignatory[${currentIndex}].nationality`}
              options={countryDropdown.map((c) => c)}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Dropdowns
              required={true}
              label="Designation"
              name={`authorizeSignatory[${currentIndex}].designation`}
              options={designation.map((d) => d.designationName)}
            />
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              name={`authorizeSignatory[${currentIndex}].dateOfBirth`}
              label="Date of Birth"
              type="date"
              placeHolder=""
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={false}
              name={`authorizeSignatory[${currentIndex}].mobileNo`}
              label="Mobile No"
              type="number"
              placeHolder="Enter Your Mobile Number"
            />
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required
              name={`authorizeSignatory[${currentIndex}].addressLine1`}
              label="Address Line 1"
              type="text"
              placeHolder="Enter Your Address"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              // required
              name={`authorizeSignatory[${currentIndex}].addressLine2`}
              label="Address Line 2"
              type="text"
              placeHolder="Enter Your Address"
              //
            />
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div>
              <label
                className={`col-sm-3 col-form-label text-nowrap ${
                  true && "required"
                }`}
                htmlFor={`authorizeSignatory[${currentIndex}].pinCode`}
              >
                Pincode
              </label>
              <div className="d-flex gap-5">
                <Field
                  type="number"
                  name={`authorizeSignatory[${currentIndex}].pinCode`}
                  id={`authorizeSignatory[${currentIndex}].pinCode`}
                  className="form-control form-control-lg form-control-solid"
                  placeholder={"Enter Your Pincode"}
                />
                <div className="col-auto">
                  <button
                    type="button"
                    className="mt-1 btn btn-primary me-3"
                    onClick={() => {
                      getpincode(currentIndex);
                    }}
                  >
                    Validate
                  </button>
                </div>
              </div>

              <div className="text-danger m-2">
                <ErrorMessage
                  name={`authorizeSignatory[${currentIndex}].pinCode`}
                />
              </div>
            </div>
            {/* <Inputs
              required={true}
              name={`authorizeSignatory[${currentIndex}].pinCode`}
              label="Pincode"
              type="number"
              placeHolder="Enter Your PinCode"
            />
            <div className="col-auto">
              <button
                type="button"
                className="mt-1 btn btn-primary me-3"
                // onClick={() => deletePromoter(d, i)}
                onClick={(values) => {
                  getpincode(values);
                }}
              >
                Validate
              </button>
            </div> */}
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              name={`authorizeSignatory[${currentIndex}].city`}
              label="City"
              type="text"
              placeHolder="Enter Your City"
            />
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
            required={false}
              name={`authorizeSignatory[${currentIndex}].district`}
              label="District"
              type="text"
              placeHolder="Enter Your District"
              //
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              name={`authorizeSignatory[${currentIndex}].state`}
              label="State"
              type="text"
              placeHolder="Enter Your State"
            />
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Dropdowns
              defaultValue={defaultSelectedCountry}
              required={true}
              name={`authorizeSignatory[${currentIndex}].country`}
              label="Country"
              options={countryData.map((c) => c.countryName)}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              required={true}
              label="Pan No"
              type="text"
              placeHolder="Enter Your Pan No"
              name={`authorizeSignatory[${currentIndex}].panNo`}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <Inputs
              // required={
              //   value?.authorizeSignatory &&
              //   value?.authorizeSignatory[currentIndex]?.nationality !==
              //     "Indian" &&
              //   true
              // }
              label={
                value[valueForm] &&
                value[valueForm][currentIndex]?.authorizedSignatory === "YES" &&
                value[valueForm][currentIndex]?.addressProof
                  ? value[valueForm][currentIndex].addressProof
                  : "Address Proof Number"
              }
              type="text"
              placeHolder={
                value[valueForm] &&
                value[valueForm][currentIndex]?.authorizedSignatory === "YES"
                  ? "Enter Your " + value[valueForm][currentIndex]?.addressProof
                  : "Enter Your Address Proof Number"
              }
              name={`authorizeSignatory[${currentIndex}].passportNumber`}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-6 col-sm-6 d-flex align-items-center">
          <button
          type="button"
          className="mt-5 btn btn-primary me-4 col-auto"
          // onClick={() => addShareHolder(d)}btn btn-primary btn-sm m-1 me-3
          onClick={() => {
            setShowPopup(true);
          }}
          style={{ display: showPopup ? 'none' : 'block' }}
        >
          Add Document
        </button>
        {showPopup && <PopupCards setApplicationData={setApplicationData} value={value} applicationData={applicationData}  appId={appId} setIsSaved={setIsSaved} setFieldValue={setFieldValue} onClose={() => {setShowPopup(false)}} />} 
        
        </div>
        </div>
      </div>
    );
  };

  const addAuthorisedSig = (d: any) => {
    const currentData = { ...value?.authorizeSignatory[0] };
    const currentDataCopy = JSON.parse(JSON.stringify(currentData));
    for (const key in currentDataCopy) {
      currentDataCopy[key] = "";
    }

    d.push(currentDataCopy);

    const form = JSON.parse(
      JSON.stringify(applicationData.authorizeSignatory[0])
    );
    form.collectionId = "";
    const keys: any = Object.keys(form.dataUnits);
    for (const fieldKey of keys) {
      form.dataUnits[fieldKey].dataUnitCode = "";
    }
    applicationData.authorizeSignatory.push(form);
    setApplicationData(applicationData);
  };

  // const editData = (data: any, index: any, isUpdate: boolean) => {
  //   setCurrentIndex(index)
  //   const id = value.authorizeSignatory.findIndex((val: any, i: any) => i === index)
  //   setUpdateDataId(isUpdate ? null : id)
  //   if (isUpdate) {
  //     const authorizedSigCopy: any = Object.assign([], value.authorizeSignatory)
  //     authorizedSigCopy[id] = {
  //       data,
  //     }
  //   }
  // }

  const deleteAuthorizedSig = (d: any, index: any) => {
    d.remove(index);
    const authorizedCopy = Object.assign(
      applicationData?.authorizeSignatory[index]
    );
    const deleteAuthorized: any = {
      applicationId: appId,
      forms: {
        authorizeSignatory: [
          {
            ...authorizedCopy,
          },
        ],
      },
    };

    if (authorizedCopy.collectionId) {
      deleteForm(deleteAuthorized, authorizedCopy?.collectionId, appId);
    }
  };
  if (!value[valueForm]) return null;
  return (
    <div className="card p-5 w-100">
      <h3 className="border-bottom p-2">Authorized Signatory</h3>
      <FieldArray
        name="authorizeSignatory"
        render={(d) => (
          <div>
            {value?.authorizeSignatory?.length > 1 ? (
              <div
                className="accordion col-xl-12 col-lg-12 col-md-12 col-sm-12"
                id="kt_accordion_1"
              >
                {value?.authorizeSignatory?.map((authorized: any, i: any) => (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id={`kt_accordion_header_${i}`}
                    >
                      <button
                        className="accordion-button fs-4 fw-bold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#kt_accordion_body_${i}`}
                        aria-expanded="false"
                        aria-controls={`kt_accordion_body_${i}`}
                        onClick={() => setCurrentIndex(i)}
                      >
                        {i + 1}. {authorized.firstName} {authorized.middleName}{" "}
                        {authorized.lastName}
                      </button>
                    </h2>
                    <div
                      id={`kt_accordion_body_${i}`}
                      className="accordion-collapse collapse row p-5"
                      aria-labelledby={`kt_accordion_header_${i}`}
                      data-bs-parent="#kt_accordion_1"
                    >
                      {renderAuthorized(i)}
                      {i > 0 && (
                        <div className="col-auto">
                          <button
                            type="button"
                            className="mt-5 btn btn-danger me-4"
                            onClick={() => deleteAuthorizedSig(d, i)}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              renderAuthorized(0)
            )}
            {/* <div className='row g-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5'>
                <RadioButton
                  required={true}
                  title='Title'
                  value={[
                    {value: 'Mr', label: 'Mr'},
                    {value: 'Ms', label: 'Ms'},
                    {value: 'Dr', label: 'Dr'},
                  ]}
                  name={`authorizeSignatory[${currentIndex}].title`}
                  disabled
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  label='First Name'
                  type='text'
                  placeHolder='Enter Your First Name'
                  name={`authorizeSignatory[${currentIndex}].firstName`}

                />
              </div>
            </div>
            <div className='row g-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].middleName`}
                  label='Middle Name'
                  type='text'
                  placeHolder='Enter Your Middle Name'
                  disabled={value[valueForm][currentIndex]?.authorizedSignatory === 'YES'}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].lastName`}
                  label='Last Name'
                  type='text'
                  placeHolder='Enter Your Last Name'
                  disabled={isAuthorizedSignatory}
                />
              </div>
            </div>
            <div className='row g-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Dropdowns
                  required={true}
                  label='Nationality'
                  name={`authorizeSignatory[${currentIndex}].nationality`}
                  options={countryDropdown.map((c) => c)}
                  disabled={isAuthorizedSignatory}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Dropdowns
                  required={true}
                  label='Designation'
                  name={`authorizeSignatory[${currentIndex}].designation`}
                  options={designation.map((d) => d.designationName)}
                  disabled={isAuthorizedSignatory}
                />
              </div>
            </div>
            <div className='row g-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].dateOfBirth`}
                  label='Date of Birth'
                  type='date'
                  placeHolder=''
                  disabled={isAuthorizedSignatory}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].mobileNo`}
                  label='Mobile No'
                  type='number'
                  placeHolder='Enter Your Mobile Number'
                  disabled={isAuthorizedSignatory}
                />
              </div>
            </div>
            <div className='row g-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].addressLine1`}
                  label='Address Line 1'
                  type='text'
                  placeHolder='Enter Your Address'
                  disabled={isAuthorizedSignatory}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].addressLine2`}
                  label='Address Line 2'
                  type='text'
                  placeHolder='Enter Your Address'
                  // disabled={isAuthorizedSignatory}
                />
              </div>
            </div>
            <div className='row g-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].pinCode`}
                  label='Pincode'
                  type='number'
                  placeHolder='Enter Your PinCode'
                  disabled={isAuthorizedSignatory}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].city`}
                  label='City'
                  type='text'
                  placeHolder='Enter Your City'
                  disabled={isAuthorizedSignatory}
                />
              </div>
            </div>
            <div className='row g-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].district`}
                  label='District'
                  type='text'
                  placeHolder='Enter Your District'
                  // disabled={isAuthorizedSignatory}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].state`}
                  label='State'
                  type='text'
                  placeHolder='Enter Your State'
                  disabled={value[valueForm][currentIndex]?.authorizedSignatory === 'YES'}
                />
              </div>
            </div>
            <div className='row g-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Dropdowns
                  required={true}
                  name={`authorizeSignatory[${currentIndex}].country`}
                  label='Country'
                  options={countryData.map((c) => c.countryName)}
                  disabled={value[valueForm][currentIndex]?.authorizedSignatory === 'YES'}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={value.authorizeSignatory[currentIndex].nationality === 'Indian' && true}
                  label='Pan No'
                  type='text'
                  placeHolder='Enter Your Pan No'
                  name={`authorizeSignatory[${currentIndex}].panNo`}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <Inputs
                  required={value.authorizeSignatory[currentIndex].nationality !== 'Indian' && true}
                  label='Passport'
                  type='text'
                  placeHolder='Enter Your Passport'
                  name={`authorizeSignatory[${currentIndex}].passportNumber`}
                />
              </div>
            </div> */}
            {/* <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            name='promoteTelephoneNoAuthorised'
            label='Telephone No'
            type='number'
            placeHolder='Enter Your Telephone Number'
          />
        </div>

      </div> */}
            {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns
            required={true}
            label='Authorised Signatory'
            name='authorisedSignatoryAuthorised'
            options={['YES', 'NO']}
          />
        </div> */}
            {/* <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            name='dateOfAuthorizationAuthorised'
            label='Date Of Authorization'
            type='date'
            placeHolder=''
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            name='addressProofNumberAuthorised'
            label='Address Proof Number'
            type='number'
            placeHolder='Enter Your Address Proof Number'
          />
        </div>
      </div> */}
            {/* <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns
            required={true}
            label='Address Proof Submitted'
            name='addressProofSubmittedAuthorised'
            options={['YES', 'NO']}
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns
            required={true}
            label='Mode Of Operation'
            name='modeOfOperationAuthorized'
            options={['YES', 'NO']}
          />
        </div>
      </div> */}
            {/* <h5 className='border-bottom mt-5'>Father/Spouse Name</h5> */}
            {/* <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns
            required={true}
            label='Title'
            name='title2Authorised'
            options={['YES', 'NO']}
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            name='firstName2Authorised'
            label='First Name'
            type='text'
            placeHolder='Enter Your First Name'
          />
        </div>
      </div> */}
            {/* <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            name='middleName2Authorised'
            label='Middle Name'
            type='text'
            placeHolder='Enter Your Middle Name'
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            name='lastName2Authorised'
            label='Last Name'
            type='text'
            placeHolder='Enter Your Last Name'
          />
        </div>
      </div> */}
            {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
        <Dropdowns required={true} label='Type' name='typeAuthorised' options={['YES', 'NO']} />
      </div> */}
            {/* <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
        <Dropdowns required={true} label='Title' name='title' options={['YES', 'NO']} />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
        <Inputs
            required={true}
            name='emailIDAuthorised'
            label='Email ID'
            type='email'
            placeHolder='Enter Your Email'
          />

        </div>
      </div> */}
            {/* <div className='row g-3'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Dropdowns
            required={true}
            label='Residential Status'
            name='residentialStatusAuthorised'
            options={['YES', 'NO']}
          />
        </div>
      </div> */}
            {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
          <Inputs
            required={true}
            name='DINNoAuthorised'
            label='DIN No'
            type='number'
            placeHolder='Enter Your DIN No'
          />
        </div> */}
            <div className="row">
              <div className="col-auto">
                <button
                  type="submit"
                  className="mt-5 btn btn-primary me-4"
                  // onClick={() =>
                  //   saveApplicationHandler(
                  //     value.authorizeSignatory,
                  //     'authorizeSignatory',
                  //     setFieldError
                  //   )
                  // }
                >
                  {loading ? (
                    <span className="indicator-progress d-flex">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  className="mt-5 btn btn-primary me-4"
                  onClick={() => addAuthorisedSig(d)}
                >
                  Add Signatory
                </button>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default AuthorisedSignatory;
